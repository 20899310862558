<template>
  <div class="">
    <Header class="sticky top-0 z-50" />
    <main class="relative bg-white">
      <div class="">
        <slot></slot>
      </div>
    </main>
    <Footer v-if="$route.name === 'main'" />
  </div>
</template>
  
  <script>
import Header from "@/components/reusable/partner/header.vue";
import Footer from "@/components/LandingPage/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>
  
  <style></style>
  