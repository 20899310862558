<template>
  <div class="">
    <div class="h-48 w-full relative">
      <p
        v-if="tag"
        class="bg-gray-850 text-white text-xs absolute left-3 top-3 px-3 rounded-md py-0.5 font-medium"
      >
        Get 20% off (up to CA$7)
      </p>
      <div v-if="isStore" class="h-full w-full">
        <div
          @click="$emit('visitStore', product)"
          v-if="product?.imageUrl"
          :style="{
            backgroundImage: `url(${product?.imageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: '390px',
            backgroundRepeat: 'no-repeat',
          }"
          class="h-full w-full rounded-lg cursor-pointer"
        ></div>
        <div
          @click="$emit('visitStore', product)"
          v-else
          class="cursor-pointer w-full h-full storeImage rounded-lg"
        />
      </div>

      <div v-else class="h-full w-full">
        <div
          @click="$emit('visitStore', product)"
          v-if="product?.imageUrl"
          :style="{
            backgroundImage: `url(${product?.imageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: '260px',
            backgroundRepeat: 'no-repeat',
          }"
          class="h-full w-full rounded-lg cursor-pointer"
        ></div>
        <div
          @click="$emit('visitStore', product)"
          v-else
          class="w-full h-full storeImage rounded-lg"
        />
      </div>

      <SfButton
        v-if="isAddButton && !isAdded"
        class="text-sm absolute bottom-2 right-2 rounded-full bg-white text-black py-1.5 px-3 capitalize"
        @click="$emit('add')"
      >
        Add
      </SfButton>

      <Counter
        v-if="isAddButton && isAdded && isAdded?.quantity !== 0"
        :amount="isAdded?.quantity"
        @add="$emit('increaseQuantity')"
        @delete="$emit('decreaseQuantity')"
        class="absolute bottom-2 right-2"
      />
    </div>
    <div class="pt-3 flex items-center justify-between">
      <div
        class="flex items-center cursor-pointer"
        @click="$emit('visitStore', product)"
      >
        <p class="text-lg font-semibold">
          {{ isStore ? product?.storeName : product.name }}
        </p>
        <img v-if="isLogo" src="@/assets/logo-simple.jpeg" class="w-8" />
      </div>

      <button @click="addStoreTOwishList">
        <WishlistBtn :isLiked="savedShop" />
      </button>

      <!-- <button @click="addwishlist(product.id)" v-if="isLike">
        <img src="@/assets/svg/heart-gray.svg" alt="heart" class="w-6" />
      </button> -->
    </div>
    <p class="text-gray-500 text-sm">{{ distance }} / {{ duration }}</p>
  </div>
</template>

<script>
import { SfButton } from "@storefront-ui/vue";
import Counter from "../cart/counter.vue";
import WishlistBtn from "./WishlistBtn.vue";
import { Loader } from "@googlemaps/js-api-loader";

import axiosInstance from "../../helpers/axiosInstance";
export default {
  props: [
    "product",
    "isAddButton",
    "tag",
    "isAdded",
    "isLike",
    "isLogo",
    "isStore",
    "storeId",
    "savedShop",
  ],
  components: { SfButton, Counter, WishlistBtn },

  data() {
    return {
      distance: "",
      duration: "",
    };
  },

  methods: {
    addStoreTOwishList() {
      if (!this.savedShop) {
        axiosInstance
          .post("/services/app/ShopLikeList/Create", {
            shopId: this.storeId,
          })
          .then(async () => {
            await this.SetStoreInfo({
              id: this.storeId,
              searchField: "",
            });
            this.isLiked = "success";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axiosInstance
          .delete(
            `/services/app/ShopLikeList/RemoveShopLike?Id=${this.storeId}`
          )
          .then(async () => {
            await this.SetStoreInfo({
              id: this.storeId,
              searchField: "",
            });
            this.isLiked = "deleted";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async getDistance() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GCP_ACCESS,
        version: "weekly",
        libraries: ["places"],
      });

      const google = await loader.load();
      const matrix = new google.maps.DistanceMatrixService();

      let res = matrix.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(25.7694708, -80.259947)],
          destinations: [new google.maps.LatLng(25.768915, -80.254659)],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        async function (response) {
          //do something

          return await response;
          // this.distance = await response?.rows[0].elements[0]?.distance.text;
          // this.duration = await response?.rows[0].elements[0]?.distance.text;
        }
      );
      res.then((response) => {
        this.distance = response?.rows[0].elements[0]?.distance.text;
        this.duration = response?.rows[0].elements[0]?.duration.text;
      });
    },
  },

  mounted() {
    this.getDistance();
  },
};
</script>

<style>
.storeImage {
  background-image: url("../../assets/StoreImage.png");
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: black; */
  border: 1px solid rgb(223, 222, 222);
}
</style>
