var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-map',{ref:"map",staticStyle:{"z-index":"0"},attrs:{"zoom":_vm.zoom,"center":[
    _vm.position.lat ||
      _vm.userLocation.lat ||
      _vm.defaultLocation.lat ||
      _vm.currentLocation.lat,
    _vm.position.lng ||
      _vm.userLocation.lng ||
      _vm.defaultLocation.lng ||
      _vm.currentLocation.lng,
  ]},on:{"dblclick":_vm.onMapClick}},[_c('l-tile-layer',{attrs:{"url":_vm.tileProvider.url,"attribution":_vm.tileProvider.attribution}}),(_vm.position.lat && _vm.position.lng)?_c('l-marker',{attrs:{"lat-lng":[_vm.position.lat, _vm.position.lng]}},[_c('l-icon',{attrs:{"icon-size":_vm.dynamicSize,"icon-anchor":_vm.dynamicAnchor,"icon-url":"../../assets/coffee.png"}})],1):_vm._e(),(_vm.position.lat && _vm.position.lng)?_c('l-marker',{attrs:{"visible":"","draggable":"","icon":_vm.icon,"lat-lng":_vm.position},on:{"update:latLng":function($event){_vm.position=$event},"update:lat-lng":function($event){_vm.position=$event},"dragstart":function($event){_vm.dragging = true},"dragend":function($event){_vm.dragging = false}}},[_c('l-tooltip',{attrs:{"content":_vm.tooltipContent,"options":{ permanent: true }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }