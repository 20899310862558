<template>
  <div>
    <div class="flex items-center justify-end space-x-2">
      <button
        @click="activeSignin"
        class="btn bg-red text-white rounded-full py-0.5 font-semibold text-lg"
      >
        Sign In
      </button>
      <button
        @click="activeSignup"
        class="btn text-black bg-white rounded-full py-0.5 font-semibold text-lg"
      >
        Sign Up
      </button>
    </div>

    <transition name="fade">
      <div v-if="isModalVisible">
        <main id="content" role="main" class="w-full max-w-md mx-auto p-6">
          <div
            class="mt-7 bg-white rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700"
          >
            <div class="p-4 sm:p-7">
              <div class="text-center">
                <h1
                  class="block text-2xl font-bold text-gray-800 dark:text-white"
                >
                  Forgot password?
                </h1>
                <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Remember your password?
                  <a
                    class="text-blue-600 decoration-2 hover:underline font-medium"
                    href="#"
                  >
                    Login here
                  </a>
                </p>
              </div>

              <div class="mt-5">
                <form>
                  <div class="grid gap-y-4">
                    <div>
                      <label
                        for="email"
                        class="block text-sm font-bold ml-1 mb-2 dark:text-white"
                        >Email address</label
                      >
                      <div class="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          class="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                          required
                          aria-describedby="email-error"
                        />
                      </div>
                      <p
                        class="hidden text-xs text-red-600 mt-2"
                        id="email-error"
                      >
                        Please include a valid email address so we can get back
                        to you
                      </p>
                    </div>
                    <button
                      type="submit"
                      class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    >
                      Reset password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <p
            class="mt-3 flex justify-center items-center text-center divide-x divide-gray-300 dark:divide-gray-700"
          >
            <a
              class="pr-3.5 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200"
              href="#"
              target="_blank"
            >
              <svg
                class="w-3.5 h-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                />
              </svg>
              View Github
            </a>
            <a
              class="pl-3 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200"
              href="#"
            >
              Contact us!
            </a>
          </p>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../router";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isOpen: false,
      types: [
        { mode: "Sign In", id: "signin" },
        { mode: "Sign Up", id: "signup" },
      ],
      mode: "signin",
      validEmail: true,
      validPassword: true,
      isloading: false,
      isFormValid: false,

      ispassShow: false,
      email: "",
      password: "",
      errors: [],
      validationError: {
        email: "",
        password: "",
      },

      firstName: "",
      lastName: "",
      countryCode: "",
      phone: "",
      formatedNumner: "",
      phoneisInValid: false,
    };
  },

  computed: {
    isModalVisible() {
      return this.isOpen;
    },
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    },
  },

  methods: {
    ...mapActions(["register"]),

    activeSignup() {
      this.changeActiveButton({ id: "signup" });

      this.onToggle();
    },
    activeSignin() {
      this.changeActiveButton({ id: "signin" });

      this.onToggle();
    },

    handleCountryCode(e) {
      this.countryCode = e.target.value;
    },
    onToggle() {
      this.isOpen = !this.isOpen;

      //   if (this.isOpen) {
      //     document.body.style.overflow = "hidden";
      //   } else {
      //     document.body.style.overflow = "unset";
      //   }
    },
    changeActiveButton(type) {
      this.mode = type.id;
    },

    async login(e) {
      e.preventDefault();
      this.isloading = true;
      let data = {
        userNameOrEmailAddress: this.email,
        password: this.password,
      };

      const response = await axios
        .post("TokenAuth/Authenticate", data, {
          headers: {
            "Abp.TenantId": "3",
          },
        })
        .then((res) => {
          this.isloading = false;
          console.log(res);
          localStorage.setItem("token", res?.data?.result?.accessToken);
          if (this.$route.name === "main") {
            router.go(0);
          } else {
            router.push("/customer/main");
          }

          return true;
        })
        .catch(() => {
          this.isloading = false;
          return false;
        });
      return response?.response;
    },

    async createUser(e) {
      e.preventDefault();

      this.isloading = true;

      let data = {
        name: this.firstName,
        surname: this.lastName,
        userName: `some`,
        emailAddress: this.email,
        password: this.password,
        isActive: true,
        phoneNumber: `${this.countryCode}${this.formatedNumber}`,
        roleNames: ["customer"],
      };

      await this.register(data)
        .then(() => {
          this.isLoader = false;
          this.isloading = false;

          setTimeout(() => {
            this.isSuccess = true;
          }, 1000);
          if (this.$route.name === "main") {
            this.$router.go(0);
          }
        })
        .catch((error) => {
          if (error) {
            this.isLoader = false;
            this.isloading = false;

            this.isSuccess = false;
          }
        });
    },

    passwordToggle() {
      this.ispassShow = !this.ispassShow;
    },

    isValid() {
      var phoneRe = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      let test = phoneRe.test(this.phone);
      if (test === false) {
        this.phoneisInValid = true;
      } else {
        this.phoneisInValid = false;

        this.phone = this.formatPhoneNumber(this.phone);
        this.formatedNumber = `${this.countryCode}${this.phone}`;
      }
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";

        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    },
  },

  watch: {
    password(value) {
      if (value === "") {
        this.validationError.password = "Password required.";
        this.validPassword = false;

        this.isFormValid = false;
      } else {
        this.validPassword = true;
        this.validationError = {
          password: "",
          ...this.validationError,
        };
        if (this.validEmail && this.validPassword) {
          this.isFormValid = true;
        }
      }
    },
    email(value) {
      var email = /^[^@]+@\w+(\.\w+)+\w$/.test(value);
      if (email === false) {
        this.validEmail = false;
        this.validationError.email = "Please enter a valid email";

        this.isFormValid = false;
      } else if (value === "") {
        this.validEmail = false;

        this.isFormValid = false;

        this.validationError.email = "Email cannot be Blank";
      }

      if (email) {
        this.validEmail = true;

        if (this.validEmail && this.validPassword) {
          this.isFormValid = true;
        }
      }
    },
    phone() {
      this.isValid();
    },
  },
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
