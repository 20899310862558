<template>
  <div>
    <div class="z-10">
      <Auth
        :onToggle="onToggle"
        :isOpen="isOpen"
        :isModalVisible="isModalVisible"
        :types="types"
        :mode="mode"
        :changeActiveButton="changeActiveButton"
      />
    </div>
    <nav
      class="md:h-[100px] h-[60px] border-b-1 border-[#000000] border-opacity-[0.1] font-jakarta"
    >
      <div
        class="Herowrapper px-[16px] h-full w-full flex items-center justify-between"
      >
        <router-link to="/">
          <button class="md:w-[60px] w-[40px]">
            <img src="@/assets/HomePage/logo.png" alt="" />
          </button>
        </router-link>
        <button
          @click="activeSignup"
          class="block md:hidden w-[100px] py-1 h-40px text-base font-semibold bg-primaryGreen text-white rounded-full leading-8"
        >
          Sign in
        </button>
        <div class="items-center gap-7 hidden md:flex">
          <!-- <div v-if="loggedIn === 'true'">
            <router-link to="/customer/main">
              <button
                class="px-2 py-2 h-50px text-base font-semibold bg-primaryGreen text-white rounded-full leading-8"
              >
                Continue to Shopping
              </button>
            </router-link>
          </div> -->
          <div class="flex gap-2 items-center">
            <a href="https://dev-vneed-admin.netlify.app/user/login">
              <button
                class="w-[130px] py-2 h-50px text-base font-semibold bg-primaryGreen text-white rounded-full leading-8"
              >
                Log in
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div class="wrapper font-jakarta">
      <div class="relative w-full h-full p-2 md:p-2 lg:p-10">
        <img class="hidden md:block" src="@/assets/convience.png" alt="" />
        <div
          class="hidden md:block absolute md:left-8 md:bottom-8 lg:left-20 lg:bottom-20"
        >
          <h3 class="text-8xl font-extrabold text-[#ffffff] shadow-lg">
            Become a <br />
            <span class="text-[#ffffff] shadow-lg"> store Partner <br /> </span>
            With
            <span class="text-[#365FFE]">NoshBox</span>
          </h3>
        </div>

        <div
          class="w-full h-full md:w-[360px] md:h-[460px] lg:h-[620px] lg:w-[420px] lg:py-4 md:absolute relative lg:right-16 lg:top-12 md:right-4 md:top-4 bg-[#F5F5F5] rounded-[10px] p-4 py-4 md:py-2"
        >
          <h4 class="md:text-2xl lg:text-3xl text-3xl font-medium">
            Register With NoshBox
          </h4>

          <form
            @submit="submitData"
            class="flex flex-col md:gap-4 lg:gap-5 gap-8 mt-4"
          >
            <input
              required
              v-model="formData.businessName"
              class="w-full h-12 p-2 px-4 rounded-lg"
              placeholder="Business name"
              type="text"
            />
            <p v-if="errors.fullName" class="text-red-500">
              {{ errors.fullName }}
            </p>
            <input
              v-model="formData.mobileNumber"
              class="w-full h-12 p-2 px-4 rounded-lg"
              placeholder="Mobile Number"
              type="text"
              required
            />

            <input
              v-model="formData.address"
              class="w-full h-12 p-2 px-4 rounded-lg"
              placeholder="Address"
              type="text"
              required
            />

            <select
              @click="getDataBasedonSelection"
              class="w-full h-12 p-2 px-4 rounded-lg"
              v-model="selectedCounriesData"
              name="country"
              id="country"
            >
              <option disabled value="">Select Country</option>
              <option
                :key="index"
                v-for="(item, index) in counriesData"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>

            <select
              v-model="selectedstateProvinces"
              class="w-full h-12 p-2 px-4 rounded-lg"
              name="CountrystateProvinces"
              id="CountrystateProvinces"
            >
              <option disabled value="">Select State</option>

              <option
                :key="index"
                v-for="(item, index) in selectedCountrystateProvinces"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>

            <input
              v-model="formData.email"
              class="w-full h-12 p-2 px-4 rounded-lg"
              placeholder="Email"
              type="email"
              required
            />
            <p v-if="errors.email" class="text-red-500">{{ errors.email }}</p>

            <input
              v-model="formData.password"
              class="w-full h-12 p-2 px-4 rounded-lg"
              placeholder="Password"
              type="password"
              required
            />

            <button
              v-if="isLoading"
              type="submit"
              class="bg-[#456759] h-12 text-white rounded-lg font-medium"
            >
              <loader :height="'32px'" :width="'32px'" />
            </button>
            <button
              v-else
              type="submit"
              class="bg-[#456759] h-12 text-white rounded-lg font-medium"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/auth/modals/auth.vue";
import axios from "axios";
import loader from "../../../components/reusable/loader.vue";
export default {
  components: { Auth, loader },

  data() {
    return {
      counriesData: [],
      selectedCounriesData: "",
      selectedstateProvinces: "",
      selectedCountrystateProvinces: [],
      showButton: true, // Initially show the button
      types: [
        { mode: "Sign In", id: "signin" },
        { mode: "Sign Up", id: "signup" },
      ],
      mode: "signin",
      isOpen: false,
      formData: {
        mobileNumber: "",
        email: "",
        address: "",
        businessName: "",
        password: "",
        zipcode: "",
      },
      errors: {},
      isLoading: false,
      loggedIn: localStorage.getItem("loggedIn"),
    };
  },
  methods: {
    activeSignup() {
      this.mode = "signup";
      this.changeActiveButton({ id: "signup" });

      this.onToggle();
    },
    activeSignin() {
      this.mode = "signin";

      this.changeActiveButton({ id: "signin" });

      this.onToggle();
    },

    changeActiveButton(type) {
      this.mode = type.id;
    },

    onToggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },

    async submitData(e) {
      this.isLoading = true;

      e.preventDefault();
      if (this.validateForm()) {
        const data = {
          tenancyName: this.formData.businessName.replace(/\s/g, ""),
          businessName: this.formData.businessName,
          adminEmailAddress: this.formData.email,
          address: this.formData.address,
          phoneNumber: this.formData.mobileNumber,
          stateProvinceId: this.selectedstateProvinces.id,
          countryId: this.selectedstateProvinces.countryId,

          password: this.formData.password,
        };

        axios
          .post("/services/app/Tenant/Register", data)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$swal({
              title: "Welcome to NoshBox!",
              text: "Please Check your Email Inbox for Onboarding Process",
              imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
              imageWidth: 100, // Adjust as needed
              imageHeight: 100,
              imageAlt: "Custom Icon",
              showCancelButton: false,
              confirmButtonColor: "#d1311F", // Change button color
              confirmButtonText: "Okay", // Change button text
              showCloseButton: true,
            }).then(() => {
              window.location.href =
                "https://dev-vneed-admin.netlify.app/user/login";
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal({
              title: error.response.data.error.message,
              text: error.response.data.error.details,
              imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
              imageWidth: 100, // Adjust as needed
              imageHeight: 100,
              showCancelButton: false,
              confirmButtonColor: "#d1311F", // Change button color
              confirmButtonText: "Try Again!", // Change button text
              showCloseButton: true,
            });
          });

        // Perform form submission logic here
        console.log("Form submitted successfully");
      }
    },

    getCountryData() {
      axios
        .get(
          "https://dev-vneed-api.azurewebsites.net/api/services/app/Country/Getall"
        )
        .then((response) => {
          console.log(response);
          this.counriesData = response.data.result.items;

          console.log(response.data.result.items);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateForm() {
      this.errors = {};

      let isValid = true;

      if (!this.formData.mobileNumber) {
        this.errors.mobileNumber = "Mobile Number is required";
        isValid = false;
      }

      // Add more validation logic for other fields
      // Email validation, storeType validation, fullName validation, city validation, etc.

      return isValid;
    },

    getDataBasedonSelection() {
      console.log(this.selectedCounriesData);
      let selected = this.counriesData.filter(
        (country) => country.name === this.selectedCounriesData
      );
      this.selectedCountrystateProvinces = selected[0].stateProvinces;
      console.log(selected);
    },
  },
  mounted() {
    this.getCountryData();
  },
  computed: {
    isModalVisible() {
      return this.isOpen;
    },
  },
};
</script>

<style>
.wrapper {
  width: 100%;
  position: relative;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
/* .deliverContainer {
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/deliverjoin/delivery\ banner.png");
    background-repeat: no-repeat;
    background-size: 100%;
  } */
</style>
