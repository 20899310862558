<template>
  <div class="relative">
    <div class="tooltip">
      <img class="w-4" src="@/assets/Checkout/infoIcon.svg" />
      <span class="tooltiptext">
        <div v-if="varient === 'Tax'">
          <div>
            <h5>Service Fee: CA$0.75</h5>
            <p>This 11% service fee helps us operate NoshBox.</p>
          </div>
          <div>
            <h5>Estimated Tax: CA$1.24</h5>
            <p>Finalized tax will be shown on your order receipt.</p>
          </div>
          <div>
            <h5>Order Fee: CA$6.00</h5>
            <p>We charge a flat fee of $6 per order.</p>
          </div>
        </div>
        <div v-else-if="varient === 'Delivery'">
          Delivery fee varies for each store is based on your location and other
          factors. Promotions or discounts that reduce delivery fees may apply.
        </div>
      </span>
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;

  background-color: rgba(0, 0, 0, 0.856);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  padding: 16px;
  text-align: left;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 18px;
  left: -35px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

<script>
export default {
  data() {
    return {};
  },
  props: {
    varient: {
      type: String,
    },
  },
};
</script>
