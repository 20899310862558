<template>
  <div>
    <div
      v-if="isSlim"
      class="h-16 rounded-lg flex items-center overflow-hidden relative"
    >
      <div class="bgclipSlim flex justify-center">
        <h3 class="not-italic font-bold text-lg leading-3 text-gray-900">
          {{ title }}
        </h3>
      </div>
      <img
        class="absolute -right-20"
        src="@/assets/grocery.jpeg"
        alt="bannerImage"
      />
    </div>

    <div
      v-else
      class="md:h-72 shadow-lg rounded-lg flex items-center overflow-hidden relative"
    >
      <img class="" src="@/assets/grocery.jpeg" alt="bannerImage" />

      <router-link to="/customer/grocerystore">
        <button
          class="absolute right-[4px] bottom-[4px] md:bottom-4 md:right-4 text-white btn bg-[#446759]"
        >
          Shop Now
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    subTitle: String,
    isSlim: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.bgclip {
  padding: 0 2rem 0rem 2rem;
  background-color: #2cc8d0;
  width: 73%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px 0 0 8px;
  gap: 10px;
  padding: 1rem;
}
.bgclipSlim {
  padding: 0 1rem 0rem 1rem;
  background-color: #2cc8d0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 65% 0%, 60% 100%, 0% 100%);
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px 0 0 8px;
  gap: 10px;
  padding: 1rem;
}
</style>
