<template>
  <div class="flex items-center justify-between">
    <div class="flex space-x-4">
      <img src="@/assets/dish.png" alt="item" class="w-12 h-12 rounded-lg" />
      <div>
        <p class="font-medium">{{ item.foodMenu.name }}</p>
        <span class="text-sm bg-gray-100 px-2 py-0.5 font-medium rounded-md">
          Qty {{ item.quantity }}
        </span>
      </div>
    </div>
    <p class="text-lg font-medium">${{ item.price }}</p>
  </div>
</template>

<script>
export default {
  props: ["item"],

  mounted() {
    console.log("THIS IS THE CHECKOUT ITE MS", this.item);
  },
};
</script>

<style></style>
