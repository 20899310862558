<template>
  <div>
    <input type="checkbox" id="promotions-modal" class="modal-toggle" />
    <label for="promotions-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl"
        for=""
      >
        <label
          for="promotions-modal"
          class="btn btn-sm btn-circle right-2 top-2"
          @click="$emit('close')"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="p-6">
          <h3 class="text-3xl font-bold leading-9 text-black-300">
            Promotions
          </h3>
        </section>

        <section class="w-full flex flex-col px-6 gap-4">
          <div class="w-full flex justify-between gap-2">
            <div class="flex flex-col gap-2 w-full">
              <label for="promocode" class="font-bold">Enter Promo Code </label>

              <div class="bg-[#f1f1f1] p-2 px-4 flex items-center rounded-md">
                <input
                  id="promocode"
                  type="text"
                  class="w-full bg-[#f1f1f1] outline-none"
                  placeholder="YKOJDP JPIOJD IDSHAS"
                />
                <button
                  @click="addPromoCode"
                  class="text-white text-sm font-bold z-10 bg-primaryGreen p-2"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div class="w-full mt-4">
            <h4 class="text-black-300 text-base font-bold">
              Available Rewards & Promotions
            </h4>
            <p class="text-grey-500 text-sm mt-2">
              No available rewards or promotions
            </p>
          </div>

          <div class="flex items-center gap-2 justify-between">
            <h5 class="text-black-300 text-bsm font-bold">
              Have a Gift Card ?
            </h5>
            <button
              class="bg-grey-200 text-bsm font-medium p-1.5 px-3 rounded-full hover:bg-grey-100"
            >
              Redeem Now
            </button>
          </div>
        </section>
      </label>
    </label>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isOpen: false,
      address: localStorage.getItem("address"),
    };
  },
  components: {},

  async mounted() {},

  methods: {
    addPromoCode() {
      console.log("wd,sd");
      axios
        .post("/services/app/ShoppingCart/ApplyPromotionalCode", {
          promoCode: "newuser",
          shopId: 40,
        })
        .then((res) => {
          console.log(res);
          this.$swal({
            title: "PROMOCODE ADDED",
            text: "",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Continue to Checkout", // Change button text
            showCloseButton: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            title: err.response.data.error.message,
            text: err.response.data.error.details,
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Try Again!", // Change button text
            showCloseButton: true,
          });
        });
    },
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}
</style>
