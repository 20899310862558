<template>
  <div>
    <nav
      class="md:h-[100px] h-[60px] border-b-1 border-[#000000] border-opacity-[0.1] font-jakarta"
    >
      <div
        class="Herowrapper px-[16px] h-full w-full flex items-center justify-between"
      >
        <router-link to="/">
          <button class="md:w-[60px] w-[40px] shadow-md rounded-lg">
            <img src="@/assets/HomePage/logo.png" alt="" />
          </button>
        </router-link>

        <h1 class="w-full text-center">Privacy Policy</h1>
      </div>
    </nav>
    
    <div class="container mx-auto p-6">

    <section>
      <p>
        July 02, 2024

      </p>
      <h2 class="text-2xl font-semibold mb-4">Personal Information We Collect</h2>
      <p class="mb-4">
        We collect personal information relating to you ("Personal Information"). Personal Information You Provide: We collect Personal Information if you create an account to use our Services or communicate with us as follows:
      </p>

      <h3 class="text-xl font-semibold mb-2">Account Information:</h3>
      <p class="mb-4">
        When you create an account, we collect information associated with your account, including your name, contact information, account credentials, payment card information, and transaction history, (collectively, "Account Information").
      </p>

      <h3 class="text-xl font-semibold mb-2">User Content:</h3>
      <p class="mb-4">
        When using our services, we collect personal information that includes the input, file uploads, or feedback that you provide to our Services ("Content").
      </p>

      <h3 class="text-xl font-semibold mb-2">Communication Information:</h3>
      <p class="mb-4">
        When communicating with us, we collect your name, contact information, and the contents of any messages you send ("Communication Information").
      </p>

      <h3 class="text-xl font-semibold mb-2">Social Media Information:</h3>
      <p class="mb-4">
        Interacting with our social pages, such as Instagram, Facebook, Twitter, YouTube, LinkedIn. We will collect Personal Information that you elect to provide to us, such as your contact details (collectively, "Social Information"). In addition to the companies that host our social pages, where they may provide us with aggregate information and analytics about our social media activity.
      </p>
    </section>

    <section>
      <h2 class="text-2xl font-semibold mb-4">Personal Information We Receive by Default from Your Use of the Services</h2>
      <p class="mb-4">
        When you visit, use, or interact with our services, we receive the following information about your visit, use, or interactions ("Technical Information"):
      </p>

      <h3 class="text-xl font-semibold mb-2">Log Data:</h3>
      <p class="mb-4">
        Information that your browser automatically sends when you use our Services. This includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interact with our website.
      </p>

      <h3 class="text-xl font-semibold mb-2">Restaurant Order Data:</h3>
      <p class="mb-4">
        We automatically collect information about your use of restaurant Services, such as the order information, vendor preferences, food types, locations, and types of content that you view, engage with, and search in regards to vendors and NoshBox.
      </p>

      <h3 class="text-xl font-semibold mb-2">Usage Data:</h3>
      <p class="mb-4">
        We may automatically collect information about your use of the client Services, such as the type of content that you view or engage with, requests linked with clients, communication methods used with clients, the client management features you use, and the actions you take, as well as your time zone, country, the times and dates of access, type of devices, and computer connection.
      </p>

      <h3 class="text-xl font-semibold mb-2">Device Information:</h3>
      <p class="mb-4">
        Information collected may depend on the type of device you use and its settings. Includes operating systems, device identifiers, name of devices and browsers you are using.
      </p>

      <h3 class="text-xl font-semibold mb-2">Cookies:</h3>
      <p class="mb-4">
        Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They are widely used to enhance the user experience by remembering your preferences, analyzing website traffic, and improving website functionality. We use cookies on our website to enhance your browsing experience and improve the functionality of our services. Cookies allow us to remember your preferences, analyze website traffic, and deliver personalized content and advertisements. By using cookies, we can provide you with a more seamless and tailored user experience, ensuring that our website meets your needs and interests. Additionally, cookies help us gather valuable insights into how our website is being used, allowing us to optimize and enhance its performance. However, refusing a cookie may in some cases cause you from using, or negatively affecting the function or display of, a website, or certain features.
      </p>

      <h3 class="text-xl font-semibold mb-2">Analytics:</h3>
      <p class="mb-4">
        We may use a variety of online analytics products that use cookies to help us analyze how users use our Services to enhance your experience when using the Services.
      </p>
    </section>

    <section>
      <h2 class="text-2xl font-semibold mb-4">How We Use Personal Information</h2>
      <p class="mb-4">
        We may use Personal Information for the following purposes:
      </p>

      <ul class="list-disc ml-6 mb-4">
        <li>To improve our Services and conduct research;</li>
        <li>To communicate with you;</li>
        <li>To develop new programs and services;</li>
        <li>To prevent fraud, criminal activity, or misuses of our Services;</li>
        <li>To protect the security of our IT systems, architecture, and networks;</li>
        <li>To provide, administer, maintain and/or analyze the Services;</li>
        <li>To comply with legal obligations and legal processes and to protect our rights, privacy, property, safety and/or that of our affiliates, third parties, or you;</li>
        <li>To carry out business transfers.</li>
      </ul>

      <p class="mb-4">
        Aggregated or De-Identified Information. We may disclose aggregated or de-identified information so that it may no longer be used to identify you in usage of enhancing our services and conducting analysis and research. From time to time we may analyze the general behavior and characteristics of users and dogs of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally accessible. Aggregated information is data that has been combined and no longer identifies individuals, while de-identified information is modified to prevent identification. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy. We protect privacy by anonymizing and securing the information, and we commit not to re-identify or attempt to re-identify any individual from the aggregated or de-identified information we disclose, unless required by law. Our practices are designed to protect privacy and maintain the anonymized nature of the data.
      </p>
    </section>

    <section>
      <h2 class="text-2xl font-semibold mb-4">Disclosure of Personal Information</h2>
      <p class="mb-4">
        In certain conditions or circumstances, we may provide your Personal Information to third parties without further notice to you, unless required by the law:
      </p>

      <h3 class="text-xl font-semibold mb-2">Business Transfers:</h3>
      <p class="mb-4">
        In the event of a business transfer, such as a merger, acquisition, or sale of assets, your personal information may be disclosed and transferred as part of the transaction. We take necessary measures to ensure that any transfer of personal information during a business transfer is carried out securely and in accordance with applicable privacy laws.
      </p>

      <h3 class="text-xl font-semibold mb-2">Legal Requirements:</h3>
      <p class="mb-4">
        We may disclose your personal information, including information about your interaction with our Services, in response to lawful requests, such as subpoenas, court orders, or other legal processes, to comply with applicable laws and regulations. Also, if we determine, in our sole discretion, that there is a violation of our terms, policies, or the law; to detect or prevent fraud or other illegal activity; to protect the safety, security, and integrity of our products, employees, clients, trainers and all animals, or the public; to protect against legal liability.
      </p>

      <h3 class="text-xl font-semibold mb-2">Vendor and Service Providers:</h3>
      <p class="mb-4">
        We may disclose your personal information to authorized vendors and service providers for the sole purpose of fulfilling our services and maintaining the functionality of our operations. Vendors may include veterinary services, pet services, hosting services, cloud services, web analytics services, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing business duties to us.
      </p>

      <h3 class="text-xl font-semibold mb-2">Affiliates:</h3>
      <p class="mb-4">
        We may disclose your personal information to our affiliates, meaning an entity that controls, is controlled by, or is under common control with Tamers Inc. Our affiliates may use the Personal Information we share in a manner in legal correspondence with this Privacy Policy.
      </p>
    </section>
  </div>
  </div>
</template>
  
  <script>
export default {
  name: "PrivacyPolicy",
  components: {},
};
</script>
  
  <style scoped>
h1,
h2,
h3,
h4 {
  margin-top: 1em;
  font-size: larger;
  font-weight: 700;
}

ul {
  margin-left: 20px;
  list-style-type: disc;
}

ul ul {
  list-style-type: circle;
}
</style>
  