import axios from "axios";
const pickup = {
  state: {
    user: {},
    countries:[]
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getCountries(state){
      return  state.countries
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async SetCountries({commit}){
     await axios.get(`https://restcountries.com/v3.1/all`).then((res)=>{
    
       commit('setCountries',res?.data)
     })
    },
    async SetUser({ commit }) {
      await axios
        .get(`/services/app/Customers/Get?id=${localStorage.getItem("userId")}`)
        .then((res) => {
          commit("setUser", res?.data?.result);
        });
    },
  },
  mutations: {
    setUser(state, response) {
      state.shopsNearMe = response;
    },
    setCountries(state,response){
      state.countries = response
    }
  },
};
export default pickup;
