<template>
  <div>
    <input type="checkbox" id="mobilenumber-modal" class="modal-toggle" />
    <label for="mobilenumber-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-3/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl p-4"
        for=""
      >
        <label
          for="mobilenumber-modal"
          class="btn btn-sm btn-circle"
          @click="$emit('close')"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="py-2 px-2">
          <div class="mb-4">
            <h3 class="text-2xl font-bold leading-8 text-black-300">
              Edit Phone Number
            </h3>
            <p class="text-red text-sm" v-if="phoneisInValid === true">
              Please Enter a valid number
            </p>
          </div>
          <div class="flex items-center gap-4">
            <div class="flex flex-col gap-1">
              <label for="country" class="text-base font-medium">Country</label>
              <select
                id="country"
                class="w-20 rounded-md bg-coolGray-100 p-2 pl-2"
                v-model="countryCode"
              >
                <option
                  v-for="(country, i) in countries"
                  :key="i"
                  :value="country.code"
                >
                  {{ country.code }}
                </option>
              </select>
            </div>

            <div class="flex flex-col w-full">
              <label for="mobilenumber" class="text-base font-medium"
                >Phone Number</label
              >
              <input
                v-on:input="limitPhoneNumber"
                @blur="isValid"
                v-model="phone"
                type="text"
                placeholder="(884) 554-191"
                class="w-full rounded-md bg-coolGray-100 p-2 pl-2"
                id="mobilenumber"
              />
            </div>
          </div>
        </section>

        <div class="w-100 flex gap-2 justify-end mt-4">
          <label
            class="bg-gray-100 p-2 px-3 rounded-full text-base font-medium cursor-pointer"
            for="mobilenumber-modal"
            @click="cancelmodal"
          >
            Cancel
          </label>

          <label
            :class="
              phoneisInValid
                ? 'btn-disabled bg-gray-200 hover:cursor-not-allowed'
                : 'bg-red'
            "
            class="bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer"
            @click="sendOtp()"
            for="verifynumber-modal"
          >
            Continue
          </label>
        </div>
      </label>
      <Toast
        :open="isOtpSent.status === 'success' ? true : false"
        :messageText="
          isOtpSent.status === 'success'
            ? 'OTP Send Successfully'
            : 'Something Went Wrong'
        "
        :varient="isOtpSent.status === 'success' ? 'success' : 'warning'"
      />
      <Toast
        :open="isOtpSent.status === 'error' ? true : false"
        :messageText="
          isOtpSent.status === 'success'
            ? 'OTP Send Successfully'
            : 'Something Went Wrong'
        "
        :varient="isOtpSent.status === 'success' ? 'success' : 'warning'"
      />
    </label>
    <verifyNumber
      :number="phone"
      :countryCode="countryCode"
      @close="handlePopUpClose"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import verifyNumber from "@/components/reusable/modals/verifyNumber.vue";
import Toast from "@/components/reusable/Toast.vue";
import sendVerificationCode from "../../../helpers/sendOtp.js";
import countries from "../../../helpers/countries";

// import axios from "axios";
export default {
  props: ["mobileNumber", "updateNumber"],
  data() {
    return {
      countries: countries,
      isOpen: false,
      phone: "",
      countryCode: "",
      phoneisInValid: false,
      isOtpSent: {
        status: "",
      },
    };
  },
  components: { verifyNumber, Toast },

  methods: {
    limitPhoneNumber() {
      // Remove any non-digit characters from the phone number
      this.phone = this.phone.replace(/\D/g, "");

      // Limit the phone number to 10 digits
      if (this.phone.length > 10) {
        this.phone = this.phone.substring(0, 10);
      }
    },

    cancelmodal() {
      this.$emit("close");
      this.phone = "";
    },

    async sendOtp() {
      let status = await sendVerificationCode(
        this.countryCode,
        this.removeExtraCharacters(this.phone)
      );

      this.isOtpSent = { status: status };

      setTimeout(() => {
        this.isOtpSent = {
          status: "",
        };
      }, 2000);
    },
    removeExtraCharacters(number) {
      // Remove all non-digit characters from the input string
      const cleanedNumber = number.replace(/\D/g, "");

      // If the number doesn't match the USA mobile number format, return the original input
      return cleanedNumber;
    },

    handleCountryCode(e) {
      this.countryCode = e.target.value;
    },

    handlePopUpClose() {
      this.$emit("popUpClose");
    },

    changeNumber() {
      this.updateNumber(this.formatPhoneNumber(this?.phone));
    },

    populateNumber() {
      this.mobileNumber;
    },

    // sendVerificationCode() {
    //   let payload = new FormData();
    //   payload.append(
    //     "phoneNumber",
    //     `${this.countryCode}${this.removeExtraCharacters(this.phone)}`
    //   );
    //   axios
    //     .post(
    //       "services/app/Customers/GeneratePhoneConfirmationCode",

    //       payload,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Abp.TenantId": 3,
    //           "X-XSRF-TOKEN":
    //             "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.isOtpSent = {
    //         status: "success",
    //       };
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.isOtpSent = {
    //         status: "error",
    //       };
    //     });
    // },
    isValid() {
      var phoneRe = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      let test = phoneRe.test(this.phone);

      if (test === false) {
        this.phoneisInValid = true;
      } else {
        this.phoneisInValid = false;
        this.phone = this.formatPhoneNumber(this.phone);
        this.$emit("close");
      }
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    },
  },
  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
  watch: {
    mobileNumber(value) {
      this.phone = value;
    },
    phone() {
      this.isValid();
    },
  },
  mounted() {
    this.phone = this.formatPhoneNumber(this?.getProfileInfo?.phoneNumber);
    this.countryCode = this?.getProfileInfo?.phoneCountryCode;
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}
</style>
