<template>
  <div>
    <nav
      class="md:h-[100px] h-[60px] border-b-1 border-[#000000] border-opacity-[0.1] font-jakarta"
    >
      <div
        class="Herowrapper px-[16px] h-full w-full flex items-center justify-between"
      >
        <router-link to="/">
          <button class="md:w-[60px] w-[40px] shadow-md rounded-lg">
            <img src="@/assets/HomePage/logo.png" alt="" />
          </button>
        </router-link>

        <h1 class="w-full text-center">Refund Policy</h1>
      </div>
    </nav>
    <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold text-gray-900 mb-6">Terms of Use</h1>
      <p class="mb-4">Thank you for using Nosh Box App!</p>
      <p class="mb-4">
        These Terms of Use ("Terms") govern your use of Noshcommerce's website and any associated services or features
        (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms.
        If you do not agree with any provision of these Terms, you may not use the Service.
      </p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Registration and Access</h2>
      <p class="mb-4"><strong>Account Registration:</strong> In order to access certain features of the Service, you may be required to create an account. You agree to provide accurate, complete, and updated information during the registration process. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
      <p class="mb-4"><strong>Access to the Service:</strong> NoshBox grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for personal, non-commercial purposes, subject to your compliance with these Terms.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Usage Requirements</h2>
      <p class="mb-4"><strong>Acceptable Use:</strong> You may access, and we grant you non-exclusive right to use, the Services in accordance with these Terms. You agree to use the Service only for lawful purposes and in compliance with all applicable laws and regulations. You shall not engage in any activity that interferes with or disrupts the functioning of the Service or its associated networks.</p>
      <p class="mb-4"><strong>Feedback:</strong> We appreciate you using our app and value your feedback, comments, ideas, proposals and suggestions for improvements. If you provide such things, we may use it without restriction or compensation to you.</p>
      <p class="mb-4"><strong>Prohibited Conduct:</strong> When using the Service, you agree not to:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Violate any applicable laws or regulations;</li>
        <li>Infringe upon the intellectual property rights or other rights of third parties;</li>
        <li>Transmit any unauthorized or unsolicited advertising, promotional materials, or spam;</li>
        <li>Interfere with or disrupt the Service or its associated networks;</li>
        <li>Attempt to gain unauthorized access to any part of the Service or its systems;</li>
        <li>Engage in any activity that may harm, exploit, or endanger minors, customers or related staff including drivers;</li>
        <li>Impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.</li>
      </ul>
      <p class="mb-4"><strong>Third Party Services:</strong> Any third party services, software, or any other products you use in connection with the Services are subject to their own terms, and we are not responsible for third party products.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Content</h2>
      <p class="mb-4"><strong>User Content:</strong> You may have the opportunity to submit, post, or upload content to the Service ("User Content"). By submitting User Content, you represent and warrant that you have all necessary rights and permissions to do so, and that your User Content does not violate any third-party rights or applicable laws. NoshBox does not claim ownership of User Content, but you grant NoshBox a worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, modify, adapt, and display the User Content in connection with the provision of the Service.</p>
      <p class="mb-4"><strong>Accuracy:</strong> Data analytics, machine learning and artificial intelligence are constantly changing and requires consistent feedback, analysis and improvement. We are constantly working with experts to improve our Services to make them more accurate, safe, reliable and beneficial to you, your customers, and your business.</p>
      <p class="mb-4"><strong>Prohibited Content:</strong> You agree not to submit any User Content that:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Is unlawful, defamatory, obscene, or harmful;</li>
        <li>Violates the privacy or publicity rights of any person;</li>
        <li>Contains viruses, malware, or other harmful code;</li>
        <li>Harasses, threatens, or discriminates against any individual or group.</li>
      </ul>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Fees and Payments</h2>
      <p class="mb-4"><strong>Subscription Fees:</strong> Some features of the Service may require payment of subscription fees. By subscribing to the Service, you agree to pay all applicable fees and charges. Subscription fees are non-refundable unless otherwise stated.</p>
      <p class="mb-4"><strong>Payment Information:</strong> You agree to provide accurate and complete payment information. By providing payment information, you authorize NoshBox or its designated payment processor to charge the specified amount for the selected subscription plan.</p>
      <p class="mb-4"><strong>Price Changes:</strong> We may change our prices by posting notice to your account and/or to our website. Any price changes will be applicable to the Fees charged to your account immediately after the effective date of such change. Price Increases will be effective 15 days after they are posted, except for increases made to Beta Services(as defined in our Service Terms), or for legal reasons.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Confidentiality, Data Protection & Security</h2>
      <p class="mb-4"><strong>Confidentiality Information:</strong> During your use of the Service, you may have access to confidential or proprietary information belonging to NoshBox. You agree to maintain the confidentiality of such information and not to disclose or use it for any purpose other than as necessary to use the Service.</p>
      <p class="mb-4"><strong>Data Privacy:</strong> The collection, processing, and storage of your personal information are subject to NoshBox's Privacy Policy. By using the Service, you consent to the collection, processing, and storage of your personal information as described in the Privacy Policy.</p>
      <p class="mb-4"><strong>Data Security:</strong> NoshBox takes reasonable measures to protect the security and integrity of the Service and your personal information. However, you acknowledge that no data transmission or storage system can be guaranteed to be 100% secure.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Term and Termination</h2>
      <p class="mb-4"><strong>Term:</strong> These Terms shall remain in effect until terminated by either party.</p>
      <p class="mb-4"><strong>Termination and Effect on Termination:</strong> NoshBox may, in its sole discretion, suspend or terminate your access to the Service at any time and for any reason, including but not limited to a violation of these Terms. Upon termination, you will stop using the Services and you will promptly return or, if instructed by us, destroy any Confidential Information.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Indemnification; Disclaimer of Warranties; Limitations on Liability</h2>
      <p class="mb-4"><strong>Indemnity:</strong> You agree to indemnify, defend, and hold harmless NoshBox and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Your use of the Service;</li>
        <li>Your violation of these Terms;</li>
        <li>Your violation of any rights of another person or entity.</li>
      </ul>
      <p class="mb-4"><strong>Disclaimer of Warranties:</strong> THE SERVICE IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS. NOSHBOX MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, REGARDING THE SERVICE, ITS CONTENT, OR ITS AVAILABILITY. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NOSHBOX DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
      <p class="mb-4"><strong>Limitation on liability:</strong> IN NO EVENT SHALL NOSHBOX OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA, OR GOODWILL, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE OR THESE TERMS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL NOSHBOX’S TOTAL LIABILITY TO YOU FOR ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR THESE TERMS EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING OR USING THE SERVICE. THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Dispute Resolution</h2>
      <p class="mb-4"><strong>Term:</strong> YOU AGREE TO THE FOLLOWING MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS:</p>
      <p class="mb-4"><strong>MANDATORY ARBITRATION:</strong> These Terms shall be governed by and construed in accordance with the law of set Jurisdiction. Any dispute, claim, or controversy arising out of or relating to these Terms or the Service shall be resolved through binding arbitration. except that you have the means and right to opt out of these arbitration terms, and future changes to these arbitration terms, by contacting us within 30 days of agreeing to these arbitration terms or the relevant changes.</p>
      <p class="mb-4"><strong>Severability:</strong> If any part of this Section is found to be illegal or unenforceable, the remainder will remain in effect, except that unenforceability would allow Mass Filling, or a finding of partial illegality or representative arbitration, this section will be unenforceable in its entirety. Nothing in this section will be eligible to be waived or elsewise limit the right to seek injunctive relief from the public or any other non-waivable right, pending a ruling on the matter of such claim from the arbitrator.</p>
  
      <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">General Terms</h2>
      <p class="mb-4"><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and NoshBox regarding the use of the Service, superseding any prior agreements or understandings.</p>
      <p class="mb-4"><strong>Use of Brands:</strong> You may not use NoshBox, NoshCommerce or any of its affiliates' names, logos, or trademarks, without our prior written consent.</p>
      <p class="mb-4"><strong>Relationship of the Parties:</strong> These Terms do not create a partnership, agency, joint venture or relationship between you and NoshBox or any of NoshCommerce’s. Affiliates. NoshBox and you are independent contractors and neither party will have the binding power to incur obligation on the other's behalf or bind the other without the other party's prior written consent.</p>
      <p class="mb-4">By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:info@noshcommerce.com" class="text-blue-600 hover:underline">info@noshcommerce.com</a>.</p>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'TermsOfUse',
  };
  </script>
  
  <style scoped>
  </style>
  