<template>
  <div class="bg-gray-50 h-full overflow-auto px-4 py-4">
    <p class="text-red font-bold font-poppins text-xl border-b border-red pb-2">
      Table of Contents
    </p>
    <div class="pt-3 space-y-3">
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section1')"
      >
        <p class="font-mono font-bold text-red">01</p>
        <p class="font-medium text-[#333333]">Introduction</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section2')"
      >
        <p class="font-mono font-bold text-red">02</p>
        <p class="font-medium text-[#333333]">Franchise Overview</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">03</p>
        <p class="font-medium text-[#333333]">Franchise Opportunity</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">04</p>
        <p class="font-medium text-[#333333]">Franchise Structure</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section3')"
      >
        <p class="font-mono font-bold text-red">05</p>
        <p class="font-medium text-[#333333]">Initial Investment</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section4')"
      >
        <p class="font-mono font-bold text-red">06</p>
        <p class="font-medium text-[#333333]">Ongoing Fees</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section5')"
      >
        <p class="font-mono font-bold text-red">07</p>
        <p class="font-medium text-[#333333]">Franchisee Requirements</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section6')"
      >
        <p class="font-mono font-bold text-red">08</p>
        <p class="font-medium text-[#333333]">Franchise Application Process</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section6')"
      >
        <p class="font-mono font-bold text-red">09</p>
        <p class="font-medium text-[#333333]">Franchise Agreement</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section7')"
      >
        <p class="font-mono font-bold text-red">10</p>
        <p class="font-medium text-[#333333]">Training and Support</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section8')"
      >
        <p class="font-mono font-bold text-red">11</p>
        <p class="font-medium text-[#333333]">Marketing and Advertising</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section9')"
      >
        <p class="font-mono font-bold text-red">12</p>
        <p class="font-medium text-[#333333]">Operations and Management</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section10')"
      >
        <p class="font-mono font-bold text-red">13</p>
        <p class="font-medium text-[#333333]">Technology and Systems</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section11')"
      >
        <p class="font-mono font-bold text-red">14</p>
        <p class="font-medium text-[#333333]">Supply Chain and Vendors</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section12')"
      >
        <p class="font-mono font-bold text-red">15</p>
        <p class="font-medium text-[#333333]">Legal and Compliance</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('section13')"
      >
        <p class="font-mono font-bold text-red">16</p>
        <p class="font-medium text-[#333333]">Financial Management</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">17</p>
        <p class="font-medium text-[#333333]">Franchisee Responsibilities</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">18</p>
        <p class="font-medium text-[#333333]">Expansion Opportunities</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">19</p>
        <p class="font-medium text-[#333333]">Case Studies and Testimonials</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">20</p>
        <p class="font-medium text-[#333333]">Contact Information</p>
      </div>
      <div
        class="flex items-start space-x-4 cursor-pointer"
        @click="selected('')"
      >
        <p class="font-mono font-bold text-red">21</p>
        <p class="font-medium text-[#333333]">Appendices</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    selected(val) {
      this.$emit("onSelect", val);
    },
  },
};
</script>

<style>
</style>