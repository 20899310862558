<template>
  <div class="bg-white p-6 rounded-xl shadow-lg">
    <p class="text-xl font-medium">0% commissions for up to 30 days</p>
    <p class="font-light py-4">
      Partner with NoshBox to drive more sales — on the app and through your own
      website.
    </p>
    <input
      placeholder="Business Name"
      class="w-full bg-gray-150 rounded-lg py-2 px-4"
      v-model="businessName"
    />
    <input
      placeholder="Business Address"
      class="w-full bg-gray-150 rounded-lg py-2 px-4 mt-4"
      v-model="businessAddress"
    />
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4 pt-4">
      <input
        placeholder="Email Address"
        class="w-full bg-gray-150 rounded-lg py-2 px-4"
        v-model="emailAddress"
      />
      <input
        placeholder="Phone Number"
        class="w-full bg-gray-150 rounded-lg py-2 px-4"
        v-model="phoneNumber"
      />
    </div>
    <select
      class="w-full bg-gray-150 rounded-lg py-2 px-4 text-gray-400 mt-4"
      v-model="businessType"
    >
      <option>Select your business type</option>
    </select>
    <div class="flex justify-end pt-6">
      <SfButton
        class="rounded-full capitalize py-3 bg-red"
        @click="registerPartner"
        >Register</SfButton
      >
    </div>
    <p class="font-light pt-6">
      Already on DoorDash? <span class="text-red font-medium">Log in.</span>
    </p>
  </div>
</template>

<script>
import { SfButton } from "@storefront-ui/vue";
export default {
  components: {
    SfButton,
  },
  data() {
    return {
      businessAddress: "",
      businessName: "",
      emailAddress: "",
      phoneNumber: "",
      businessType: "",
    };
  },
  methods: {
    registerPartner() {
      const data = {
        tenancyName: this.businessName,
        name: this.businessName,
        adminEmailAddress: this.emailAddress,
        connectionString: this.phoneNumber,
        isActive: true,
      };
      this.$emit("register",data)
    },
  },
};
</script>

<style>
</style>