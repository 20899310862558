<template>
  <div class="flex items-center bg-gray-50 w-full px-4 py-2 rounded-full">
    <img src="@/assets/svg/search.svg" />
    <input
      :placeholder="placeholder"
      type="text"
      class="bg-transparent w-full focus:outline-none text-sm pl-2"
      v-model="inputValue"
      autocomplete="false"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["placeholder"],
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    ...mapActions(["SetAllProducts"]),
  },
  watch: {
    inputValue(newVal) {
      this.SetAllProducts({ searchField: newVal });
      this.$emit("new-Value",newVal)
    },
  },
};
</script>

<style>
</style>