import axios from "axios";
const main = {
  state: {
    favorities: [],
    addresses: []
  },
  getters: {
    getAllFavorities(state) {
      return state.favorities;
    },
    getAllAddresses(state){
      return state.addresses
    }
  },
  actions: {
    async SetAllFavorities({ commit }) {
      await axios
        .post("services/app/Shop/NearMe", {
          longitutde: 0,
          latitutde: 0,
          radius: 0,
        })
        .then((res) => {
          commit("setAllFavorities", res?.data?.result);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    async getAddress({commit},payload){
      await axios.get(`https://api.geoapify.com/v1/geocode/search?text=${payload}&format=json&apiKey=c47533fead95452f863f4dfcbe1103f0`).then((response)=>{
        console.log(response)
        commit('setAddress',response?.data?.results)
      })
    }
  },
  mutations: {
    setAllFavorities(state, response) {
      state.favorities = response;
    },
    setAddress(state,response){
      state.addresses = response;
    }
  },
};
export default main;
