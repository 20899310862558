<template>
  <div>
    <translate-modal
      :closeModal="closeModal"
      :isModalOpen="isModalOpen"
    ></translate-modal>
    <div class="z-10">
      <Auth
        :onToggle="onToggle"
        :isOpen="isOpen"
        :isModalVisible="isModalVisible"
        :types="types"
        :mode="mode"
        :changeActiveButton="changeActiveButton"
      />
    </div>
    <nav
      ref="gotohome"
      class="border-b-1 border-[#000000] border-opacity-[0.1] font-jakarta fixed bg-white w-full"
    >
      <div
        class="Herowrapper px-[16px] h-full w-full flex items-center justify-between"
      >
        <router-link to="/">
          <button class="md:w-[60px] w-[40px]">
            <img src="@/assets/HomePage/logo.png" alt="" />
          </button>
        </router-link>

        <button
          @click="activeSignup"
          class="block md:hidden w-[100px] py-1 h-40px text-base font-semibold bg-primaryGreen text-white rounded-full leading-8"
        >
          Sign in
        </button>
        <div class="items-center gap-7 hidden md:flex">
          <div
            class="flex mr-5 items-center gap-8 font-jakarta text-[15px] font-medium text-[#000000]"
          >
            <button @click="aboutus">
              <h3>About us</h3>
            </button>
            <button @click="convenience">
              <h3>Convenience Stores</h3>
            </button>
            <button @click="homechef">
              <h3>Home Chefs</h3>
            </button>
            <button @click="whyus">
              <h3>Why Choose us</h3>
            </button>
            <button @click="community">
              <h3>Franchising</h3>
            </button>
            <button @click="community">
              <h3>Community</h3>
            </button>
          </div>

          <div class="flex gap-2 items-center">
            <button
              @click="activeSignin"
              class="text-black-300 text-base rounded-full font-semibold"
            >
              Log in
            </button>
            <button
              @click="activeSignup"
              class="w-[130px] py-2 h-50px text-base font-semibold bg-primaryGreen text-white rounded-full leading-8"
            >
              Sign up
            </button>

            <div>
              <button
                @click="isModalOpen = true"
                class="bg-primaryGreen text-white p-2 rounded-full"
              >
                Translate
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Auth from "@/components/auth/modals/auth.vue";
// import SearchBox from "@/components/main/searchBox.vue";
import tnc from "@/assets/tnc/tnc.pdf";
import pp from "@/assets/tnc/privacypolicy.pdf";
import { Loader } from "@googlemaps/js-api-loader";
// import { Translator } from "vue-google-translate";

import TranslateModal from "../../components/reusable/TranslateModal.vue";

export default {
  components: { Auth, TranslateModal },
  data() {
    return {
      showButton: true, // Initially show the button
      types: [
        { mode: "Sign In", id: "signin" },
        { mode: "Sign Up", id: "signup" },
      ],
      mode: "signin",
      isOpen: false,
      isModalOpen: false,
      loggedIn: localStorage.getItem("loggedIn"),
      arrayofcountires: [
        {
          code: "en|af",
          title: "Afrikaans",
        },
        {
          code: "en|sq",
          title: "Albanian",
        },
        {
          code: "en|ar",
          title: "Arabic",
        },
        {
          code: "en|hy",
          title: "Armenian",
        },
        {
          code: "en|az",
          title: "Azerbaijani",
        },
        {
          code: "en|eu",
          title: "Basque",
        },
        {
          code: "en|be",
          title: "Belarusian",
        },
        {
          code: "en|bg",
          title: "Bulgarian",
        },
        {
          code: "en|ca",
          title: "Catalan",
        },
        {
          code: "en|zh-CN",
          title: "Chinese (Simplified)",
        },
        {
          code: "en|zh-TW",
          title: "Chinese (Traditional)",
        },
        {
          code: "en|hr",
          title: "Croatian",
        },
        {
          code: "en|cs",
          title: "Czech",
        },

        {
          code: "en|da",
          title: "Danish",
        },
        {
          code: "en|nl",
          title: "Dutch",
        },
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|et",
          title: "Estonian",
        },
        {
          code: "en|tl",
          title: "Filipino",
        },
        {
          code: "en|fi",
          title: "Finnish",
        },
        {
          code: "en|fr",
          title: "French",
        },

        {
          code: "en|de",
          title: "German",
        },
        {
          code: "en|el",
          title: "Greek",
        },
        {
          code: "en|hu",
          title: "Hungarian",
        },
        {
          code: "en|id",
          title: "Indonesian",
        },
        {
          code: "en|ga",
          title: "Irish",
        },
        {
          code: "en|it",
          title: "Italian",
        },
        {
          code: "en|ja",
          title: "Japanese",
        },
        {
          code: "en|ko",
          title: "Korean",
        },
        {
          code: "en|lt",
          title: "Lithuanian",
        },
        {
          code: "en|ms",
          title: "Malay",
        },
        {
          code: "en|no",
          title: "Norwegian",
        },
        {
          code: "en|pl",
          title: "Polish",
        },
        {
          code: "en|pt",
          title: "Portuguese",
        },
        {
          code: "en|ro",
          title: "Romanian",
        },
        {
          code: "en|ru",
          title: "Russian",
        },
        {
          code: "en|es",
          title: "Spanish",
        },
        {
          code: "en|sv",
          title: "Swedish",
        },
        {
          code: "en|th",
          title: "Thai",
        },
        {
          code: "en|tr",
          title: "Turkish",
        },
        {
          code: "en|uk",
          title: "Ukrainian",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
    },

    async getDistance() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GCP_ACCESS,
        version: "weekly",
        libraries: ["places"],
      });

      const google = await loader.load();
      const matrix = new google.maps.DistanceMatrixService();

      matrix.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(25.7694708, -80.259947)],
          destinations: [new google.maps.LatLng(25.768915, -80.254659)],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        function (response, status) {
          //do something

          console.log(response, status);
        }
      );
    },
    beforeEnter(el) {
      el.style.opacity = "0"; // Set the initial opacity to 0 before the animation starts
    },

    activeSignup() {
      window.scrollTo(0, 0);

      this.mode = "signup";
      this.changeActiveButton({ id: "signup" });

      this.onToggle();
    },
    activeSignin() {
      window.scrollTo(0, 0);

      this.mode = "signin";

      this.changeActiveButton({ id: "signin" });

      this.onToggle();
    },

    changeActiveButton(type) {
      this.mode = type.id;
    },

    onToggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },

    aboutus() {
      this.$refs.aboutus.scrollIntoView({ behavior: "smooth" });
    },
    convenience() {
      this.$refs.convenience.scrollIntoView({ behavior: "smooth" });
    },
    homechef() {
      this.$refs.homechef.scrollIntoView({ behavior: "smooth" });
    },
    whyus() {
      this.$refs.whyus.scrollIntoView({ behavior: "smooth" });
    },
    community() {
      this.$refs.community.scrollIntoView({ behavior: "smooth" });
    },
    gotohome() {
      this.$refs.gotohome.scrollIntoView({ behavior: "smooth" });
    },
    handleClick() {
      this.$refs.art1.scrollIntoView({ behavior: "smooth" });
    },

    showTnc() {
      const link = document.createElement("a");
      link.href = tnc;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showpp() {
      const link = document.createElement("a");
      link.href = pp;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    // Add a timer to hide the button after a certain time (you can adjust the delay as needed)
    setTimeout(() => {
      this.showButton = false;
    }, 3000); // Hide the button after 3 seconds (3000 milliseconds)
  },
  computed: {
    isModalVisible() {
      return this.isOpen;
    },
  },
};
</script>

<style>
.joinSection {
  background-image: url("../../assets/HomePage/Groupwave.png");
  background-size: contain;
  @apply p-4 md:h-[400px] w-full rounded-3xl bg-primaryGreen relative flex items-center flex-col justify-center gap-4;
}

.burger-enter-from {
  opacity: 0;
}

.burger-enter-to {
  opacity: 1;
}

.burger-enter-active {
  transition: all 2s ease;
}

.burger-leave-from {
  opacity: 1;
}

.burger-leave-to {
  opacity: 0;
}

.burger-leave-active {
  transition: all 2s ease;
}

.wrapper {
  width: 100%;
  position: relative;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Herowrapper {
  width: 100%;
  position: relative;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
</style>