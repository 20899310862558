<template>
  <LandingLayout>
    <div class="flex justify-center">
      <div class="xl:w-9/12 lg:w-10/12 w-full px-5">
        <h1 class="pt-12 text-4xl font-bold">{{ category }}</h1>
        <div>
          <p
            class="border-b border-gray-100 text-2xl font-bold pb-2 pt-12 mb-3"
          >
            Home Chef's
          </p>
          <div
            class="pt-3 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5"
          >
            <StoreCard
              v-for="(store, i) in getAllhomeChefStore"
              :store="store?.shopDetails"
              @handleClick="$router.push(`/customer/store/${store.shopId}`)"
              :key="i"
            />
          </div>
        </div>

        <!-- <div v-else>
          <div class="w-100 flex items-center justify-center flex-col">
            <img class="w-[40%]" src="@/assets/nostoresfound.svg" alt="" />

            <h3 class="text-3xl font-jakarta font-bold">No Stores Found</h3>
          </div>
        </div> -->
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/layouts/landingLayout.vue";
import StoreCard from "../../../components/reusable/storeCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    LandingLayout,
    StoreCard,
  },

  async mounted() {
    await this.SetAllhomeChefStore();
  },
  methods: {
    ...mapActions(["SetAllhomeChefStore"]),
  },
  computed: {
    ...mapGetters(["getAllhomeChefStore"]),
  },
};
</script>

<style></style>
