<template>
  <div>
    <transition name="fade">
      <div v-if="openModal">
        <section class="absolute left-0 top-0 h-[100vh] w-full">
          <div
            @click="handleOpenModal"
            class="absolute opacity-70 inset-0 z-40 bg-black"
            :class="openModal ? 'overflow-hidden' : ''"
          ></div>

          <div
            class="overflow-y-auto absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-full max-w-lg rounded-xl shadow-lg bg-white z-50"
            :class="'h-[80vh]'"
          >
            <button @click="handleOpenModal" class="absolute top-4 right-4">
              <img src="@/assets/svg/close.svg" alt="" />
            </button>
            <div class="px-4 py-6">
              <h3 class="font-bold text-3xl text-[#191919] leading-10">
                Green Apple Gourmet
              </h3>
            </div>

            <div class="h-[230px]">
              <googleMap
                :isAdjust="false"
                :address="
                  getProfileInfo?.address
                    ? getProfileInfo?.address
                    : guestAddress?.placeName
                "
                :handleTooFar="handleTooFar"
                :updateLocation="updateLocation"
              />
            </div>

            <div class="px-4 mt-4">
              <div
                class="w-full flex justify-between border-b-1 border-grey-1 pb-2"
              >
                <div class="flex items-center gap-4">
                  <img src="@/assets/storePage/Info/pin.svg" alt="" />

                  <div>
                    <h6 class="text-base">3762 Boston Road</h6>
                    <p class="text-sm font-semibold text-grey-400">Bronx, NY</p>
                  </div>
                </div>

                <button>
                  <img src="@/assets/storePage/Info/link.svg" alt="" />
                </button>
              </div>

              <!-- OPEN TIME -->
              <div
                class="w-full flex justify-between border-b-1 border-grey-1 pb-2 mt-4"
              >
                <div class="flex items-center gap-4">
                  <img src="@/assets/storePage/Info/time.svg" alt="" />

                  <div>
                    <h6 class="text-base text-green-600">Open</h6>
                    <p class="text-sm font-semibold text-grey-600">
                      Accepting DoorDash orders until 6:40 PM
                    </p>
                  </div>
                </div>

                <button>
                  <img src="@/assets/storePage/Info/dropdown.svg" alt="" />
                </button>
              </div>
              <!-- PHONE -->
              <div
                class="w-full flex justify-between border-b-1 border-grey-1 pb-4 mt-4"
              >
                <div class="flex items-center gap-4">
                  <img src="@/assets/storePage/Info/phone.svg" alt="" />

                  <div>
                    <h6 class="text-base">+13479479200</h6>
                  </div>
                </div>

                <button>
                  <img src="@/assets/storePage/Info/link.svg" alt="" />
                </button>
              </div>
            </div>

            <div class="flex justify-end mt-2 px-4">
              <button
                @click="handleOpenModal"
                class="bg-red text-base font-bold text-white p-2 rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import googleMap from "@/components/reusable/goolemap.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return { isTooFar: false, location: {} };
  },
  components: { googleMap },

  methods: {
    handleTooFar(isFar) {
      this.isTooFar = isFar;
    },
    updateLocation(lat, long) {
      this.location = {
        lat,
        long,
      };
    },
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
  props: ["openModal", "handleOpenModal"],
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
