<template>
  <div class="w-full pt-6">
    <VueSlickCarousel
      :slidesToShow="slidesToShow"
      ref="carousel"
      :responsive="responsive"
    >
      <div v-for="(product, i) in products" :key="i" class="px-3">
        <ProductCard
          :isLogo="isLogo"
          :product="product"
          @add="$emit('addProduct', product)"
          :isAdded="isInCart(product)"
          :isLike="isLike"
          :isStore="isStore"
          :tag="showImgTag"
          :isAddButton="isAddButton"
          @visitStore="(product) => $emit('visitStore', product)"
          :storeId="product.storeId"
          :savedShop="product.isSavedShop"
          @increaseQuantity="$emit('increaseQuantity', isInCart(product))"
          @decreaseQuantity="$emit('decreaseQuantity', isInCart(product))"
        />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import ProductCard from "./productCard.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  props: [
    "products",
    "slidesToShow",
    "isAddButton",
    "showImgTag",
    "cartItems",
    "isLike",
    "isLogo",
    "isStore",
  ],
  components: {
    ProductCard,
    VueSlickCarousel,
  },
  data() {
    return {
      // products: carouselData,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    isInCart(items) {
      if (this.cartItems) {
        return this.cartItems.find((val) => {
          return val.foodMenuId === items.id;
        });
      } else {
        return false;
      }
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/vue/styles";

.carousel {
  padding: 0;
  background-color: white;

  @include for-desktop {
    margin: 0;
  }

  &__item {
    margin: 1.9375rem 0 2.4375rem 0;
  }
}
</style>
