<template>
  <div>
    <button @click="toggleWishlist">
      <i
        style="color: #e1236f"
        :class="{
          'fas fa-heart fa-lg': inWishlist,
          'far fa-heart fa-lg': !inWishlist,
        }"
      ></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ["isLiked"],
  data() {
    return {
      inWishlist: this.isLiked,
    };
  },
  methods: {
    toggleWishlist() {
      this.inWishlist = !this.inWishlist;

      // Here, you might dispatch an action or emit an event to update
      // your global state or parent component with the new state.
      // For example:
      // this.$emit('update-wishlist', { id: this.itemId, inWishlist: this.inWishlist })
      // or
      // this.$store.dispatch('UPDATE_WISHLIST', { id: this.itemId, inWishlist: this.inWishlist })
    },
  },
};
</script>
