var render = function render(){var _vm=this,_c=_vm._self._c;return _c('landing-layout',[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"xl:w-9/12 lg:w-10/12 w-full px-5"},[_c('div',{staticClass:"flex py-3"},[_c('h1',{staticClass:"font-bold text-sm underline cursor-pointer",on:{"click":function($event){return _vm.$router.push(`/customer/store/${_vm.storeId}`)}}},[_vm._v(" Store Home ")]),_c('h2',{staticClass:"pl-2 font-bold text-sm text-gray-500"},[_vm._v(" / "+_vm._s(_vm.categoryId?.name)+" ")])]),_c('div',{staticClass:"flex md:flex-row flex-col justify-between items-center py-3"},[_c('p',{staticClass:"text-black text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.categoryId?.name)+" ")]),_c('div',{staticClass:"md:w-1/3 w-full pt-3"},[_c('Search',{attrs:{"placeholder":`Search ${_vm.getStoreInfo?.shopName}`},on:{"new-Value":_vm.searchProducts}})],1)]),_c('Categories',{ref:`categories.category.tag`,attrs:{"tags":_vm.getStoreInfo.categoryTags,"slidesToShow":12,"isAddButton":true,"showImgTag":false},on:{"handleClick":(val) =>
            _vm.$router.push(
              `/customer/store/${
                _vm.$route.params.storeId
              }/category/${JSON.stringify(val)}`
            )}}),(_vm.getStoreInfoByCategory?.categoryTags[0].items)?_c('div',{staticClass:"grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-8 py-10"},_vm._l((_vm.getStoreInfoByCategory?.categoryTags[0]
            .items),function(product,index){return _c('ProductCard',{key:index,attrs:{"product":product,"isAdded":_vm.isInCart(product),"tag":false,"isAddButton":true},on:{"add":function($event){return _vm.createCart({ product: product })},"increaseQuantity":function($event){_vm.increaseQuantity(_vm.isInCart(product))},"decreaseQuantity":function($event){_vm.decreaseQuantity(_vm.isInCart(product))}}})}),1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }