<template>
  <!-- FULL MENU -->

  <div>
    <div ref="beforeSection1">
      <!-- Content before Section 1 -->
    </div>
    <div>
      <div class="mt-4">
        <div
          class="flex flex-col sticky top-[81px] bg-white py-2 z-10 border-b-1 border-grey-100"
        >
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-base font-semibold">Full Menu</h3>
              <h3 class="text-xs font-medium">all day</h3>
            </div>

            <div class="flex items-center gap-2">
              <input
                type="text"
                placeholder="Search Store Menu"
                class="w-[340px] h-[40px] bg-[#f1f1f1] rounded-full px-4"
              />
            </div>
          </div>

          <!-- MENU -->
          <div class="mt-4 py-4 flex items-center gap-2">
            <button><img src="@/assets/storePage/menu.svg" alt="" /></button>
            <div class="flex items-center gap-4 whitespace-nowrap">
              <button
                class="text-bsm font-semibold"
                :class="currentSection === '1' ? 'text-red' : 'text-black-300'"
              >
                <a href="#popular"> Popular Items</a>
              </button>
              <button
                class="text-sm font-semibold"
                :class="currentSection === '2' ? 'text-red' : 'text-black-300'"
              >
                <a href="#combos"> Combos</a>
              </button>
              <button
                class="text-sm font-semibold"
                :class="currentSection === '3' ? 'text-red' : 'text-black-300'"
              >
                <a href="#smoothies"> Smoothies</a>
              </button>
              <button
                class="text-sm font-semibold"
                :class="currentSection === '4' ? 'text-red' : 'text-black-300'"
              >
                <a href="#build"> Build Your Own</a>
              </button>
              <button class="text-sm font-semibold text-grey-600">
                Vegan Food
              </button>
              <button class="text-sm font-semibold text-grey-600">
                Salads
              </button>
              <button class="text-sm font-semibold text-grey-600">
                Halal Food
              </button>
              <button class="text-sm font-semibold text-grey-600">
                Signature Made Food
              </button>
            </div>
          </div>
        </div>

        <!-- FEATURED PRODUCTS -->

        <div ref="area1" class="my-4" id="popular">
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-semibold">Popular Items</h3>

            <div class="flex items-center gap-2">
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/leftblack.svg" alt="" />
              </button>
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/rightblack.svg" alt="" />
              </button>
            </div>
          </div>

          <div class="mt-4 grid grid-cols-2 gap-4">
            <ProductCard
              :isImage="true"
              :productName="'Chicken Over Rice'"
              :price="12.0"
            />
            <ProductCard :productName="'Chopped cheese'" :price="8.5" />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
          </div>
        </div>

        <!-- COMBOS -->

        <div ref="area2" class="my-4" id="combos">
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-semibold">Combos</h3>

            <div class="flex items-center gap-2">
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/leftblack.svg" alt="" />
              </button>
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/rightblack.svg" alt="" />
              </button>
            </div>
          </div>

          <div class="mt-4 grid grid-cols-2 gap-4">
            <ProductCard
              :isImage="true"
              :productName="'Chicken Over Rice'"
              :price="12.0"
            />
            <ProductCard :productName="'Chopped cheese'" :price="8.5" />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
          </div>
        </div>
        <!-- SMOOTHIES -->

        <div ref="area3" class="my-4" id="smoothies">
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-semibold">Smoothies</h3>

            <div class="flex items-center gap-2">
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/leftblack.svg" alt="" />
              </button>
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/rightblack.svg" alt="" />
              </button>
            </div>
          </div>

          <div class="mt-4 grid grid-cols-2 gap-4">
            <ProductCard
              :isImage="true"
              :productName="'Chicken Over Rice'"
              :price="12.0"
            />
            <ProductCard :productName="'Chopped cheese'" :price="8.5" />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
          </div>
        </div>

        <!-- BUILD YOUR OWN -->

        <div ref="area4" id="build" class="my-4">
          <div class="flex items-center justify-between">
            <h3 class="text-2xl font-semibold">Build Your Own</h3>

            <div class="flex items-center gap-2">
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/leftblack.svg" alt="" />
              </button>
              <button
                class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
              >
                <img src="@/assets/storePage/rightblack.svg" alt="" />
              </button>
            </div>
          </div>

          <div class="mt-4 grid grid-cols-2 gap-4">
            <ProductCard
              :isImage="true"
              :productName="'Chicken Over Rice'"
              :price="12.0"
            />
            <ProductCard :productName="'Chopped cheese'" :price="8.5" />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
            <ProductCard
              :productName="'Bacon Egg And Cheese On A Roll'"
              :price="6.0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductCard from "./ProductCard.vue";
export default {
  data() {
    return {
      sections: [],
      section1Reached: false,
      currentSection: null,
    };
  },
  components: { ProductCard },
  mounted() {
    this.sections = Array.from(
      { length: 4 },
      (_, index) => this.$refs[`area${index + 1}`].offsetTop
    );
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const section1Top = this.$refs.area1.offsetTop;
      const beforeSection1Height = this.$refs.beforeSection1.offsetTop;

      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(
        "section1Top",
        section1Top,
        "beforeSection1Height",
        beforeSection1Height,
        "scrollPosition",
        scrollPosition
      );
      if (scrollPosition >= beforeSection1Height) {
        this.section1Reached = true;
      }

      if (this.section1Reached) {
        const { sections } = this;
        const numSections = sections.length;
        let currentSection = null;

        for (let i = numSections - 1; i >= 0; i--) {
          if (scrollPosition >= sections[i] - 100) {
            currentSection = `${i + 1}`;
            break;
          }
        }

        this.currentSection = currentSection;

        console.log(this.currentSection);
      }
    },
  },
};
</script>

<style scoped></style>
