<template>
  <div class="flex justify-center border-b border-gray-100 bg-white">
    <div
      class="flex bg-white items-center justify-between space-x-4 lg:px-6 py-3 px-2 xl:w-5/6 w-full"
    >
      <div>
        <div class="flex items-center">
          <img src="@/assets/logo-simple.jpeg" alt="logo" class="w-10" />
          <p class="uppercase text-red">Partner</p>
        </div>
      </div>
      <div class="md:flex hidden items-center">
        <div class="dropdown">
          <label
            tabindex="0"
            class="btn m-1 flex items-center space-x-1 font-medium text-lg"
          >
            <p>Products</p>
            <img src="@/assets/svg/bottom.svg" class="w-5" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li><a>Item 1</a></li>
            <li><a>Item 2</a></li>
          </ul>
        </div>
        <div class="dropdown">
          <label
            tabindex="0"
            class="btn m-1 flex items-center space-x-1 font-medium text-lg"
          >
            <p>Tools</p>
            <img src="@/assets/svg/bottom.svg" class="w-5" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li><a>Item 1</a></li>
            <li><a>Item 2</a></li>
          </ul>
        </div>
        <div class="dropdown">
          <label
            tabindex="0"
            class="btn m-1 flex items-center space-x-1 font-medium text-lg"
          >
            <p>Business Type</p>
            <img src="@/assets/svg/bottom.svg" class="w-5" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li><a>Item 1</a></li>
            <li><a>Item 2</a></li>
          </ul>
        </div>
        <div class="dropdown">
          <label
            tabindex="0"
            class="btn m-1 flex items-center space-x-1 font-medium text-lg"
          >
            <p>Resources</p>
            <img src="@/assets/svg/bottom.svg" class="w-5" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li><a>Item 1</a></li>
            <li><a>Item 2</a></li>
          </ul>
        </div>
        <p class="font-medium text-lg cursor-pointer pl-4">Pricing</p>
        <p class="font-medium text-lg cursor-pointer pl-6">Contact</p>
      </div>
      <div class="flex justify-end">
        <a href="https://dev-NoshBox-admin.netlify.app/">
          <p class="cursor-pointer font-medium text-lg">Login</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>