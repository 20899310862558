<template>
  <div
    class="grid grid-cols-2 py-3 border-b border-gray-100 items-center px-12"
  >
    <div
      class="flex items-center space-x-3 cursor-pointer"
      @click="$router.go(-1)"
    >
      <img src="@/assets/svg/left-icon.svg" alt="icon" class="w-6" />
      <p class="text-primary cursor-pointer">{{ $t("backToStore") }}</p>
    </div>
    <div>
      <router-link to="/customer/main">
        <div class="flex items-center md:space-x-2 space-x-0">
          <img
            src="@/assets/logo-simple.jpeg"
            class="md:w-14 w-24 rounded-lg"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
