import axios from "axios";
import router from "../../router";
const auth = {
  state: {
    favorities: [],
    register: null,
  },
  getters: {
    getRegister(state) {
      return state.register;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern, no-unused-vars
    async register({ commit }, payload) {
      return await axios.post("services/app/Customers/Register", payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async login({}, payload) {
      const response = await axios
        .post("TokenAuth/Authenticate", payload.data, {
          headers: {
            "Abp.TenantId": "3",
          },
        })
        .then((res) => {
          payload.loading = false;

          console.log(res);
          localStorage.setItem("token", res?.data?.result?.accessToken);
          sessionStorage.setItem("userId", res?.data?.result?.userId);
          router.push("/customer/main");
        })
        .catch((error) => {
          console.log("error", error);
          return error;
        });
      return response;
    },
  },
  mutations: {
    setAllFavorities(state, response) {
      state.favorities = response;
    },
    setRegister(state, response) {
      state.register = response;
    },
  },
};
export default auth;
