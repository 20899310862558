<template>
  <div
    class="w-[425px] h-[145px] border-1 border-grey-300 flex justify-between rounded-md"
  >
    <div class="p-4 flex-[3] flex flex-col justify-center">
      <h4 class="text-base font-bold">{{ productName }}</h4>
      <p class="text-bsm font-medium">${{ price }}</p>
    </div>
    <div class="relative flex-[1.95]">
      <img
        v-if="isImage"
        src="@/assets/storePage/featuredItems.png"
        alt="productImage"
        class="w=full h-full"
      />
      <button
        class="text-bsm font-semibold bg-white text-black-300 px-2 p-1 absolute right-2 bottom-2 rounded-full"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: ["image", "isImage", "productName", "price"],
};
</script>

<style></style>
