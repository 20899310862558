<template>
  <div id="map"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { mapGetters } from "vuex";
import { calculateDistance } from "../../helpers/calculateDistance";
export default {
  props: ["isAdjust", "handleTooFar", "address", "updateLocation"],
  name: "Map",
  async mounted() {
    await this.loadMap();
  },

  data() {
    return {
      latitude: "",
      longitude: "",
      addresslat: "",
      addresslon: "",
    };
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
  watch: {
    address(value) {
      console.log("ADDRESS", value);
      this.loadMap();
    },
  },
  methods: {
    async loadMap() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GCP_ACCESS,
        version: "weekly",
        libraries: ["places"],
      });

      const google = await loader.load();
      let locationAddress = await this?.address;

      const geocoder = new google.maps.Geocoder();

      if (locationAddress) {
        geocoder.geocode({ address: locationAddress }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.addresslat = results[0].geometry.location.lat();
              this.addresslon = results[0].geometry.location.lng();

              const map = new google.maps.Map(document.getElementById("map"), {
                center: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
                zoom: 12,
              });
              this.latitude = results[0].geometry.location.lat();
              this.longitude = results[0].geometry.location.lng();

              this.getAddress();

              const marker = new google.maps.Marker({
                position: map.getCenter(),
                map: map,
                title: "Marker Title",

                icon: {
                  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Map_marker_font_awesome.svg/1200px-Map_marker_font_awesome.svg.png",
                  scaledSize: new google.maps.Size(32, 32), // adjust the size as needed
                  anchor: new google.maps.Point(16, 16), // adjust the anchor point if necessary
                },
                draggable: this.isAdjust, // Enable dragging the marker
              });

              // Update marker position when the map is dragged
              google.maps.event.addListener(map, "drag", () => {
                if (this.isAdjust) {
                  marker.setPosition(map.getCenter());
                  const position = marker.getPosition();

                  this.latitude = position.lat();
                  this.longitude = position.lng();

                  this.updateLocation(position.lat(), position.lng());
                  this.isToofar();
                }
              });

              // Update map center when the marker is dragged
              google.maps.event.addListener(marker, "drag", () => {
                map.setCenter(marker.getPosition());
              });

              // Do something with the latitude and longitude
            } else {
              const map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: 37.7749, lng: -122.4194 },
                zoom: 12,
              });

              const marker = new google.maps.Marker({
                position: map.getCenter(),
                map: map,
                title: "Marker Title",

                icon: {
                  url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Map_marker_font_awesome.svg/1200px-Map_marker_font_awesome.svg.png",
                  scaledSize: new google.maps.Size(32, 32), // adjust the size as needed
                  anchor: new google.maps.Point(16, 16), // adjust the anchor point if necessary
                },
                draggable: this.isAdjust, // Enable dragging the marker
              });

              // Update marker position when the map is dragged
              google.maps.event.addListener(map, "drag", () => {
                if (this.isAdjust) {
                  marker.setPosition(map.getCenter());
                  const position = marker.getPosition();

                  this.latitude = position.lat();
                  this.latitude = position.lng();
                }
              });

              // Update map center when the marker is dragged
              google.maps.event.addListener(marker, "drag", () => {
                map.setCenter(marker.getPosition());
              });
              console.error("No results found");
            }
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        });
      }
    },

    async getAddress() {
      const loader = new Loader({
        apiKey: "AIzaSyAkr3zw0bOjRwMUWpTyOikFfpJrA5XYodQ",
        version: "weekly",
        libraries: ["places"],
      });

      const google = await loader.load();

      const geocoder = new google.maps.Geocoder();
      const latLng = new google.maps.LatLng(this.latitude, this.longitude);

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0];

            let splitedAddress = address.formatted_address.split(",");

            localStorage.setItem(
              "address",
              JSON.stringify({
                houseNumber: splitedAddress[0],
                rest: `${splitedAddress.slice(1).join(" ")}`,
              })
            );
            // Do something with the address
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    },

    async isToofar() {
      const thresholdDistance = 4; // Threshold distance in kilometers
      const distance = calculateDistance(
        this.addresslat, // PRIMARY ADDRESS COORDS
        this.addresslon, // PRIMARY ADDRESS COORDS
        this.latitude, // MARKER  COORDS
        this.longitude // MARKER  COORDS
      ); // Calculate distance using the provided coordinates

      if (distance === 0 || distance > thresholdDistance) {
        if (distance > thresholdDistance) {
          this.handleTooFar(true);
        } else {
          // Handle case when marker is at the provided address
          this.handleTooFar(false);
        }
      } else {
        // Handle small movements within the threshold
        this.handleTooFar(false);
      }
    },
  },
};
</script>

<style>
#map {
  height: 100%;
  border-radius: 4px;
}
</style>
