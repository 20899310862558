<template>
  <div class="grid lg:grid-cols-2 grid-cols-1 h-screen">
    <div class="lg:pt-32 md:pt-12 pt-6 lg:px-24 md:12 px-5">
      <div v-if="isloading === false">
        <div class="flex space-x-3 items-center" @click="navigateToLastRoute">
          <img
            src="@/assets/svg/left-icon.svg"
            alt="some"
            class="cursor-pointer"
          />
          <!-- <router-link to="/customer/main"> -->
          <div class="flex space-x-3 items-center cursor-pointer">
            <img
              src="@/assets/logo-simple.jpeg"
              alt="logo"
              class="w-12 rounded-lg shadow-md"
            />
            <p class="font-semibold">NoshBox</p>
          </div>
          <!-- </router-link> -->
        </div>

        <p class="text-gray-500 font-semibold pt-4 md:pl-10 pl-0">
          {{ $t("pay") }} NoshBox
        </p>
        <p class="text-3xl font-semibold md:pl-10 pl-0 pt-2">
          ${{ amountDue }}
        </p>

        <div class="md:pl-10 pl-0 pt-8 space-y-3 max-h-96 overflow-auto">
          <CheckoutItem
            v-for="(item, index) in getCartInfo?.shoppingCartItems"
            :key="index"
            :item="item"
          />
        </div>
        <div class="lg:flex hidden space-x-3 md:pl-10 pl-0 absolute bottom-32">
          <p class="text-gray-400">
            {{ $t("poweredBy") }} <span class="font-semibold">NoshBox</span>
          </p>
          <div
            class="pl-3 border-l-2 border-gray-100 flex items-center space-x-3"
          >
            <p class="underline text-gray-400">{{ $t("terms") }}</p>
            <p class="underline text-gray-400">{{ $t("privacy") }}</p>
          </div>
        </div>
      </div>

      <FormSkelton v-else />
    </div>

    <div class="shadow-lg lg:pt-16 pt-12 lg:px-24 md:px-12 px-5 space-y-4">
      <div v-if="isloading === false">
        <div v-if="getAllCards?.length > 0" class="">
          <p class="pb-8 text-2xl font-bold text-red">
            Chose a save card to process payment
          </p>

          <div
            v-for="(card, index) in getAllCards"
            :key="index"
            class="flex items-center justify-between pb-3"
          >
            <input
              type="radio"
              :id="card?.cardId"
              :value="card?.cardId"
              class="hidden peer"
              v-model="selected"
            />
            <label
              :for="card?.cardId"
              class="inline-flex items-center px-5 py-2 border-2 justify-between w-full text-gray-500 bg-white border-gray-100 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="flex items-center space-x-3">
                  <img src="@/assets/svg/visa-card.svg" class="w-12" />
                  <div>
                    <p class="w-full text-lg font-semibold">
                      **** **** **** {{ card?.last4 }}
                    </p>
                    <p
                      v-if="card?.isDefault === true"
                      class="w-full text-sm font-semibold"
                    >
                      Default Card for payment
                    </p>
                  </div>
                </div>
              </div>

              <button
                id="card-button"
                type="button"
                @click="createPayment(selected)"
                class="w-16 h-16 rounded-full flex items-center justify-center"
                :class="
                  selected === card?.cardId
                    ? 'bg-red text-white'
                    : 'bg-grey-100   text-black-300'
                "
              >
                <p v-if="!paymentLoading">Pay</p>
                <div
                  v-else
                  :class="selected === card?.cardId ? 'block' : 'hidden'"
                >
                  <div class="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </button>
            </label>
          </div>
          <div class="flex items-center justify-between pb-3">
            <input
              class="text-red hidden peer"
              type="radio"
              id="newcard"
              value="new card"
              v-model="selected"
            />
            <label
              for="newcard"
              class="inline-flex items-center border-2 justify-between w-full p-5 text-gray-500 bg-black-300 border-gray-100 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-600 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="flex items-center space-x-3">
                  <p class="text-white w-full text-lg font-semibold">
                    ADD NEW CARD
                  </p>
                </div>
              </div>
            </label>
          </div>
          <!-- <div class="flex items-center space-x-3">
          <div class="border-b border-gray-300 w-full"></div>
          <p>
            or
          </p>
          <div class="border-b border-gray-300 w-full"></div>
        </div> -->
        </div>

        <!-- <div class="grid w-full gap-3 md:grid-cols-1">
          <div>
            <input
              type="radio"
              id="hosting-small"
              name="hosting"
              value="hosting-small"
              class="hidden peer"
              required
            />
            <label
              for="hosting-small"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">0-50 MB</div>
              </div>
              <svg
                class="w-5 h-5 ml-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </label>
          </div>
        </div> -->

        <form
          v-if="selected === 'new card' || getAllCards?.length === 0"
          id="payment-form"
        >
          <div class="pb-3">
            <label class="text-gray-500"> Email </label>

            <input
              placeholder=""
              v-model="email"
              class="w-full bg-transparent border border-gray-100 shadow-sm rounded-md text-lg py-1 px-2 mt-2 focus:outline-none"
            />
            <p class="text-red-500 text-xs" v-if="!isValidEmail">
              {{ $t("validEmail") }}
            </p>
          </div>

          <div class="pb-3">
            <label class="text-gray-500">{{ $t("nameOnCard") }} </label>
            <input
              placeholder=""
              v-model="name"
              class="w-full bg-transparent border border-gray-100 shadow-sm rounded-md text-lg py-1 px-2 mt-2 focus:outline-none"
            />
          </div>
          <div class="pb-3">
            <label class="text-gray-500">{{ $t("address") }} </label>
            <input
              placeholder=""
              v-model="address"
              class="w-full bg-transparent border border-gray-100 shadow-sm rounded-md text-lg py-1 px-2 mt-2 focus:outline-none"
            />
          </div>
          <label class="text-gray-500 mb-2">
            {{ $t("cardInformation") }}
          </label>
          <div id="card-container" class=""></div>
          <button
            id="card-button"
            type="button"
            class="w-full rounded-md capitalize bg-red text-white text-lg py-2"
            @click="handlePay"
          >
            <p v-if="!paymentLoading">{{ $t("pay") }}</p>
            <p v-else>processing your payment</p>
          </button>
        </form>

        <div id="payment-status-container"></div>
        <button
          v-if="selected !== 'new card'"
          id="card-button"
          type="button"
          class="w-full rounded-md capitalize bg-red text-white text-lg py-2"
          @click="createPayment(selected)"
        >
          <p v-if="!paymentLoading">{{ $t("pay") }}</p>
          <p v-else>processing your payment</p>
        </button>
      </div>
      <FormSkelton v-else />
    </div>
  </div>
</template>

<script>
import CheckoutItem from "@/components/checkout/checkoutItem.vue";
import FormSkelton from "../../../components/skeltons/formSkelton.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    CheckoutItem,
    FormSkelton,
  },
  data() {
    return {
      card: null,
      selected: null,
      name: "",
      email: "",
      address: "",
      isloading: false,
      paymentLoading: false,
    };
  },
  async created() {
    this.isloading = true;
    await this.InitializingSquare();
    await this.GetCart({ id: this.$route.params.storeId });
    await this.SetAllCards();
    this.isloading = false;
    await this.getCustomerProfile();
    this.name = this.getProfileInfo?.name;
    this.email = this.getProfileInfo?.email;
    this.address = this.getProfileInfo?.address;
    if (this.getAllCards.length > 0) {
      this.getAllCards.forEach((element) => {
        if (element.isDefault) {
          this.selected = element.cardId;
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getCartInfo", "getAllCards", "getProfileInfo"]),
    amountDue() {
      return this.getCartInfo?.taxAmount + this.getCartInfo?.totalPrice;
    },
    isValidEmail() {
      return this.email !== ""
        ? /^[^@]+@\w+(\.\w+)+\w$/.test(this.email)
        : true;
    },
  },

  watch: {
    selected(newVal) {
      if (newVal === "new card") {
        this.InitializingSquare();
      }
    },
  },
  methods: {
    ...mapActions([
      "getCountries",
      "GetCart",
      "SetAllCards",
      "getCustomerProfile",
      "SetAllCarts",
      "CreateCart",
    ]),

    async InitializingSquare() {
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }

      let payments;
      try {
        payments = window.Square.payments(
          "sandbox-sq0idb-CZ31SdvfjCaVnNI9Yiynkw",
          "LHVY73BCEXNZH"
        );
      } catch {
        const statusContainer = document.getElementById(
          "payment-status-container"
        );
        statusContainer.className = "missing-credentials";
        statusContainer.style.visibility = "visible";
        return;
      }

      try {
        this.card = await this.initializeCard(payments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    },
    async initializeCard(payments) {
      const cards = await payments.card();
      await cards.attach("#card-container");
      return cards;
    },
    async handlePaymentMethodSubmission(paymentMethod) {
      try {
        // disable the submit button as we await tokenization and make a payment request.
        // cardButton.disabled = true;

        const token = await this.tokenize(paymentMethod);
        console.log("Card nummber" + this.card.cardNumber);
        const paymentResults = await this.createPayment(token);
        // displayPaymentResults("SUCCESS");

        console.debug("Payment Success", paymentResults);
      } catch (e) {
        // cardButton.disabled = false;
        // displayPaymentResults("FAILURE");
        console.error(e.message);
      }
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        this.$swal({
          title: "Payment is processing!",
          imageUrl: require("../../../assets/loader.gif"), // Replace with your icon's URL
          imageWidth: 100, // Adjust as needed
          imageHeight: 100,
          showCancelButton: false, // Hide the default "Cancel" button
          showConfirmButton: false, // Hide the default "Confirm" button
          showCloseButton: true,
        });
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    },
    async createPayment(token) {
      //
      this.paymentLoading = true;
      const tokenC = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      const paymentResponse = await axios
        .post(
          "services/app/ShoppingCart/ProcessPayment",
          {
            //locationId: "L7M0989KNDHXH",
            name: this.name,
            email: this.email,
            address: this.address,
            sourceId: token,
            cardNumber: "",
            expiryMonth: 0,
            expiryYear: 0,
            saveCard: true,
            shopId: this.getCartInfo?.shopId,
            orderSubscription: {
              weeks: 2,
              isOnGoing: true,
              dayOfWeek: 2,
            },
          },
          {
            method: "POST",
            headers: {
              headers: !tokenC ? headers : {},
            },
          }
        )
        .then((response) => {
          this.CreateCart({
            shopId: this.getCartInfo?.shopId,
          });

          this.paymentLoading = false;

          this.isloading = false;
          this.$swal({
            title: "Thank you for your purchase!",
            text: "Your order is confirmed, and we're thrilled to have you as our valued customer. Thank you for choosing us!",
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Track Order!", // Change button text
            showCloseButton: true,
          }).then(() => {
            this.$router.push(`/customer/orders/${response?.data?.result?.id}`);
          });
        })
        .catch(() => {
          this.isloading = false;
          this.paymentLoading = false;

          this.$swal({
            title: "Sorry!",
            text: "Something went wrong Api!",
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Okay!", // Change button text
            showCloseButton: true,
          });
        });
      if (paymentResponse.ok) {
        // :TODO AFTER PAYMENT IS 200 OK FIRE API TO DB TO SAVE DELIVERY INSTRUCTIONS

        return paymentResponse.json();
      }

      const errorBody = await paymentResponse.text();
      throw new Error(errorBody);
    },
    async handlePay() {
      await this.handlePaymentMethodSubmission(this.card);
    },
    navigateToLastRoute() {
      // Go back to the last visited route
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
