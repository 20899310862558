<template>
  <!-- <div
      class="z-20 relative w-[320px] md:w-[630px] h-[60px] border-1 rounded-full"
    >
      <img
        class="absolute left-4 top-4"
        src="@/assets/HomePage/pin.svg"
        alt=""
      />
      <input
        placeholder="Enter Delivery Address"
        class="w-full h-full px-16 py-2 rounded-full"
        type="text"
        v-model="query"
        @input="handleSearchInput"
      />

      <div class="z-5">
        <button
          v-if="location === null"
          class="absolute right-2 top-1 h-[50px] w-[50px] rounded-full flex justify-center items-center bg-primaryGreen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
              fill="white"
            />
          </svg>
        </button>

        <div
          class="absolute right-2 top-1 h-[50px] w-[50px] rounded-full flex justify-center items-center bg-primaryGreen text-white"
          v-else
        >
          <router-link to="/customer/main">
            <button>Go</button>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="query" class="z-50">
      <div v-if="query.length > 0" class="w-full">
        <p
          v-for="place in places"
          :key="place.id"
          class="bg-white p-3 border-primaryGreen border-1 rounded-full text-black-300 mt-1 cursor-pointer"
        >
          {{ place.description }}
        </p>
      </div>
    </div> -->

  <div
    class="z-20 relative w-[320px] md:w-[630px] h-[60px] mt-4 border-1 rounded-full"
  >
    <img class="absolute left-4 top-4" src="@/assets/HomePage/pin.svg" alt="" />
    <input
      v-model="query"
      @input="handleSearchInput"
      @keydown.escape="closeSuggestions"
      type="text"
      placeholder="Search for a place"
      class="w-full h-full px-16 py-2 rounded-full"
    />
    <div class="z-5">
      <button
        v-if="location === null"
        class="absolute right-2 top-1 h-[50px] w-[50px] rounded-full flex justify-center items-center bg-primaryGreen"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
            fill="white"
          />
        </svg>
      </button>

      <div
        class="absolute right-2 top-1 h-[50px] w-[50px] rounded-full flex justify-center items-center bg-primaryGreen text-white"
        v-else
      >
        <router-link to="/customer/main">
          <button>Go</button>
        </router-link>
      </div>
    </div>
    <div
      v-if="showSuggestions"
      class="absolute z-10 mt-1 w-full bg-white shadow-lg"
    >
      <div
        v-for="(suggestion, index) in suggestions"
        :key="index"
        class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.formatted_address }}
      </div>
    </div>
  </div>
</template>
<script>
import { searchPlacesExtra } from "../../helpers/seacrhplaces";
// import { SfButton } from "@storefront-ui/vue";
export default {
  components: {
    // SfButton,
  },
  data() {
    return {
      location: null,
      places: [],
      query: "",
      showSuggestions: false,
      suggestions: [],
    };
  },
  methods: {
    async handleSearchInput() {
      if (this.query === "") {
        this.showSuggestions = false;
      } else {
        const inputValue = this.query;

        this.suggestions = await searchPlacesExtra(inputValue)
          .then((responses) => {
            console.log(responses);
            return responses;
          })
          .catch((error) => {
            // Handle errors here
            console.error("An error occurred:", error);
            return [];
          });

        if (this.suggestions.length > 0) {
          this.showSuggestions = true;
        } else {
          this.showSuggestions = false;
        }
      }
    },

    closeSuggestions() {
      this.showSuggestions = false;
    },
    selectSuggestion(suggestion) {
      console.log("Handlesec", suggestion);

      this.location = {
        placeName: suggestion.formatted_address,
        latitude: suggestion.geometry.location.lat(),
        longitude: suggestion.geometry.location.lng(),
      };
      this.query = suggestion.formatted_address;
      localStorage.setItem("location", JSON.stringify(this.location));
      this.showSuggestions = false;

      // Perform search or other actions based on the selected suggestion
    },
  },
};
</script>
