<template>
  <form>
    <input type="checkbox" id="payment-modal" class="modal-toggle" />
    <label for="payment-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl"
        for=""
      >
        <label
          for="payment-modal"
          class="btn btn-sm btn-circle right-2 top-2"
          @click="$emit('close')"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="p-6">
          <h3 class="text-3xl font-bold leading-9 text-black-300 text-center">
            Add New Card
          </h3>
        </section>
        <div class="px-4">
          <div id="card-container" class=""></div>
          <button
            v-if="isloading"
            id="card-button"
            type="button"
            class="w-full rounded-md capitalize bg-primaryGreen text-white text-lg py-2"
          >
            . . .
          </button>
          <button
            v-else
            id="card-button"
            type="button"
            class="w-full rounded-md capitalize bg-primaryGreen text-white text-lg py-2"
            @click="handleSave"
          >
            {{ $t("save") }}
          </button>
        </div>
        <!--  TOAST -->

        <Toast
          :open="status === 'success' ? true : false"
          :messageText="
            status === 'success'
              ? 'Card Added Successfully'
              : 'Something went wrong'
          "
          :varient="status === 'success' ? 'success' : 'warning'"
        />
      </label>
    </label>
  </form>
</template>

<script>
import axiosInstance from "../../../helpers/axiosInstance";
import { mapGetters } from "vuex";
import Toast from "@/components/reusable/Toast.vue";
import { mapActions } from "vuex";

// import spinner from "@/components/reusable/Spinner.vue";
export default {
  data() {
    return {
      status: null,
      card: null,
      isloading: false,
    };
  },
  components: { Toast },
  methods: {
    ...mapActions(["SetAllCards"]),
    async InitializingSquare() {
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }

      let payments;
      try {
        payments = window.Square.payments(
          "sandbox-sq0idb-CZ31SdvfjCaVnNI9Yiynkw",
          "LHVY73BCEXNZH"
        );
      } catch {
        const statusContainer = document.getElementById(
          "payment-status-container"
        );
        statusContainer.className = "missing-credentials";
        statusContainer.style.visibility = "visible";
        return;
      }

      try {
        this.card = await this.initializeCard(payments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    },
    async initializeCard(payments) {
      const cards = await payments.card();
      await cards.attach("#card-container");
      return cards;
    },
    async handlePaymentMethodSubmission(paymentMethod) {
      try {
        const token = await this.tokenize(paymentMethod);
        const paymentResults = await this.createPayment(token);
        console.debug("Payment Success", paymentResults);
      } catch (e) {
        console.error(e.message);
      }
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    },
    async createPayment(token) {
      this.isloading = true;
      //
      const tokenC = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      const paymentResponse = await axiosInstance
        .post(
          "services/app/Customers/SaveCustomerCard",
          {
            //locationId: "L7M0989KNDHXH",
            Id: token,
          },
          {
            headers: {
              headers: !tokenC ? headers : {},
            },
          }
        )
        .then(async () => {
          this.isloading = false;
          this.$swal({
            title: "Card Successfully Saved",
            text: "Start hassel free shopping",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Shop Now", // Change button text
            showCloseButton: true,
          });

          await this.SetAllCards();
        })
        .catch((error) => {
          this.isloading = false;
          this.$swal({
            title: error.response.data.error.message,
            text: error.response.data.error.details,
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Try Again!", // Change button text
            showCloseButton: true,
          });
        });

      if (paymentResponse.ok) {
        // :TODO AFTER PAYMENT IS 200 OK FIRE API TO DB TO SAVE DELIVERY INSTRUCTIONS

        return paymentResponse.json();
      }

      const errorBody = await paymentResponse.text();
      throw new Error(errorBody);
    },
    async handleSave() {
      this.isloading = true;
      await this.handlePaymentMethodSubmission(this.card);
    },
  },

  async mounted() {
    await this.InitializingSquare();
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>
