<template lang="">
  <div
    class="w-96 h-56 bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform"
  >
    <div
      class="w-full h-full bg-black-300 rounded-md flex items-center justify-center"
    >
      <div class="w-full px-8">
        <div class="flex justify-between">
          <div>
            <p class="font-light">Name</p>
          </div>

          <h3 class="text-white text-lg font-bold">{{ brand }}</h3>
          <button
            v-if="!isDefault"
            @click="setCardDefault"
            class="text-green-50 hover:bg-gray-50 hover:text-black-300 cursor-pointer text-sm leading-1 px-2 border-1 border-white"
          >
            <span v-if="isloading"> ... </span>
            <span v-else> Make Default </span>
          </button>

          <button
            v-if="isDefault"
            class="bg-gray-50 text-black-300 cursor-pointer text-sm leading-1 px-2 border-1 border-white"
          >
            <span v-if="isloading"> ... </span>
            <span v-else> Default </span>
          </button>
        </div>
        <div class="pt-1">
          <p class="font-light">Card Number</p>
          <p class="font-medium tracking-more-wider">
            XXXX XXXX XXXX {{ cardnumber }}
          </p>
        </div>
        <div class="pt-6 pr-6">
          <div class="flex justify-between">
            <div>
              <p class="font-light text-xs">Valid</p>
              <p class="font-medium tracking-wider text-sm">XX / XX</p>
            </div>
            <div>
              <p class="font-light text-xs">Expiry</p>
              <p class="font-medium tracking-wider text-sm">XX/XX</p>
            </div>
            <div>
              <p class="font-light text-xs">CVV</p>
              <p class="font-bold tracking-more-wider text-sm">***</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  props: ["data", "isDefault", "cardId", "cardnumber", "brand"],

  data() {
    return {
      isloading: false,
    };
  },

  methods: {
    ...mapActions(["SetAllCards"]),

    setCardDefault() {
      this.isloading = true;
      let formData = new FormData();
      formData.append("cardId", this.cardId);

      axios
        .post("/services/app/Customers/SetCustomersDefaultCard", {
          cardId: this.cardId,
        })
        .then(async (res) => {
          this.isloading = false;

          console.log(res);

          this.$swal({
            title: "Default Card Changed",
            text: "Payment method modified successfully",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Close", // Change button text
            showCloseButton: true,
          });

          await this.SetAllCards();
        })
        .catch((err) => {
          this.isloading = false;

          this.$swal({
            title: err.response.data.error.message,
            text: err.response.data.error.details,
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Try Again!", // Change button text
            showCloseButton: true,
          });

          console.log(err);
        });
    },
  },
};
</script>
<style lang=""></style>
