import axios from "axios";
const servicestoreByCatogory = {
  state: {
    shopsByCategory: [],
    category: {},
  },
  getters: {
    getAllShopsByCategory(state) {
      return state.shopsByCategory;
    },
    getCategory(state) {
      return state.category;
    },
  },
  actions: {
    async SetAllShopsByCategory({ commit }, payload) {
      await axios
        .get(`services/app/Shop/GetAllShopsByCategory?Id=${payload?.id}`)
        .then((res) => {
          commit("setAllShopsByCategory", res?.data?.result?.items);
        });
    },
    async SetCategory({ commit }, payload) {
      await axios
        .get(`services/app/MenuCategory/Get?Id=${payload?.id}'`)
        .then((res) => {
          commit("setCategory", res?.data?.result);
        });
    },
  },
  mutations: {
    setAllShopsByCategory(state, response) {
      state.shopsByCategory = response;
    },
    setCategory(state, response) {
      state.category = response;
    },
  },
};
export default servicestoreByCatogory;
