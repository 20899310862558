import axios from "axios";
const landing = {
  state: {
    allProducts: [],
    allcategories: [],
    storeInfo: {},
    storeInfoByCategory: {},
    shopTypes: [],
    restaurantTypes: [],
  },
  getters: {
    getAllProducts(state) {
      return state.allProducts;
    },
    getStoreInfo(state) {
      return state.storeInfo;
    },
    getStoreInfoByCategory(state) {
      return state.storeInfo;
    },
    getCategoryInfo(state) {
      return state.allcategories;
    },
    getShopTypes(state) {
      return state.shopTypes;
    },
    getResturantTypes(state) {
      return state.restaurantTypes;
    },
  },
  actions: {
    async SetAllProducts({ commit }, e) {
      await axios
        .get(
          `services/app/FoodMenu/GetMenusByCategory?Keyword=${
            e?.searchField ? e?.searchField : ""
          }`
        )
        .then((response) => {
          commit("setAllProduct", response?.data?.result.categories);
        });
    },
    async SetStoreInfo({ commit }, payload) {
      await axios
        .get(
          `services/app/FoodMenu/GetMenusByCategoryTagByShopId?Keyword=${payload.searchField}&IsActive=true&ShopId=${payload.id}&SkipCount=1&MaxResultCount=10`
        )
        .then((response) => {
          commit("setStoreInfo", response?.data?.result);
        });
    },
    async SetStoreInfoByCategory({ commit }, payload) {
      await axios
        .get(
          `services/app/FoodMenu/GetMenusByCategoryTagByShopId?Keyword=${
            payload.searchField
          }&IsActive=true&ShopId=${payload.id}&TagId=${
            payload.tagId ? payload.tagId : ""
          }&SkipCount=1&MaxResultCount=10`
        )
        .then((response) => {
          commit("setStoreInfoByCategory", response?.data?.result);
        });
    },
    async SetCategoryInfo({ commit }) {
      await axios
        .get(`services/app/MenuCategory/GetAll?skipCount=1&maxResultCount=10`)
        .then(async (response) => {
          let data = await response?.data?.result?.items;

          commit("setCategoryInfo", data ? data : []);
        });
    },
    async SetShopTypes({ commit }) {
      await axios
        .get("services/app/ShopType/GetAll?skipCount=1&maxResultCount=10")
        .then((response) => {
          commit("setShopTypes", response?.data?.result?.items);
        });
    },
    async SetAllResturantTypes({ commit }) {
      await axios.get("services/app/Shop/GetShopTags").then((response) => {
        commit("setAllResturantTypes", response?.data?.result?.items);
      });
    },
  },
  mutations: {
    setAllProduct(state, response) {
      state.allProducts = response;
    },
    setStoreInfo(state, response) {
      state.storeInfo = response;
    },
    setCategoryInfo(state, response) {
      state.allcategories = response;
    },
    setStoreInfoByCategory(state, response) {
      state.storeInfoByCategory = response;
    },
    setShopTypes(state, response) {
      state.shopTypes = response;
    },
    setAllResturantTypes(state, response) {
      state.restaurantTypes = response;
    },
  },
};
export default landing;
