<template>
  <LandingLayout>
    <div
      class="mt-12 md:mt-4 h-full w-full flex flex-col justify-center items-center"
    >
      <img class="w-[60%] md:w-[35%]" src="@/assets/errorpage.jpg" alt="" />
      <!-- ACTION BUTTONS -->
      <div class="mt-4 flex flex-col items-center gap-2">
        <h1 class="text-2xl">Looks Like You Lost !</h1>
        <router-link to="/customer/main">
          <button class="btn bg-black-300 text-base text-white font-bold">
            Go Home
          </button>
        </router-link>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/layouts/landingLayout.vue";

export default {
  data() {
    return {};
  },
  components: { LandingLayout },

  methods: {},
  computed: {},
};
</script>

<style>
.formulate-input-element > input {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-color: rgba(247, 247, 247, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  padding-right: 10px;
}
.formulate-input-element > select {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-color: rgba(247, 247, 247, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  padding-right: 10px;
}
.formulate-input-label .formulate-input-label--before {
  font-weight: 700;
}
</style>
