<template>
  <SfSidebar
    subtitle=""
    button
    :visible="isShow"
    :overlay="isShow"
    :persistent="isShow"
    position="left"
    class="removepadding"
    style="padding: 0"
    @close="$emit('close')"
  >
    <translate-modal
      :closeModal="closeModal"
      :isModalOpen="isModalOpen"
    ></translate-modal>
    <div class="flex items-center md:space-x-2 space-x-0 mx-auto z-20">
      <img
        src="@/assets/logo-simple.jpeg"
        class="md:w-14 w-24 rounded-xl shadow-md"
      />
    </div>

    <div class="pt-4">
      <router-link to="/customer/main">
        <div
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/home.svg" />
          <p class="font-semibold text-lg">{{ $t("home") }}</p>
        </div>
      </router-link>
      <router-link to="/customer/pickup">
        <div
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/pickup.svg" />
          <p class="font-semibold text-lg">{{ $t("pickup") }}</p>
        </div>
      </router-link>
      <!-- <div
        class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
      >
        <img src="@/assets/svg/offers.svg" />
        <p class="font-semibold text-lg">{{ $t("offers") }}</p>
      </div> -->
      <router-link to="/customer/orders" v-if="token && token !== ''">
        <div
          v-if="token && token !== ''"
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/order.svg" />
          <p class="font-semibold text-lg">{{ $t("orders") }}</p>
        </div>
      </router-link>
      <router-link to="/customer/subscriptions" v-if="token && token !== ''">
        <div
          v-if="token && token !== ''"
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img
            class="w-[26px] h-[26px]"
            src="@/assets/subscription-model.png"
          />
          <p class="font-semibold text-lg">{{ $t("getSubscriptions") }}</p>
        </div>
      </router-link>

      <router-link to="/customer/account" v-if="token && token !== ''">
        <div
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/SVGaccount.svg" />
          <div>
            <p class="font-semibold text-lg">{{ $t("account") }}</p>
            <p class="text-gray-400 font-light text-sm capitalize">
              {{ getProfileInfo?.name }}
            </p>
          </div>
        </div>
      </router-link>

      <router-link to="/customer/savedstores" v-if="token && token !== ''">
        <div
          v-if="token && token !== ''"
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/SVGsaved.svg" />
          <p class="font-semibold text-lg">{{ $t("savedStores") }}</p>
        </div>
      </router-link>
      <router-link to="/customer/payments" v-if="token && token !== ''">
        <div
          v-if="token && token !== ''"
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/SVGpayment.svg" />
          <p class="font-semibold text-lg">{{ $t("payment") }}</p>
        </div>
      </router-link>
      <div
        v-if="token && token !== ''"
        class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
      >
        <img src="@/assets/svg/SVGgift.svg" />
        <p class="font-semibold text-lg">{{ $t("giftCard") }}</p>
      </div>

      <router-link to="/customer/get-help">
        <div
          class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        >
          <img src="@/assets/svg/help.svg" />
          <p class="font-semibold text-lg">{{ $t("help") }}</p>
        </div>
      </router-link>
      <div
        v-if="!token"
        class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        @click="$emit('openSignIn')"
      >
        <img src="@/assets/svg/help.svg" />
        <p class="font-semibold text-lg">{{ $t("signUp_or_signIn") }}</p>
      </div>
      <div
        v-if="token && token !== ''"
        class="flex items-center space-x-3 py-3 border-b border-gray-100 cursor-pointer"
        @click="signOut"
      >
        <img src="@/assets/svg/signOut.svg" />
        <p class="font-semibold text-lg">{{ $t("signOut") }}</p>
      </div>
      <div class="mt-2">
        <button
          @click="isModalOpen = true"
          class="bg-primaryGreen text-white p-2 rounded-full"
        >
          Translate
        </button>
      </div>

      <!-- <select
        class="border p-1 rounded-full mt-2"
        @click="changeLanguage"
        v-model="selectedLanguage"
        name=""
        id=""
      >
        <option
          v-for="(val, index) in languages"
          :key="index"
          class="cursor-pointer"
          :value="val"
        >
          {{ val }}
        </option>
      </select> -->
      <!-- <dropdown
        placement="left"
        class="lg:block hidden"
        customClass="w-32"
        :open="isDrop"
        @toggle="isDrop = !isDrop"
      >
        <template v-slot:button>
          <div
            class="flex items-center space-x-3 border border-black px-2 py-1 mt-6 rounded-full"
          >
            <img src="@/assets/svg/globe.svg" alt="globe" class="w-6" />
            <p class="text-sm font-medium pl-3">
              {{ currentlanguage }}
            </p>
            <img src="@/assets/svg/bottom.svg" alt="bottom" class="w-6" />
          </div>
        </template>

        <template v-slot:content>
          <div class="text-sm space-y-2 py-3 px-3">
            <p
              v-for="(val, index) in languages"
              @click="currentlanguage = val"
              :key="index"
              class="cursor-pointer"
            >
              {{ val }}
            </p>
          </div>
        </template>
      </dropdown> -->
    </div>

    <!-- <div class="pt-6 space-y-4">
        <p class="text-lg">
            About Us
        </p>
        <p class="text-lg">
            Careers
        </p>
        <p class="text-lg">
            NoshBox Newsroom
        </p>
        <p class="text-lg">
            About Engineering
        </p>
        <p class="text-lg">
            Engineering Blog
        </p>
        <p class="text-lg">
            Accessibility
        </p>
        <p class="text-lg">
            Be a Seller
        </p>
        <p class="text-lg">
            Be a Partner Restaurant
        </p>
        <p class="text-lg">
            Rider for Deliveries
        </p>
        <p class="text-lg">
            Get NoshBox Credits
        </p>
    </div> -->
  </SfSidebar>
</template>

<script>
import { SfSidebar } from "@storefront-ui/vue";
// import dropdown from "@/components/reusable/dropdown.vue";
import { mapActions, mapGetters } from "vuex";

import TranslateModal from "@/components/reusable/TranslateModal.vue";

export default {
  props: { isShow: Boolean },
  components: { SfSidebar, TranslateModal },
  data() {
    return {
      token: "",
      isModalOpen: false,
      currentlanguage: sessionStorage?.getItem("language")
        ? sessionStorage.getItem("language")
        : "English (US)",
      languages: ["English (US)", "French (FR)", "Afghani (AFG)"],
      selectedLanguage: "English (US)",
      isDrop: false,
    };
  },
  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
  created() {
    this.token = localStorage?.getItem("token")
      ? localStorage?.getItem("token")
      : "";
    if (this.token) {
      this.getCustomerProfile();
    }
  },
  mounted() {
    if (sessionStorage.getItem("language")) {
      this.$i18n.locale = sessionStorage.getItem("language");
    }
  },
  watch: {
    currentlanguage(newVal) {
      if (newVal === "English (US)") {
        this.$i18n.locale = "en";
        sessionStorage.setItem("language", "en");
      } else if (newVal === "French (FR)") {
        this.$i18n.locale = "fr";
        sessionStorage.setItem("language", "fr");
      } else if (newVal === "Afghani (AFG)") {
        sessionStorage.setItem("language", "afg");
        this.$i18n.locale = "afg";
      }
    },
  },
  methods: {
    ...mapActions(["getCustomerProfile"]),
    closeModal() {
      this.isModalOpen = false;
    },
    changeLanguage() {
      this.currentlanguage = this.selectedLanguage;
    },
    signOut() {
      localStorage?.removeItem("token");
      localStorage?.removeItem("loggedIn");
      localStorage?.removeItem("address");
      localStorage.removeItem("customerId");
      // localStorage.removeItem("address");
      this.$router.push("/");
    },
  },
};
</script>

<style>
.removepadding {
  padding: 0 !important;
}
</style>
