<template>
  <div
    class="py-3 w-full flex items-center p-2 border border-gray-100 rounded-lg shadow-sm space-x-4"
  >
    <img
      @click="$emit('handleClick')"
      v-if="store?.logoUrl"
      :src="store?.logoUrl"
      alt="logo"
      class="rounded-full w-16 h-16 border border-gray-100 cursor-pointer"
    />
    <img
      @click="$emit('handleClick')"
      v-else
      src="@/assets/logo-simple.jpeg"
      alt="logo"
      class="rounded-full w-16 h-16 border border-gray-100 cursor-pointer"
    />
    <div @click="$emit('handleClick')" class="w-full">
      <p class="text-base mb-1 font-bold cursor-pointer">
        {{ store?.businessName }}
      </p>
      <p class="text-sm text-gray-400 font-medium cursor-pointer">28 minutes</p>
      <p class="text-sm text-gray-400 font-medium cursor-pointer">
        CA {{ store?.deliveryFee }} delivery free
      </p>
    </div>
    <button @click="addStoreTOwishList">
      <WishlistBtn :isLiked="isSaved" />
    </button>
  </div>
</template>

<script>
import WishlistBtn from "./WishlistBtn.vue";

import axiosInstance from "../../helpers/axiosInstance";
import { mapActions } from "vuex";
export default {
  props: ["store" , "isSaved"],
  components: { WishlistBtn },

  methods: {
    ...mapActions(["SetStoreInfo"]),

    addStoreTOwishList() {
      if (!this.isSaved) {
        axiosInstance
          .post("/services/app/ShopLikeList/Create", {
            shopId: this.store.tenantId,
          })
          .then(async () => {
            await this.SetStoreInfo({
              id: this.store.tenantId,
              searchField: "",
            });
           
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axiosInstance
          .delete(
            `/services/app/ShopLikeList/RemoveShopLike?Id=${ this.store.tenantId}`
          )
          .then(async () => {
            await this.SetStoreInfo({
              id:  this.store.tenantId,
              searchField: "",
            });
           
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

  },





};
</script>

<style></style>
