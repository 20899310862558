<template>
  <div class="flex justify-center">
    <div class="md:w-3/4 w-full pt-6 space-y-6 md:px-0 px-5 h-full mx-auto">
      <div class="p-5 border border-gray-100 rounded-lg">
        <div class="flex justify-between items-center">
          <p class="font-semibold text-lg">1. Account details</p>
          <p>{{ getProfileInfo?.email }}</p>
        </div>
        <SignInSignUp v-if="isloggedIN === 'false'" />
      </div>
      <div
        v-if="isloggedIN === 'true'"
        class="p-5 border border-gray-100 rounded-lg"
      >
        <p class="font-semibold text-lg">2. Shipping details</p>
        <div v-if="token && token !== ''">
          <div class="flex items-start md:space-x-5 space-x-0">
            <img src="@/assets/svg/clock.svg" class="md:block hidden" />
            <div class="w-full">
              <div class="flex justify-between items-center">
                <p>Delivery Time</p>
                <p v-if="deliveryMode !== 'subscribtion'">
                  {{ deliveryTime?.date }} {{ deliveryTime?.day }}
                  {{ deliveryTime?.month }}
                  {{ deliveryTime?.time?.time }}
                </p>
                <p v-if="deliveryMode === 'subscribtion'">SUBCRIPTION</p>
              </div>

              <!-- DELIVERY TYPE SELECTOR -->
              <div
                class="flex md:flex-row flex-col md:space-x-3 space-x-0 md:space-y-0 space-y-2 pt-3"
              >
                <div
                  v-if="!shopSettings.standard === 'true'"
                  @click="changeActiveButton('now')"
                  :class="
                    deliveryMode === 'now'
                      ? 'border-black-300'
                      : 'border-grey-100'
                  "
                  class="pr-6 border-2 flex items-start space-x-4 rounded-lg p-2 cursor-pointer"
                >
                  <div>
                    <p class="text-md font-semibold">Standard</p>
                    <p class="text-sm">23-30 min</p>
                  </div>
                  <img
                    v-if="deliveryMode === 'now'"
                    src="@/assets/svg/check.svg"
                    class="mt-2"
                  />
                  <img
                    v-else
                    src="@/assets/svg/uncheck.svg"
                    class="mt-2 w-4 h-4"
                  />
                </div>

                <label
                  v-if="shopSettings.schedule === 'true'"
                  for="schedule-modal"
                  @click="changeActiveButton('later')"
                  :class="
                    deliveryMode === 'later'
                      ? 'border-black-300'
                      : 'border-grey-100'
                  "
                  class="pr-6 border-2 flex items-start space-x-4 rounded-lg p-2 cursor-pointer"
                >
                  <div>
                    <p class="text-md font-semibold">Schedule for later</p>

                    <p v-if="deliveryTime?.date !== 'TODAY ASAP'">
                      {{ deliveryTime?.date }} {{ deliveryTime?.day }}
                      {{ deliveryTime?.month }}
                      {{ deliveryTime?.time?.time }}
                    </p>

                    <p class="text-sm">Choose a time</p>
                  </div>
                  <img
                    v-if="deliveryMode === 'later'"
                    src="@/assets/svg/check.svg"
                    class="mt-2"
                  />
                  <img
                    v-else
                    src="@/assets/svg/uncheck.svg"
                    class="mt-2 w-4 h-4"
                  />
                </label>

                <button
                  v-if="shopSettings.subscription === 'true'"
                  for="subscribtion-modal"
                  :class="
                    deliveryMode === 'subscribtion'
                      ? 'border-black-300'
                      : 'border-grey-100'
                  "
                  class="border-2 flex items-start space-x-4 rounded-lg p-2 pr-6 cursor-pointer"
                  @click="isModalOpen = true"
                >
                  <div>
                    <p class="text-md font-semibold">Subscription</p>

                    <p v-if="deliveryMode === 'subscribtion'" class="text-left">
                      {{ subcribtionDetail?.duration }}
                    </p>
                    <p v-if="deliveryMode === 'subscribtion'" class="text-left">
                      {{ subcribtionDetail?.time }}
                    </p>
                    <div
                      v-if="deliveryMode === 'subscribtion'"
                      class="flex items-center"
                    >
                      <p class="text-left">{{ subcribtionDetail?.days }}</p>
                    </div>
                    <p v-if="deliveryMode !== 'subscribtion'" class="text-sm">
                      Choose a time
                    </p>
                  </div>
                  <img
                    v-if="deliveryMode === 'subscribtion'"
                    src="@/assets/svg/check.svg"
                    class="mt-2"
                  />
                  <img
                    v-else
                    src="@/assets/svg/uncheck.svg"
                    class="mt-2 w-4 h-4"
                  />
                </button>

                <Subs
                  :closeModal="closeModal"
                  :isModalOpen="isModalOpen"
                  :subcribtionType="subcribtionType"
                  :storeId="storeId"
                  @close="handlePopUpClose"
                  @getSubDetail="getSubDetail"
                  :changeActiveButton="changeActiveButton"
                ></Subs>
              </div>
            </div>
          </div>
          <!-- ADDRESS -->

          <label for="address-modal">
            <div
              class="flex justify-between items-center py-5 border-b border-gray-100 px-5 hover:bg-gray-300 cursor-pointer rounded-md mt-2"
            >
              <div class="flex items-center space-x-5">
                <img src="@/assets/svg/homegray.svg" alt="icon" />
                <div>
                  <p>
                    {{
                      getProfileInfo?.address
                        ? getProfileInfo?.address
                        : "ADD ADDRESS"
                    }}
                  </p>
                  <!-- <p class="text-gray-500 text-sm">
                  {{ getProfileInfo?.state }} , {{ getProfileInfo?.postCode }}
                </p> -->
                </div>
              </div>
              <img src="@/assets/svg/right.svg" alt="icon" class="w-6" />
            </div>
          </label>
          <!-- LEAVE IT AT MY DOOR -->
          <label for="leaveat-modal">
            <div
              class="flex justify-between items-center py-5 border-b border-gray-100 px-5 hover:bg-gray-300 cursor-pointer rounded-md mt-2"
            >
              <div class="flex items-center space-x-5">
                <img src="@/assets/svg/package.svg" alt="icon" />
                <div>
                  <p>{{ deliveryType }}</p>
                  <p class="text-gray-500 text-sm">
                    {{
                      deliveryInstructions === ""
                        ? "Add more details"
                        : deliveryInstructions
                    }}
                  </p>
                </div>
              </div>
              <img src="@/assets/svg/right.svg" alt="icon" class="w-6" />
            </div>
          </label>
          <!-- MOBILE NUMBER -->
          <label for="mobilenumber-modal">
            <div
              class="flex justify-between items-center py-5 px-5 hover:bg-gray-300 cursor-pointer rounded-md mt-2"
            >
              <div class="flex items-center space-x-5">
                <img src="@/assets/svg/phone.svg" alt="icon" />
                <p>
                  {{
                    getProfileInfo?.phoneNumber
                      ? getProfileInfo?.phoneNumber
                      : "Add Mobile Number"
                  }}
                </p>
              </div>
              <img src="@/assets/svg/right.svg" alt="icon" class="w-6" />
            </div>
          </label>
        </div>
        <!-- SEND AS GIFT -->
        <!-- <div
          class="flex justify-between items-center py-5 border-b border-gray-100 px-5 hover:bg-gray-300 cursor-pointer rounded-md mt-2"
        >
          <div class="flex items-center space-x-5">
            <img src="@/assets/svg/gift.svg" alt="icon" />
            <div>
              <p>Send as a gift</p>
              <p class="text-gray-500 text-sm">Learn more</p>
            </div>
          </div>
          <img src="@/assets/svg/right.svg" alt="icon" class="w-6" />
        </div> -->
      </div>

      <SfButton
        :disabled="deliveryMode === ''"
        v-if="isloggedIN === 'true'"
        @click="$router.push(`/customer/payment/${storeId}`)"
        class="w-full flex justify-between px-4 rounded-full bg-red-600 py-3 font-semibold mt-4"
      >
        <p class="capitalize">Place Order</p>
        <p>CA${{ amountDue }}</p>
      </SfButton>
    </div>

    <addressModal @close="handlePopUpClose" />
    <MobileNUmberModal
      :updateNumber="updateNumber"
      :mobileNumber="mobileNumber"
      @close="handlePopUpClose"
    />
    <Leaveat
      :handleDeliveryType="changeDeliveryType"
      :deliveryType="deliveryType"
      :UpdatedeliveryInstructions="UpdatedeliveryInstructions"
      :address="getProfileInfo?.address"
      :updateDeliveryInfo="updateDeliveryInfo"
      :UpdateAppartmentNumber="UpdateAppartmentNumber"
      @close="handlePopUpClose"
    />
    <schedule
      :deliveryTime="handleDeliveryTime"
      :storeId="storeId"
      @close="handlePopUpClose"
      :changeActiveButton="changeActiveButton"
    />
    <subscribe
      :subcribtionType="subcribtionType"
      :storeId="storeId"
      @close="handlePopUpClose"
      :changeActiveButton="changeActiveButton"
    />
    <paymentModal @close="handlePopUpClose" />
  </div>
</template>

<script>
import { SfButton } from "@storefront-ui/vue";
import addressModal from "@/components/reusable/modals/addressModal.vue";
import MobileNUmberModal from "@/components/reusable/modals/mobileNumberModal.vue";
import Leaveat from "@/components/reusable/modals/leaveat.vue";
import schedule from "@/components/reusable/modals/schedule.vue";
import subscribe from "@/components/reusable/modals/subscribtion.vue";

import paymentModal from "@/components/reusable/modals/payment.vue";
import SignInSignUp from "./signInSignUp.vue";
import { mapActions, mapGetters } from "vuex";
import Subs from "@/components/reusable/modals/Subs.vue";
import axiosInstance from "../../helpers/axiosInstance";
import { ref } from "vue";

export default {
  props: ["cartInfo", "storeId"],

  components: {
    SfButton,
    addressModal,
    MobileNUmberModal,
    Leaveat,
    schedule,
    paymentModal,
    SignInSignUp,
    subscribe,
    Subs,
  },

  data() {
    return {
      isModalOpen: ref(false),
      modal: ref(null),
      phoneNumber: "",
      address: "",
      deliveryMode: "now",
      deliveryTime: { date: "TODAY", time: "23-25 min" },
      subcribtionDetail: { days: [], duration: "", time: "" },
      deliveryType: "Leave it at my door",
      deliveryInstructions: "",
      mobileNumber: "",
      appartmentNumber: "",
      token: "",
      shopSettings: { schedule: false, standard: false, subscription: false },
      isloggedIN: localStorage.getItem("loggedIn")
        ? localStorage.getItem("loggedIn")
        : "false",
    };
  },

  refreshIsLogged() {
    this.isloggedIN = localStorage.getItem("loggedIn");
  },
  created() {
    this.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";

    this.deliveryMode = this.shopSettings.standard === "false" ? "now" : "";
    localStorage.setItem("deliveryMode", this.deliveryMode);
  },

  methods: {
    subcribtionType(days, duration, time) {
      this.subcribtionDetail = { days, duration, time };
    },

    closeModal() {
      this.isModalOpen = false;
    },
    updateDeliveryInfo() {
      let payload = new FormData();
      payload.append(
        "leaveAtDoor",
        this.deliveryType === "Leave it at my door" ? true : false
      );
      payload.append("dropOffInstructions", this.deliveryInstructions);
      payload.append("apartmentNumber", this.appartmentNumber);
      payload.append("deliveryAddress", this.address);

      axiosInstance
        .patch("services/app/ShoppingCart/UpdateShoppingCart", payload, {
          headers: {
            "Content-Type": "application/json",
            "Abp.TenantId": 3,
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    ...mapActions(["getCustomerProfile", "GetCart"]),

    handlePopUpOpen() {
      this.$emit("popUpOpen");
    },
    handlePopUpClose() {
      this.$emit("popUpClose");
    },

    addDays(dateObj, numDays) {
      dateObj.setDate(dateObj.getDate() + numDays);
      return dateObj;
    },

    changeActiveButton(button) {
      if (button === "now") {
        this.deliveryTime = { date: "TODAY ASAP", time: "23-25 min" };
      }

      this.deliveryMode = button;
      localStorage.setItem("deliveryMode", this.deliveryMode);
    },
    changeDeliveryType(type) {
      this.deliveryType = type;
    },
    UpdatedeliveryInstructions(ins) {
      this.deliveryInstructions = ins;
    },
    UpdateAppartmentNumber(apartmentNumber) {
      this.appartmentNumber = apartmentNumber;
    },

    handleDeliveryTime(timing, value) {
      if (this.deliveryMode === "now") {
        this.deliveryTime = "15-20 min";
      } else if (value === "later") {
        if (this.deliveryMode === "later") {
          this.deliveryTime = { ...timing };
        }
      }
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";

        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    },
    getNumber() {
      this.mobileNumber = this.formatPhoneNumber(
        this.getProfileInfo.phoneNumber
      );
    },
    updateNumber(newNumber) {
      this.mobileNumber = newNumber;
    },

    initAddress() {
      let addressLs = localStorage.getItem("address");
      if (addressLs) {
        this.address = addressLs;
      }
    },

    getSubDetail(data) {
      console.log(data);
    },

    getShopDeliverySettings() {
      console.log("THIS FUN IS STARETED");

      axiosInstance
        .get(
          `/services/app/AppSettings/GetSettingValueForTenant?SettingName=NoshBox.OrderScheduling.Enabled&ShopId=${this.storeId}`
        )
        .then((res) => {
          console.log("SHOP DELIVERY SETTINGS", res);

          this.shopSettings = {
            ...this.shopSettings,
            schedule: res.data.result.settingValue,
          };
        })
        .catch((err) => {
          console.log(err);
        });

      axiosInstance
        .get(
          `/services/app/AppSettings/GetSettingValueForTenant?SettingName=NoshBox.OrderSubscription.Enabled&ShopId=${this.storeId}`
        )
        .then((res) => {
          console.log("SHOP DELIVERY SETTINGS", res);

          this.shopSettings = {
            ...this.shopSettings,
            subscription: res.data.result.settingValue,
          };
        })
        .catch((err) => {
          console.log(err);
        });

      axiosInstance
        .get(
          `/services/app/AppSettings/GetSettingValueForTenant?SettingName=NoshBox.OrderStandardDelivery.Enabled&ShopId=${this.storeId}`
        )
        .then((res) => {
          console.log("SHOP DELIVERY SETTINGS", res);

          this.shopSettings = {
            ...this.shopSettings,
            standard: res.data.result.settingValue,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  async mounted() {
    await this.getShopDeliverySettings();
    await this.getCustomerProfile();
    this.getNumber();
    this.initAddress();
    await this.GetCart({ id: this.storeId });
    await this.refreshIsLogged();
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),

    amountDue() {
      return this.cartInfo?.taxAmount + this.cartInfo?.totalPrice;
    },
  },
};
</script>

<style>
.modal-enter-active,
.modal-leave-active {
  transition: all 0.25s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
