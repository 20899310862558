<template>
  <div
    class="cursor-default modal-box md:relative absolute md:top-0 top-20 py-5 px-5 max-w-5xl rounded-3xl h-min-128 overflow-y-auto"
    :class="
      showClose
        ? 'overflow-y-auto bg-white  lg:w-4/12 md:7/12 w-full'
        : ' w-full'
    "
  >
    <div v-if="isTooFar">
      <div
        class="bg-r-100 text-r-700 px-4 py-3 rounded-full relative"
        role="alert"
      >
        <span class="block sm:inline text-sm"
          >The marker is too far from the provided address!
        </span>
        <strong class="block sm:inline text-sm"
          >Use Change Address Option</strong
        >
      </div>
    </div>

    <label
      v-if="showClose"
      for="leaveat-modal"
      class="btn btn-sm btn-circle"
      @click="$emit('close')"
    >
      <img src="@/assets/svg/cross.svg" class="w-4" />
    </label>

    <div class="">
      <!-- MAP CONTAINER -->
      <div class="">
        <div :class="isAdjust ? 'h-[370px]' : 'h-40'" class="w-100 rounded-md">
          <gmaps
            :isAdjust="isAdjust"
            :address="
              getProfileInfo?.address
                ? getProfileInfo?.address
                : guestAddress?.placeName
            "
            :handleTooFar="handleTooFar"
            :updateLocation="updateLocation"
          ></gmaps>
        </div>

        <div v-if="!isAdjust" class="flex items-center justify-between mt-2">
          <div>
            <h6 class="font-bold" :class="showClose ? 'text-base' : 'text-sm'">
              {{ getProfileInfo?.address }}
            </h6>
            <!-- <p class="text-sm font-medium">
              {{ getProfileInfo?.state }} , {{ getProfileInfo?.postCode }}
            </p> -->
          </div>
          <button
            class="bg-gray-100 p-2 px-3 rounded-full cursor-pointer ml-2"
            :class="
              showClose
                ? 'w-48 text-base font-medium'
                : 'w-44 text-sm font-semibold'
            "
            @click="pinAdujustToggle"
          >
            Adjust Pin
          </button>
        </div>
      </div>

      <!-- ADDRESS INSTRUCTIONS -->

      <div v-if="!isAdjust" class="mt-2 w-100">
        <div class="flex flex-col">
          <label class="text-sm font-bold pb-1" for="appartNum"
            >Apartment Number or Suite</label
          >
          <input
            id="appartNum"
            type="text"
            class="w-100 p-2 bg-gray-100 rounded-md"
            placeholder="Apartment Number or Suite"
            v-model="appartmentNumber"
          />
        </div>
        <!-- DROP OFF OPTIONS -->
        <div class="flex flex-col mt-4">
          <h3 class="text-sm font-bold pb-1">Drop-off Options</h3>

          <div
            @click="handleDeliveryType('Hand it to me')"
            class="flex items-center gap-2"
          >
            <input
              type="radio"
              class="addressRadio"
              name="dropoff"
              id="dropoff"
              :checked="leaveAtDoor === false"
            />
            <label class="text-md font-medium cursor-pointer" for="dropoff"
              >Hand it to me</label
            >
          </div>
          <div
            @click="handleDeliveryType('Leave it at my door')"
            class="flex items-center gap-2 mt-2"
          >
            <input
              type="radio"
              class="addressRadio"
              name="dropoff"
              id="Leave"
              :checked="leaveAtDoor === false"
            />
            <label class="text-md font-medium cursor-pointer" for="Leave"
              >Leave it at my door</label
            >
          </div>
        </div>
        <div class="w-full">
          <textarea
            name="dropOffInstruction"
            id="dropOffInstruction"
            class="w-full bg-grey-100 h-20 rounded-md mt-2 p-3"
            :placeholder="
              deliveryType === 'Hand it to me'
                ? 'Eg Give Hand it over Instructions'
                : 'Eg Give Door Delivery Instructions'
            "
            v-model="deliveryInstruction"
          ></textarea>
        </div>
      </div>
    </div>

    <div v-if="!isAdjust" class="w-100 flex gap-2 justify-end mt-4">
      <label
        v-if="showClose"
        class="w-2/4 bg-grey-100 p-2 px-3 rounded-full text-base font-medium cursor-pointer text-center"
        for="leaveat-modal"
        @click="toggleDropDown"
      >
        Cancel
      </label>
      <label
        v-else
        class="w-2/4 bg-grey-100 p-2 px-3 rounded-full text-base font-medium cursor-pointer text-center"
        for="leaveat-modal"
        @click="handleEdit"
      >
        Go Back
      </label>
      <label
        class="w-2/4 bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer text-center"
        for="leaveat-modal"
        @click="saveDeliveryData"
      >
        Save
      </label>
    </div>

    <div v-if="isAdjust" class="w-100 flex gap-2 justify-end mt-4">
      <button
        class="w-2/4 bg-gray-100 p-2 px-3 rounded-full text-base font-medium cursor-pointer text-center"
        for="leaveat-modal"
        @click="cancelMarker"
      >
        Cancel
      </button>
      <button
        class="w-2/4 bg-red text-white p-2 px-4 rounded-full text-base font-medium text-center"
        for="leaveat-modal"
        @click="savePin"
        :disabled="isTooFar"
        :class="
          isTooFar
            ? 'disabled cursor-not-allowed disabled:bg-gray-800'
            : 'cursor-pointer'
        "
      >
        Save Pin
      </button>
    </div>
    <Toast
      :open="islocationUpdated === 'success'"
      :messageText="'Pin Updated Successfully'"
      :varient="'success'"
    />
    <Toast
      :open="islocationUpdated === 'error'"
      :messageText="'Not able to update pin Please try again'"
      :varient="'warning'"
    />
  </div>
</template>

<script>
import gmaps from "@/components/reusable/goolemap.vue";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import Toast from "@/components/reusable/Toast.vue";

export default {
  components: { gmaps, Toast },
  props: [
    "deliveryType",
    "handleDeliveryType",
    "UpdatedeliveryInstructions",
    "address",
    "showClose",
    "updateDeliveryInfo",
    "UpdateAppartmentNumber",
    "toggle",
    "getCartInfo",
    "handleEdit",
  ],
  data() {
    return {
      isOpen: false,
      isAdjust: false,
      deliveryInstruction: "",
      appartmentNumber: "",
      isTooFar: false,
      location: { lat: "", long: "" },
      islocationUpdated: "",
      guestAddress: JSON.parse(sessionStorage.getItem("location")),
      leaveAtDoor: true,
    };
  },

  methods: {
    toggleDropDown() {
      if (!this.showClose) {
        this.handleEdit();
        this.toggle();
      } else {
        this.$emit("close");
      }
    },

    saveDeliveryData() {
      this.$emit("close");
      this.UpdatedeliveryInstructions(this.deliveryInstruction);
      this.UpdateAppartmentNumber(this.appartmentNumber);
      this.updateDeliveryInfo();
      if (!this.showClose) {
        this.handleEdit();
        this.toggle();
      }
    },
    pinAdujustToggle() {
      this.isAdjust = !this.isAdjust;
    },

    savePin() {
      this.isAdjust = !this.isAdjust;

      this.updateLatLong();
    },

    async cancelMarker() {
      this.isTooFar = false;
      this.pinAdujustToggle();
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GCP_ACCESS,
        version: "weekly",
        libraries: ["places"],
      });

      const google = await loader.load();

      const geocoder = new google.maps.Geocoder();

      if (this.getProfileInfo?.address) {
        geocoder.geocode(
          { address: this.getProfileInfo?.address },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                const map = new google.maps.Map(
                  document.getElementById("map"),
                  {
                    center: {
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng(),
                    },
                    zoom: 12,
                  }
                );

                const marker = new google.maps.Marker({
                  position: map.getCenter(),
                  map: map,
                  title: "Marker Title",

                  icon: {
                    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Map_marker_font_awesome.svg/1200px-Map_marker_font_awesome.svg.png",
                    scaledSize: new google.maps.Size(32, 32), // adjust the size as needed
                    anchor: new google.maps.Point(16, 16), // adjust the anchor point if necessary
                  },
                  draggable: this.isAdjust, // Enable dragging the marker
                });

                // Update marker position when the map is dragged
                google.maps.event.addListener(map, "drag", () => {
                  if (this.isAdjust) {
                    marker.setPosition(map.getCenter());
                    marker.getPosition();
                  }
                });

                // Update map center when the marker is dragged
                google.maps.event.addListener(marker, "drag", () => {
                  map.setCenter(marker.getPosition());
                });

                // Do something with the latitude and longitude
              }
            }
          }
        );
      }
    },
    updateLocation(lat, long) {
      this.location = {
        lat,
        long,
      };
    },

    updateLatLong() {
      let payload = new FormData();

      payload.append("latitude", this.location.lat);
      payload.append("longitude", this.location.long);

      axios
        .patch("services/app/ShoppingCart/UpdateShoppingCart", payload, {
          headers: {
            "Content-Type": "application/json",
            "Abp.TenantId": 3,
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then((res) => {
          this.islocationUpdated = "success";

          setTimeout(() => {
            this.islocationUpdated = "";
          }, 3000);
          console.log(res);
        })
        .catch((err) => {
          this.islocationUpdated = "error";
          setTimeout(() => {
            this.islocationUpdated = "";
          }, 3000);
          console.log(err);
        });
    },

    handleTooFar(isFar) {
      this.isTooFar = isFar;
    },

    prefillData() {
      let cartData = this.getCartInfo?.shoppingCartItems[0]?.shoppingCart;

      if (cartData) {
        this.deliveryInstruction = cartData.dropOffInstructions;
        this.appartmentNumber = cartData.apartmentNumber;
        this.leaveAtDoor = cartData.leaveAtDoor;
      }
    },
  },

  async mounted() {
    this.prefillData();
  },

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #00eb7d;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 10;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #456759;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
