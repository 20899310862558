<template>
  <div>
    <transition name="fade">
      <div v-if="openModal">
        <section class="absolute left-0 top-0 h-[100vh] w-full">
          <div
            @click="handleOpenModal"
            class="absolute opacity-70 inset-0 z-40 bg-black"
            :class="openModal ? 'overflow-hidden' : ''"
          ></div>

          <div
            class="overflow-y-auto absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-full max-w-lg rounded-xl shadow-lg bg-white z-50"
            :class="'h-[81vh]'"
          >
            <button @click="handleOpenModal" class="absolute top-4 right-4">
              <img src="@/assets/svg/close.svg" alt="" />
            </button>
            <div class="px-4 py-6">
              <h3 class="font-bold text-3xl text-[#191919] leading-10">
                Pricing & Fees
              </h3>
            </div>

            <div class="px-5">
              <ul class="text-[14px] px-4">
                <li>
                  Menu Prices: This merchant sets prices. Those prices may be
                  higher than prices in-store or elsewhere for this location.
                  In-store promotions may not apply.pricing for delivery and
                  pickup may vary.
                </li>
                <li>
                  Service Fee: This fee goes to DoorDash. The service fee may
                  vary but is 15% of your subtotal for most restaurant orders
                  (and 5% for most eligible DashPass restaurant orders). A flat,
                  minimum service fee may apply on small orders.
                </li>
                <li>
                  Delivery Fee: The delivery fee is a flat fee that goes to
                  DoorDash. The delivery fee is $0 on eligible DashPass
                  restaurant orders.
                </li>
                <li>
                  Other DoorDash Fees: Expanded range fees, express fees, small
                  order fees, and fees in response to local regulations may
                  apply. Each are separate fees, all of which go to DoorDash.
                </li>
                <li>
                  Other DoorDash Fees: Expanded range fees, express fees, small
                  order fees, and fees in response to local regulations may
                  apply. Each are separate fees, all of which go to DoorDash.
                </li>
                <li>
                  Checkout: You can see all of the fees that apply to your order
                  at checkout prior to completing the transaction.
                </li>
              </ul>
            </div>

            <div class="flex justify-end mt-2 px-4">
              <button
                @click="handleOpenModal"
                class="bg-red text-base font-bold text-white p-2 rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return { isTooFar: false, location: {} };
  },
  components: {},

  methods: {
    handleTooFar(isFar) {
      this.isTooFar = isFar;
    },
    updateLocation(lat, long) {
      this.location = {
        lat,
        long,
      };
    },
  },

  props: ["openModal", "handleOpenModal"],
};
</script>
<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
