import axios from "axios";
const register = {
  state: {},
  getters: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    async registerAdmin({ commit }, payload) {
      await axios
        .post("services/app/Tenant/Create", payload, {
          headers: {
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then(() => {});
    },
  },
  mutations: {},
};
export default register;
