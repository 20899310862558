<template>
  <SfSidebar
    subtitle=""
    button
    :visible="isShow"
    :overlay="isShow"
    :persistent="isShow"
    position="right"
    class=""
    @close="$emit('close')"
  >
    <div v-if="getAllCarts?.items.length !== 0">
      <div v-for="(cart, index) in getAllCarts?.items" :key="index">
        <template v-if="cart?.shoppingCartItems.length !== 0">
          <div class="pt-12 pb-4 border-b border-gray-100">
            <p class="text-sm font-semibold text-gray-850">
              {{ $t("yourCartFrom") }} {{ JSON.stringify() }}
            </p>
            <div class="flex items-center space-x-3">
              <p class="font-semibold text-lg">{{ cart?.shopName }}</p>
              <img src="@/assets/svg/right.svg" alt="right" class="w-4" />
            </div>
            <SfButton
              @click="$router.push(`/customer/checkout/${cart?.shopId}`)"
              class="w-full flex justify-between rounded-full bg-red px-4 capitalize py-2.5 mt-4"
            >
              <p>{{ $t("checkout") }}</p>
              <p>CA${{ cart?.totalPrice }}</p>
            </SfButton>
          </div>
          <div>
            <template v-if="cart?.shoppingCartItems">
              <CartItem
                v-for="(item, index) in cart?.shoppingCartItems"
                :key="index"
                :item="item"
                @increaseQuantity="(val) => increaseQuantity(val)"
                @decreaseQuantity="(val) => decreaseQuantity(val)"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center h-full">
      <img src="@/assets/svg/mainCart.svg" alt="cart" class="w-56" />
      <p class="text-gray-400 text-center px-10 pt-3">
        Order Cart Unavailable Uh oh, something went wrong
      </p>
    </div>
  </SfSidebar>
</template>

<script>
import { SfSidebar, SfButton } from "@storefront-ui/vue";
import CartItem from "../cart/cartItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: { isShow: Boolean },
  components: { SfSidebar, SfButton, CartItem },
  computed: {
    ...mapGetters(["getCartInfo", "getAllCarts"]),
  },

  methods: {
    ...mapActions(["GetCart", "UpdateCart", "SetAllCarts"]),
    async increaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity + 1,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
    },
    async decreaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity > 0 ? val?.quantity - 1 : 0,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
    },
  },
  async mounted() {
    await this.SetAllCarts();
  },
};
</script>

<style></style>
