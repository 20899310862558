<template>
  <div>
    <input type="checkbox" id="schedule-modal" class="modal-toggle" />
    <label for="schedule-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-5/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl"
        for=""
      >
        <label
          for="schedule-modal"
          class="btn btn-sm btn-circle right-2 top-2"
          @click="closeHandler"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="p-4">
          <h3 class="text-xl font-bold leading-9 text-black-300">
            Select a Delivery Date
          </h3>
        </section>

        <div class="w-100 px-4 flex flex-col justify-center items-center">
          <div
            class="w-full bg-grey-100 h-16 rounded-full flex items-center justify-between"
          >
            <div
              v-for="button in buttons"
              :key="button.id"
              @click="toggleButton(button)"
              :class="
                button.id === selectedComponentId &&
                'bg-primaryGreen text-white'
              "
              class="w-24 h-full rounded-full px-4 py-1 font-bold flex items-center flex-col justify-center cursor-pointer hover:bg-grey-300"
            >
              <p class="w-100 h-100">
                {{ days[button.date.getDay()].slice(0, 3) }}
              </p>
              <p class="w-100 h-100">{{ button.date.getDate() }}</p>
            </div>
          </div>
          <div
            class="w-full mt-3 px-4 flex flex-wrap gap-3 items-center justify-center"
          >
            <label
              for="schedule-modal"
              v-for="(time, i) in selectedComponent.timings"
              :key="i"
              :class="
                deliveryDate.time.id === time.id
                  ? 'bg-primaryGreen text-white'
                  : 'bg-grey-200 text-black-300'
              "
              @click="setDeliveryDateTime(time)"
              class="w-5/12 py-1 rounded-full cursor-pointer text-center"
            >
              <p class="text-base font-bold">{{ time.time }}</p>
            </label>
          </div>
        </div>
      </label>
    </label>
  </div>
</template>

<script>
import axiosInstance from "../../../helpers/axiosInstance";
import { mapActions } from "vuex";

export default {
  props: ["deliveryTime", "changeActiveButton", "storeId"],

  data() {
    return {
      isOpen: false,
      selectedComponentId: 1,
      selectedComponentlabel: "TODAY",
      selectedDate: this.addDays(new Date(), 1),
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      selectedComponent: {
        id: 1,
        label: "TODAY",
        selected: true,
        date: this.addDays(new Date(), 1),
        timings: [
          { time: "1-1:30", id: 1 },
          { time: "2-2:30", id: 2 },
          { time: "3-3:30", id: 3 },
          { time: "4-4:30", id: 4 },
        ],
      },

      deliveryDate: {
        date: "",
        time: { time: "1-1.30", id: 1 },
        month: new Date().getMonth(),
      },
      buttons: [
        {
          id: 1,
          label: "TODAY",
          selected: true,
          date: this.addDays(new Date(), 1),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 2,
          label: "TMRW",
          selected: false,

          date: this.addDays(new Date(), 2),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 3,
          label: "WED",
          selected: false,

          date: this.addDays(new Date(), 3),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 4,
          label: "THUR",
          selected: false,

          date: this.addDays(new Date(), 4),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 5,
          label: "FRI",
          selected: false,

          date: this.addDays(new Date(), 5),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 6,
          label: "SAT",
          selected: false,

          date: this.addDays(new Date(), 6),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
        {
          id: 7,
          label: "SUN",
          selected: false,

          date: this.addDays(new Date(), 7),
          timings: [
            { time: "1-1:30", id: 1 },
            { time: "2-2:30", id: 2 },
            { time: "3-3:30", id: 3 },
            { time: "4-4:30", id: 4 },
          ],
        },
      ],
    };
  },

  // METHODS -----------------------------------
  methods: {
    ...mapActions(["UpdateShoppingCartItem"]),
    closeHandler() {
      this.$emit("close");
      this.changeActiveButton("now");
    },
    addDays(dateObj, numDays) {
      dateObj.setDate(dateObj.getDate() + numDays);
      return dateObj;
    },

    toggleButton(button) {
      this.selectedComponentlabel = button.label;
      this.selectedComponent = button;
      this.selectedComponentId = button.id;
      this.selectedDate = button.date;

      this.UpdateShoppingCartItem({
        deliveryDateStart: button.date,
        shopId: this.storeId,
      });
    },

    setDeliveryDateTime(time) {
      this.deliveryDate = {
        date: this.selectedDate.getDate(),
        time: { id: time.id, time: time.time },
        month: this.month[this.selectedDate.getMonth()],
        day: this.days[this.selectedDate.getDay()].slice(0, 3),
      };

      this.updateDeliveryTime(this.deliveryDate);

      this.deliveryTime(this.deliveryDate, "later");
      this.$emit("close");
    },

    updateDeliveryTime() {
      let data = {
        deliveryDateStart: this.addDays(new Date(), 1),
        deliveryDateEnd: this.addDays(new Date(), 2),
      };

      axiosInstance
        .patch("/services/app/ShoppingCart/UpdateShoppingCart", data)
        .then(() => {
          this.$swal({
            title: "Delivery Time Updated",
            text: "",
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Explore NoshBox!", // Change button text
            showCloseButton: true,
          });
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.error.message,
            text: error.response.data.error.details,
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Try Again!", // Change button text
            showCloseButton: true,
          });
        });
    },
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}
</style>
