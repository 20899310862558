<template>
  <div v-if="isloggedIN === 'true'">
    <div class="p-8 border-b border-gray-100">
      <div class="flex items-center space-x-4">
        <img
          v-if="getCartInfo?.shopLogoUrl"
          :src="getCartInfo?.shopLogoUrl"
          alt="store"
          class="w-12 h-12 rounded-full shadow"
        />

        <img
          v-else
          src="@/assets/storeicon.jpg"
          alt="store"
          class="w-12 h-12 rounded-full shadow border-1 border-black-300"
        />
        <div class="space-y-1">
          <p class="text-xs font-medium text-gray-500">
            {{ $t("yourCartFrom") }}
          </p>
          <div>
            <p class="font-semibold">{{ getCartInfo?.shopName }}</p>
          </div>
        </div>
      </div>

      <!-- <div class="bg-yellow-200 p-4 rounded-lg flex items-start justify-between space-x-4 mt-5">
        <img src="@/assets/svg/alert.svg" />
        <p class="text-sm">
          Sorry! This address is out of the delivery area for this store.
        </p>
        <label for="leaveat-modal"
          class="cursor-pointer bg-white text-sm font-semibold capitalize text-black rounded-full px-4 py-1.5 shadow-lg">
          {{ $t("change") }}
        </label>
      </div> -->
      <!-- <div class="bg-yellow-200 p-4 rounded-lg flex items-start justify-between space-x-4 mt-5">
        <img src="@/assets/svg/alert.svg" />
        <p class="text-sm">
          Sorry! This order is unavailable for Delivery at Your Requested time.
        </p>
        <label for="leaveat-modal"
          class="cursor-pointer bg-white text-sm font-semibold capitalize text-black rounded-full px-4 py-1.5 shadow-lg">
          {{ $t("change") }}
        </label>
      </div> -->
      <!-- <div class="bg-yellow-200 p-4 rounded-lg flex items-start justify-between space-x-4 mt-5">
        <img src="@/assets/svg/alert.svg" />
        <p class="text-sm">{{ $t("validPaymentMethod") }}</p>
        <SfButton class="bg-white text-sm font-semibold capitalize text-black rounded-full px-4 py-1.5 shadow-lg">
          {{ $t("add") }}
        </SfButton>
      </div> -->
      <!-- <SfButton
        :disabled="deliveryMethod === ''"
        class="w-full flex justify-between px-4 rounded-full bg-red-600 py-3 font-semibold mt-4"
        @click="$router.push(`/customer/payment/${storeId}`)"
      >
        <p class="capitalize">{{ $t("placeOrder") }}</p>
        <p>CA${{ amountDue }}</p>
      </SfButton> -->
      <div class="flex justify-between items-center mt-6">
        <div class="flex items-center space-x-5">
          <img src="@/assets/svg/cart-black.svg" alt="icon" />
          <p>{{ $t("orderSummary") }}</p>
        </div>
        <!-- <img src="@/assets/svg/right.svg" alt="icon" class="w-6" /> -->
      </div>
      <div class="pt-3">
        <div class="flex justify-between items-center">
          <p class="text-2xl font-semibold">{{ $t("items") }}</p>
          <!-- <SfButton class="px-3 flex items-center space-x-4 py-1 text-sm font-medium bg-gray-300 text-black rounded-full">
            <img src="@/assets/svg/add.svg" />
            <p>{{ $t("addMoreItems") }}</p>
          </SfButton> -->
        </div>
        <template v-if="getCartInfo">
          <CartItem
            v-for="(item, index) in getCartInfo?.shoppingCartItems"
            :key="index"
            :item="item"
            @increaseQuantity="(val) => increaseQuantity(val)"
            @decreaseQuantity="(val) => decreaseQuantity(val)"
          />
        </template>
      </div>
      <!-- <div class="flex items-center justify-between pt-3">
        <p class="pb-0 text-lg text-black font-semibold">
          {{ $t("mostOrderedItems") }}
        </p>
        <div class="flex items-center space-x-4">
          <div class="flex items-center space-x-2">
            <SfButton class="p-2 rounded-full bg-gray-100" @click="prev">
              <img src="@/assets/svg/left-C.svg" />
            </SfButton>
            <SfButton class="p-2 rounded-full bg-gray-100" @click="next">
              <img src="@/assets/svg/right-C.svg" />
            </SfButton>
          </div>
        </div>
      </div>
      <MostOrderd ref="carouselComp" /> -->
    </div>
    <label for="promotions-modal" class="cursor-pointer">
      <div
        class="flex justify-between items-center p-5 border-b border-gray-100"
      >
        <div class="flex items-center space-x-5">
          <img src="@/assets/svg/tag.svg" alt="icon" />
          <p>{{ $t("promoCode") }}</p>
        </div>
        <img src="@/assets/svg/right.svg" alt="icon" class="w-6" />
      </div>
    </label>
    <div class="p-5 border-b border-gray-100 space-y-1">
      <!-- <div class="flex items-center justify-between">
        <p>Subtotal</p>
        <p>CA$4.55</p>
      </div> -->
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <p>Delivery Fee</p>

          <ToolTip varient="Delivery" />
        </div>
        <p>CA$ {{ getCartInfo?.deliveryFee }}</p>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <p>Fees & Estimated Tax</p>

          <ToolTip varient="Tax" />
        </div>
        <p>CA$ {{ getCartInfo?.taxAmount }}</p>
      </div>
    </div>
    <div class="p-5 border-b border-gray-100">
      <div class="flex items-center justify-between">
        <p>Driver Tip</p>
        <p>{{ tip }}</p>
      </div>
      <tipSelector
        :cartInfo="this.getCartInfo"
        :addTip="addTip"
        :storeId="storeId"
      ></tipSelector>
      <p class="text-xs text-gray-500 pt-4">
        {{ $t("recomendedDasherTip") }}
      </p>
    </div>
    <div class="p-5 border-b border-gray-100">
      <div class="flex items-center justify-between">
        <p>Total</p>
        <p>CA${{ getCartInfo?.totalPrice }}</p>
      </div>
    </div>
    <div class="p-5 border-b border-gray-100">
      <div class="flex items-center justify-between font-semibold">
        <p>Amount Due</p>
        <p>CA${{ amountDue }}</p>
      </div>
    </div>
    <promotions @close="handlePopUpClose" />
  </div>
  <div
    v-else
    class="hidden lg:flex items-center justify-start lg:p-4 lg:py-10 w-full h-full flex-col gap-2"
  >
    <img width="60%" src="@/assets/loginsvg.svg" alt="" />

    <h3 class="text-center">
      Please Sign in / Sign up to continue the checkout process
    </h3>
  </div>
</template>

<script>
// import { SfButton } from "@storefront-ui/vue";
import ToolTip from "@/components/reusable/toolip.vue";
import CartItem from "@/components/cart/cartItem.vue";
// import MostOrderd from "./mostOrderd.vue";
import tipSelector from "@/components/reusable/tipSelector.vue";
import promotions from "@/components/reusable/modals/promotions.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    // SfButton,
    CartItem,
    // MostOrderd,/
    ToolTip,
    tipSelector,
    promotions,
  },
  props: ["storeId"],
  data() {
    this.publishableKey =
      "pk_test_51KxdgMB6jxtqU3Zi1Fuxx8yacJvW2aJv5A527o9j8Ew4jnHrJv46N0BMmGtTgZBtCV4nEJCdiy6dTRTwi42B6VYs00RdN1byzY";
    return {
      tip: this.getCartInfo?.driverTip ? this.getCartInfo?.driverTip : "0",
      loading: false,
      lineItems: [
        {
          price: "price_1Movd4B6jxtqU3ZiD7keYswX", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "http://localhost:8080/checkout",
      cancelURL: "http://localhost:8080/checkout",
      isloggedIN: localStorage.getItem("loggedIn"),

      deliveryMethod: localStorage.getItem("deliveryMode"),
    };
  },
  computed: {
    ...mapGetters(["getCartInfo"]),
    amountDue() {
      return this.getCartInfo?.taxAmount + this.getCartInfo?.totalPrice;
    },
    deliveryMode() {
      return localStorage.getItem("deliveryMode");
    },
  },
  methods: {
    ...mapActions(["UpdateCart", "SetAllCarts"]),
    next() {
      this.$refs.carouselComp.showNext();
    },
    prev() {
      this.$refs.carouselComp.showPrev();
    },

    addTip(tip) {
      this.tip = tip;
    },
    handlePopUpClose() {
      this.$emit("popUpClose");
    },
    async increaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity + 1,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
    },
    async decreaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity > 0 ? val?.quantity - 1 : 0,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
    },
  },
  mounted() {
    console.log("CART INFO", this.getCartInfo);
    setTimeout(() => {
      this.tip = this.getCartInfo?.driverTip
        ? this.getCartInfo?.driverTip
        : "0";
    }, 2000);
  },
};
</script>

<!-- <style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;

  background-color: rgba(0, 0, 0, 0.856);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  padding: 16px;
  text-align: left;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 18px;
  left: -35px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style> -->

<style></style>
