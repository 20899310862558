import axiosInstance from "../../helpers/axiosInstance";
const orders = {
  state: {
    orders: [],
    order: {},
  },
  getters: {
    getAllOrders(state) {
      return state.orders;
    },
    getOrder(state) {
      return state.order;
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async SetAllOrders({ commit }) {
      await axiosInstance
        .get(`services/app/Orders/GetAllByCustomer?SkipCount=1`)
        .then((res) => {
          commit("setOrders", res?.data?.result?.items);
        });
    },
    async SetOrder({ commit }, payload) {
      await axiosInstance
        .get(`services/app/Orders/Get?id=${payload?.id}`)
        .then((res) => {
          commit("setOrder", res?.data?.result);
        });
    },
  },

  mutations: {
    setOrder(state, response) {
      state.order = response;
    },
    setOrders(state, response) {
      state.orders = response;
    },
  },
};
export default orders;
