import Vue from "vue";
import Vuex from "vuex";
import Landing from "./consumer/landing";
import Checkout from "./consumer/checkout";
import Main from "./consumer/main";
import Auth from "./consumer/auth";
import Register from "./partner/register";
import Pickup from "./consumer/pickup";
import User from "./consumer/user";
import StoreByCatogory from "./consumer/storeByCategory";
import Orders from "./consumer/orders";
import homeChefStore from "./consumer/gethomecook";
import groceryStore from "./consumer/groceryStores";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    landing: Landing,
    checkout: Checkout,
    main: Main,
    auth: Auth,
    register: Register,
    pickup: Pickup,
    user: User,
    storeByCatogory: StoreByCatogory,
    orders: Orders,
    homeChefSores: homeChefStore,
    groceryStore: groceryStore,
  },
});
