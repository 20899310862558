<template>
  <div>
    <Header class="sticky top-0 z-10"></Header>
    <main class="relative bg-white flex justify-center">
      <div class="xl:w-5/5 w-full lg:-px-0">
        <slot></slot>
      </div>
    </main>
    <Footer v-if="$route.name === 'main' || $route.name === 'category'" />
  </div>
</template>

<script>
import Header from "@/components/LandingPage/header.vue";
import Footer from "@/components/LandingPage/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style></style>
