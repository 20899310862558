import axios from "axios";
const pickup = {
  state: {
    shopsNearMe: [],
    addresses: [],
  },
  getters: {
    getAllShopsNearMe(state) {
      return state.shopsNearMe;
    },
  },
  actions: {
    async SetAllShopsNearme({ commit }, e) {
      await axios
        .post("services/app/Shop/PickupShopsNearMe", {
          longitutde: e.lng ? e.lng : 0,
          latitutde: e.lat ? e.lat : 0,
          radius: 0,
        })
        .then((res) => {
          commit("setAllShopsNearMe", res?.data?.result);
        });
    },
  },
  mutations: {
    setAllShopsNearMe(state, response) {
      state.shopsNearMe = response;
    },
  },
};
export default pickup;
