<template>
  <div
    class="flex items-center justify-between border-b border-gray-100 py-3 "
  >
    <img
      v-if="item?.foodMenu?.imageUrl"
      :src="item?.foodMenu?.imageUrl"
      alt="image"
      class="w-16 h-16 rounded-md shadow-md"
    />
    <img
      v-else
      src="@/assets/bg2.png"
      alt="image"
      class="w-16 h-16 rounded-md shadow-md"
    />
    <div class="pr-2 pl-3">
      <p class="text-normal">{{ item?.foodMenu?.name }}</p>
      <p class="text-xs text-gray-600">Approx 1.15 kg each</p>
      <p>CA${{ item?.foodMenu?.unitPrice }}</p>
    </div>
    <Counter
      :amount="item?.quantity"
      @add="$emit('increaseQuantity', item)"
      @delete="$emit('decreaseQuantity', item)"
    />
  </div>
</template>

<script>
import Counter from "./counter.vue";
export default {
  props: ["item"],
  components: {
    Counter,
  },
};
</script>

<style></style>
