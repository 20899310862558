<template lang="">
  <landing-layout>
    <Subs
      :mode="mode"
      :closeModal="closeModal"
      :isModalOpen="isModalOpen"
      :isActive="subscriptionData.isPaused"
      :refetch="getSubscriptions"
    ></Subs>

    <section class="wrapper mt-4">
      <!-- Header Text -->
      <div class="flex items-center justify-between mb-2">
        <h3 class="text-2xl p-2 font-semibold lg:text-3xl lg:p-0">
          Subscription Detail
        </h3>

        <div class="flex gap-2 items-center">
          <button
            v-if="!subscriptionData.isPaused"
            @click="modifySub('pause')"
            class="bg-primaryGreen p-2 text-white rounded-md"
          >
            Pause
          </button>

          <button
            v-if="subscriptionData.isPaused"
            @click="modifySub('resume')"
            class="bg-primaryGreen p-2 text-white rounded-md"
          >
            Resume
          </button>
          <button
            @click="modifySub('cancel')"
            class="bg-red p-2 text-white rounded-md"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        class="w-100 bg-[#f1f1f1] shadow-sm rounded-md p-3 flex justify-between"
      >
        <div
          class="py-5 px-6 rounded-lg flex flex-col items-start justify-between gap-2"
        >
          <h3 class="text-lg">
            Subscription Status :
            <span class="text-primaryGreen font-bold">
              {{
                subscriptionData?.isActive === true
                  ? subscriptionData.isPaused === true
                    ? "Paused"
                    : "Active"
                  : "Cancelled"
              }}
            </span>
          </h3>
          <!-- <h3 class="text-lg">Delivery Completed : 2/8</h3> -->
          <h3 class="text-lg">
            Subscription Duration : {{ subscriptionData.weeks }} weeks
          </h3>
        </div>

        <div>
          <div
            class="w-full py-5 px-6 rounded-lg flex items-start justify-between gap-3"
          >
            <div>
              <h4 class="text-[16px] font-bold">
                Start Date : {{ subscriptionData.startDate }}
              </h4>
              <h3 class="text-lg">
                Delivery Day : {{ subscriptionData.dayOfWeek }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <!-- NEXT DELIVERY -->
      <div v-if="subscriptionData.childOrders.length > 0" class="mt-4">
        <h3 class="text-lg font-semibold">Orders</h3>

        <div class="w-full mt-4">
          <div class="w-full mx-auto">
            <div
              v-for="(orders, index) in subscriptionData.childOrders"
              :key="index"
            >
              <div class="border rounded-md mb-2">
                <div
                  @click="toggleSection(index)"
                  class="flex justify-between items-center p-4 cursor-pointer"
                >
                  <div class="flex gap-4 items-center">
                    <span class="font-semibold">
                      Delivered on : {{ orders.delivredDate }}
                    </span>
                    <span class="font-semibold">
                      Order Id : {{ orders.orderId }}
                    </span>
                  </div>
                  <i
                    class="fas"
                    :class="{
                      'fa-chevron-down': orders.expanded,
                      'fa-chevron-up': !orders.expanded,
                    }"
                  ></i>
                </div>
                <div v-show="orders.expanded" class="p-4">
                  <div class="flex items-center justify-between mt-4">
                    <h4 class="text-xl font-bold">Order Info</h4>
                  </div>

                  <div>
                    <!-- Order List Card -->

                    <div
                      class="w-full bg-white p-4 mt-2 rounded-lg flex items-center justify-between gap-3"
                    >
                      <div class="w-10 h-10 rounded-sm bg-black-300"></div>

                      <!-- Product Name -->
                      <div class="w-60 flex-[10]">
                        <h4>Chicken Tandoor</h4>
                      </div>

                      <!-- Product Qty -->
                      <h4 class="flex-1">Qty :2</h4>

                      <!-- product Price -->

                      <h4 class="flex-1">CA $20</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="flex items-center flex-col justify-center mt-10">
          <h2 class="text-xl font-bold">No Orders Found</h2>
          <img class="w-[30%] h-[40%]" src="@/assets/no_orders.svg" alt="" />
        </div>
      </div>
    </section>
  </landing-layout>
</template>
<script>
import landingLayout from "@/layouts/landingLayout.vue";
import { ref } from "vue";
import Subs from "@/components/reusable/modals/EditSubscription.vue";
import axiosInstance from "../../../helpers/axiosInstance";

export default {
  components: { landingLayout, Subs },
  data() {
    return {
      isModalOpen: ref(false),
      isLoading: false,
      mode: "",
      sections: [
        {
          title: "Section 1",
          content: "Content for Section 1",
          expanded: false,
        },
        {
          title: "Section 2",
          content: "Content for Section 2",
          expanded: false,
        },
      ],

      subcriotibOrders: [
        {
          orderId: "11da-6d-dfa12",
          delivredDate: "Not Yet Delivered",
          expanded: false,
        },
        {
          orderId: "46da-7d-dfasd",
          delivredDate: "31 / 08 / 2023",
          expanded: false,
        },
      ],

      subscriptionData: {},
    };
  },
  methods: {
    modifySub(mode) {
      this.isModalOpen = true;
      this.mode = mode;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    toggleSection(index) {
      this.subcriotibOrders[index].expanded =
        !this.subcriotibOrders[index].expanded;
    },

    getSubscriptions() {
      axiosInstance
        .get(
          `/services/app/OrdersSubscription/Get?Id=${this.$route.params.subid}`
        )
        .then((res) => {
          console.log(res);
          this.subscriptionData = res?.data?.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  async mounted() {
    this.getSubscriptions();
  },
};
</script>
<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
