import axios from "axios";

async function sendVerificationCode(countryCode, phone) {
  let payload = new FormData();
  payload.append("phoneNumber", `${phone}`);
  payload.append("countryCode", `+${countryCode}`);
  let result = axios
    .post(
      "services/app/Customers/GeneratePhoneConfirmationCode",

      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "Abp.TenantId": 3,
          "X-XSRF-TOKEN":
            "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
        },
      }
    )
    .then((res) => {
      console.log(res);
      return "success";
    })
    .catch((err) => {
      console.log(err);

      return "error";
    });

  return result;
}

export default sendVerificationCode;
