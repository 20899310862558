<template>
  <div class="border-b border-gray-100 pb-3 mb-3">
    <div class="flex items-center space-x-2">
      <p class="text-lg font-semibold">{{ store?.shopDetails?.shopName }}</p>

      <button @click="addStoreTOwishList">
        <WishlistBtn :isLiked="false" />
      </button>
    </div>
    <div class="flex items-center space-x-1">
      <img src="@/assets/svg/ratings.svg" alt="ratings" class="w-3" />
      <p class="text-gray-500 text-sm">
        {{ store?.shopDetails?.tenant.rating }} (1900+ ratings) . $$ .
        {{ store?.shopDetails.tenant.deliveryTime }} min
      </p>
    </div>
    <p class="text-sm text-gray-500">
      <span class="font-medium text-primary"> Open </span>
      . Closes at 9:25 pm
    </p>
    <VueSlickCarousel class="pt-4" :slidesToShow="3">
      <div class="px-1" v-for="(product, i) in store.productsInShop" :key="i">
        <img src="@/assets/dish.png" class="h-28 rounded-lg" />
        <p class="text-sm pt-1">{{ product.name }}</p>
        <p class="text-xs text-gray-500">CA${{ product.unitPrice }}/p></p>
      </div>
    </VueSlickCarousel>
    <div class="flex items-start space-x-2 text-center pt-3">
      <div class="w-full">
        <router-link :to="`/customer/store/${store?.shopId}`">
          <SfButton
            class="border border-gray-100 bg-transparent text-black rounded-full text-sm py-2 w-full capitalize"
          >
            Delivery
          </SfButton>
        </router-link>

        <p class="text-xs text-gray-500">
          CA$19.89 delivery free over Canada
          {{ store?.shopDetails?.tenant?.deliveryFee }}$
        </p>
      </div>
      <div class="w-full">
        <router-link :to="`/customer/store/${store?.shopId}`">
          <SfButton
            class="border border-gray-100 bg-transparent text-black rounded-full text-sm py-2 w-full capitalize"
          >
            Pickup
          </SfButton>
        </router-link>

        <p class="text-xs text-gray-500">CA$19.89</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { SfButton } from "@storefront-ui/vue";
import axios from "axios";
import WishlistBtn from "../reusable/WishlistBtn.vue";
export default {
  methods: {
    addStoreTOwishList() {
      if (!this.isLiked) {
        axios
          .post("/services/app/ShopLikeList/Create", {
            shopId: this.store?.shopId,
          })
          .then(async () => {
            await this.SetStoreInfo({
              id: this.store?.shopId,
              searchField: "",
            });
            this.isLiked = "success";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .delete(
            `/services/app/ShopLikeList/RemoveShopLike?Id=${this.store?.shopId}`
          )
          .then(async () => {
            await this.SetStoreInfo({
              id: this.store?.shopId,
              searchField: "",
            });
            this.isLiked = "deleted";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },

  components: {
    VueSlickCarousel,
    SfButton,
    WishlistBtn,
  },
  props: ["store"],

  mounted() {
    console.log("THIS IS FOR MAP STORE", this.store);
  },
  data() {
    return {
      isLiked: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
.carousel {
  padding: 0;
  background-color: white;

  @include for-desktop {
    margin: 0;
  }

  &__item {
    margin: 1.9375rem 0 2.4375rem 0;
  }
}
</style>
