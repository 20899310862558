<template>
  <div class="flex bg-white justify-center border-b border-gray-100">
    <Auth
      :onToggle="onToggle"
      :isOpen="isOpen"
      :isModalVisible="isModalVisible"
      :types="types"
      :mode="mode"
      :changeActiveButton="changeActiveButton"
    />
    <div class="flex items-center space-x-4 lg:px-6 py-3 px-2 wrapper">
      <div class="flex items-center lg:w-full md:space-x-2 space-x-0">
        <button class="bg-white" @click="isSidebar = true">
          <img src="@/assets/svg/bar.svg" class="w-10" />
        </button>
        <router-link to="/customer/main">
          <div class="flex items-center md:space-x-2 space-x-0">
            <img
              src="@/assets/logo-simple.jpeg"
              class="md:w-14 w-24 rounded-xl shadow-md"
            />
          </div>
        </router-link>
        <router-link to="/customer/main">
          <p
            class="text-sm font-bold pl-3 lg:block hidden"
            :class="$route.name === 'main' ? 'underline' : ''"
          >
            {{ $t("delivery") }}
          </p>
        </router-link>
        <router-link to="/customer/pickup">
          <p
            class="text-sm font-bold lg:block hidden"
            :class="$route.name === 'pickup' ? 'underline' : ''"
          >
            {{ $t("pickup") }}
          </p>
        </router-link>
        <dropdown
          :open="open"
          @toggle="open = !open"
          placement="right"
          class="lg:block hidden"
          customClass="w-96"
        >
          <template v-slot:button>
            <p
              v-if="!isGuest"
              class="text-sm font-medium border-l-2 border-gray-100 pl-3"
            >
              {{
                getProfileInfo?.address
                  ? getProfileInfo?.address
                  : "Add Your Address"
              }}
            </p>
            <p
              v-if="isGuest"
              class="text-sm font-medium border-l-2 border-gray-100 pl-3"
            >
              {{ guestAddress ? guestAddress : "Add Address" }}
            </p>
          </template>

          <!-- Opened dropdown content -->
          <template v-slot:content>
            <section v-if="!isEdit">
              <div class="text-base font-semibold space-y-2 py-3 px-3 relative">
                <div
                  v-if="isLoading === true"
                  class="z-5 top-0 left-0 absolute w-full h-full bg-[rgba(192,192,192,0.3)] pointer-events-none"
                >
                  <div class="flex items-center justify-center">
                    <div class="lds-facebook">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <p>{{ $t("enterYourAddress") }}</p>
                <div>
                  <input
                    type="text"
                    v-model="query"
                    placeholder="Search for a place"
                    @input="handleSearchInput"
                    class="w-full p-2 bg-[#f1f1f1] rounded-sm"
                  />
                </div>
                <div v-if="query">
                  <div v-if="query.length > 0" class="w-full">
                    <p
                      @click="
                        changeAddress(
                          place.description,
                          place.city,
                          place.postalCode
                        )
                      "
                      v-for="place in places"
                      :key="place.id"
                      class="bg-grey-100 p-3 rounded-md text-black-300 mt-2 cursor-pointer"
                    >
                      {{ place.description }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-if="getProfileInfo?.address"
                class="border-2 border-grey-100"
              ></div>

              <div v-if="getProfileInfo?.address" class="py-2">
                <div class="flex items-center justify-between py-3">
                  <div class="flex items-center justify-center gap-3">
                    <input
                      type="radio"
                      id="address2"
                      class="addressRadio"
                      checked
                    />

                    <label for="address2" class="cursor-pointer">
                      <h5
                        class="text-base font-medium leading-5 text-red-700 block"
                      >
                        {{ getProfileInfo?.address }}
                      </h5>
                      <!-- <h6
                        class="text-xs font-medium leading-5 block text-red-700"
                      >
                        {{ getProfileInfo?.state }} ,
                        {{ getProfileInfo?.postCode }}
                      </h6> -->
                    </label>
                  </div>
                  <div @click="handleEdit">
                    <label
                      for="leaveat-modal"
                      class="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 cursor-pointer"
                    >
                      <img src="@/assets/Checkout/pen.svg" class="w-4" />
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="isGuest" class="py-2">
                <div class="flex items-center justify-between py-3">
                  <div class="flex items-center justify-center gap-3">
                    <input
                      type="radio"
                      id="address2"
                      class="addressRadio"
                      checked
                    />

                    <label for="address2" class="cursor-pointer">
                      <h5
                        class="text-base font-medium leading-5 text-red-700 block"
                      >
                        {{ guestAddress }}
                      </h5>
                      <!-- <h6
                        class="text-xs font-medium leading-5 block text-red-700"
                      >
                        {{ getProfileInfo?.state }} ,
                        {{ getProfileInfo?.postCode }}
                      </h6> -->
                    </label>
                  </div>
                  <div @click="handleEdit">
                    <label
                      for="leaveat-modal"
                      class="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 cursor-pointer"
                    >
                      <img src="@/assets/Checkout/pen.svg" class="w-4" />
                    </label>
                  </div>
                </div>
              </div>
            </section>
            <section v-else>
              <DropOff
                :UpdatedeliveryInstructions="UpdatedeliveryInstructions"
                :updateDeliveryInfo="updateDeliveryInfo"
                :UpdateAppartmentNumber="UpdateAppartmentNumber"
                :getCartInfo="getCartInfo"
                :toggle="toggle"
                :showClose="false"
                :handleDeliveryType="changeDeliveryType"
                :handleEdit="handleEdit"
              />
            </section>
          </template>
        </dropdown>
      </div>
      <Search class="w-full lg:hidden block" />
      <div
        class="flex items-center justify-end md:space-x-6 space-x-0 md:w-full w-32"
      >
        <Search
          v-if="$route.name === 'main'"
          class="lg:flex hidden"
          :placeholder="$t('placeholderSearch')"
        />
        <SfButton
          v-if="$route.name === 'main'"
          class="bg-red py-1.5 px-3 rounded-full space-x-2 flex"
          @click="isCart = true"
        >
          <img src="@/assets/svg/cart.svg" class="w-6 brightness-200" />
          <p class="text-white font-normal">
            {{ getAllCarts?.items.length }}
          </p>
        </SfButton>
        <SfButton
          v-if="$route.name !== 'main'"
          class="bg-red py-1.5 px-3 rounded-full space-x-2 xl:hidden flex"
          @click="isCart = true"
        >
          <img src="@/assets/svg/cart.svg" class="w-6 brightness-200" />
          <p class="text-white font-normal">
            {{ getCartInfo?.shoppingCartItems.length }}
          </p>
        </SfButton>

        <div
          class="flex items-center justify-end space-x-2"
          :class="isLoggedIn ? 'block' : 'hidden'"
        >
          <button
            @click="activeSignin"
            class="btn bg-red text-white rounded-full py-0 font-semibold text-normal hidden sm:block"
          >
            Login
          </button>
        </div>
      </div>
    </div>
    <Sidebar
      :isShow="isSidebar"
      @close="isSidebar = false"
      @openSignIn="activeSignin"
    />
    <Cart :isShow="isCart" @close="isCart = false" />
  </div>
</template>

<script>
import Sidebar from "./siderbar.vue";
import dropdown from "../reusable/dropdown.vue";
import Search from "../reusable/search.vue";
import Cart from "./cart.vue";
import Auth from "@/components/auth/modals/auth.vue";
import { SfButton } from "@storefront-ui/vue";
import { mapActions, mapGetters } from "vuex";
import searchPlaces from "../../helpers/seacrhplaces";
import axios from "axios";
import DropOff from "@/components/reusable/DropOff.vue";

export default {
  components: {
    Sidebar,
    Cart,
    SfButton,
    dropdown,
    Search,
    Auth,
    DropOff,
  },
  data() {
    return {
      types: [
        { mode: "Sign In", id: "signin" },
        { mode: "Sign Up", id: "signup" },
      ],
      mode: "signin",
      isSidebar: false,
      isCart: false,
      address: "",
      value: "",
      token: "",
      query: "",
      places: [],
      isLoading: false,
      isEdit: false,
      isGuest: false,
      guestAddress: "",
      open: false,
      isLoggedIn: localStorage?.getItem("token") ? false : true,
      deliveryType: "Leave it at my door",
      deliveryInstructions: "",
      isOpen: false,
      appartmentNumber: "",
    };
  },
  computed: {
    ...mapGetters([
      "getCartInfo",
      "getAllAddresses",
      "getProfileInfo",
      "getAllCarts",
    ]),
    isModalVisible() {
      return this.isOpen;
    },
  },
  created() {
    this.token = localStorage?.getItem("token")
      ? localStorage?.getItem("token")
      : "";
  },

  methods: {
    ...mapActions([
      "getAddress",
      "getCustomerProfile",
      "GetCart",
      "SetAllCarts",
    ]),

    async handleSearchInput() {
      const inputValue = this.query;

      this.places = await searchPlaces(inputValue);
    },

    activeSignup() {
      this.mode = "signup";
      this.changeActiveButton({ id: "signup" });

      this.onToggle();
    },
    activeSignin() {
      this.mode = "signin";

      this.changeActiveButton({ id: "signin" });
      this.isSidebar = false;
      this.onToggle();
    },

    changeActiveButton(type) {
      this.mode = type.id;
    },

    onToggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },

    updateDeliveryInfo() {
      let payload = new FormData();
      payload.append(
        "leaveAtDoor",
        this.deliveryType === "Leave it at my door" ? true : false
      );
      payload.append("dropOffInstructions", this.deliveryInstructions);
      payload.append("apartmentNumber", this.appartmentNumber);
      payload.append("deliveryAddress", this.address);
      payload.append("city", this.city);
      payload.append("zipcode", this.zipcode);

      axios
        .patch("services/app/ShoppingCart/UpdateShoppingCart", payload, {
          headers: {
            "Content-Type": "application/json",
            "Abp.TenantId": 3,
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeDeliveryType(type) {
      this.deliveryType = type;
    },
    UpdatedeliveryInstructions(ins) {
      this.deliveryInstructions = ins;
    },
    UpdateAppartmentNumber(apartmentNumber) {
      this.appartmentNumber = apartmentNumber;
    },

    toggle() {
      this.open = !this.open;
    },

    handleEdit() {
      this.isEdit = !this.isEdit;
    },

    getAddress() {
      this.address = this.getProfileInfo.address;
    },

    changeAddress(address, city, postalCode) {
      if (this.isGuest) {
        let place = { placeName: address };
        sessionStorage.setItem("location", JSON.stringify(place));
        this.guestAddress = address;
      } else {
        try {
          this.isLoading = true;

          let payload = new FormData();
          payload.append("address", address);
          payload.append("city", city);
          payload.append("postCode", postalCode);

          axios
            .patch("/services/app/Customers/UpdateProfile", payload, {
              headers: {
                Authorization: `Bearer ${this.token}`,
                "Content-Type": "application/json",
                "Abp.TenantId": 3,
                "X-XSRF-TOKEN":
                  "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
              },
            })
            .then((res) => {
              this.isLoading = false;
              this.getCustomerProfile();
              this.value = res.data.result.address;
              // this.address = res.data.result.address;
              // this.getAddress(res.data.result.address);
            })
            .catch((err) => {
              this.isLoading = false;

              console.log(err);

              return err;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },

    async getGuestAddress() {
      // CHECK USER EXISTS

      let user = localStorage.getItem("token");

      if (user) {
        await this.getCustomerProfile();
        await this.GetCart();
        this.getAddress();
      } else {
        this.isGuest = true;
        let localAddress = JSON.parse(localStorage.getItem("location"));

        if (localAddress) {
          this.guestAddress = localAddress.placeName;
        }
      }
    },
  },
  async mounted() {
    this.getGuestAddress();
    this.SetAllCarts();
  },

  watch: {
    value(newVal) {
      if (newVal !== "") {
        this.getAddress(newVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #bb1c1c;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
</style>
