<template>
  <div>
    <infoModal :openModal="openModal" :handleOpenModal="handleOpenModal" />
    <pricing
      :openModal="openPriceModal"
      :handleOpenModal="handlePriceOpenModal"
    />

    <landing-layout>
      <div>
        <div class="flex justify-between">
          <div class="wrapper mt-4">
            <div class="pb-16">
              <!-- COVER AND LOGO -->
              <div class="relative">
                <img
                  class="rounded-2xl"
                  src="@/assets/storePage/cover.png"
                  alt="cover"
                />

                <img
                  class="rounded-full border-4 border-white absolute -bottom-10 left-5"
                  src="@/assets/storePage/logo.png"
                  alt="cover"
                />
              </div>
            </div>

            <!-- SHOP DETAILS -->

            <div>
              <h3 class="text-black-300 text-4xl font-extrabold leading-10">
                Green Apple Gourmet
              </h3>

              <div class="flex items-center mt-4 gap-4">
                <p class="text-[#00838A] font-medium text-bsm">V Pass</p>
                <p class="text-grey-400 font-medium text-bsm">•</p>

                <p class="text-grey-400 font-medium text-bsm">Wraps, Panini</p>
                <p class="text-grey-400 font-medium text-bsm">•</p>

                <p class="text-grey-400 font-medium text-bsm">4.5 126 rating</p>
                <p class="text-grey-400 font-medium text-bsm">1.7 mi</p>
                <p class="text-grey-400 font-medium text-bsm">•</p>

                <p class="text-grey-400 font-medium text-bsm">$$</p>
              </div>
              <div class="flex items-center mt-4 gap-4">
                <div class="flex items-center gap-1">
                  <img src="@/assets/storePage/time.svg" alt="time" />
                  <p class="text-[#00872F] font-medium text-bsm">Open now</p>
                </div>
                <p class="text-grey-400 font-medium text-bsm">•</p>

                <p class="text-grey-400 font-medium text-bsm">All day</p>
              </div>
              <div class="flex items-center mt-4 gap-4 justify-between">
                <div class="flex items-center gap-1">
                  <p class="text-grey-600 font-medium text-bsm">
                    Pricing & Fees
                  </p>

                  <button @click="handlePriceOpenModal">
                    <img
                      src="@/assets/storePage/info.svg"
                      alt="time"
                      class="hover:cursor-pointer"
                    />
                  </button>
                </div>

                <button
                  @click="handleOpenModal"
                  class="bg-grey-200 text-sm flex items-center gap-2 p-1 px-2 rounded-full font-semibold hover:bg-grey-300"
                >
                  <img
                    class="text-black-300"
                    src="@/assets/storePage/infoblack.svg"
                    alt="time"
                  />

                  More info
                </button>
              </div>
            </div>

            <!-- SHOP INFORMATOINS -->

            <div class="mt-4 flex items-center justify-between">
              <!-- DELIVERY INFO -->
              <div
                class="border-1 border-grey-100 w-80 flex items-center gap-2 p-1 rounded-lg"
              >
                <div
                  class="flex flex-col rounded-lg text-[#00838A] w-full justify-center items-center p-1"
                  :class="
                    currentType.id === 'delivery'
                      ? 'bg-[#D7F5F5]'
                      : 'bg-[#ffffff]'
                  "
                >
                  <h5 class="font-semibold text-base">$0.00</h5>
                  <h6 class="font-medium text-sm">
                    {{ mode === "delivery" ? "delivery fee" : "In fee" }}
                  </h6>
                </div>
                <p class="text-grey-100">|</p>
                <div
                  class="flex flex-col rounded-lg w-full justify-center items-center p-1"
                >
                  <h5 class="font-semibold text-base text-black-300">
                    {{ currentType.time }}
                  </h5>
                  <h6 class="font-medium text-sm">
                    {{
                      mode === "delivery" ? "delivery time" : "ready for pickup"
                    }}
                  </h6>
                </div>
              </div>

              <!-- ACTION BTNS -->
              <div class="flex items-center gap-4">
                <button
                  class="bg-grey-100 text-base flex items-center gap-2 p-2 px-3 rounded-full font-semibold hover:bg-grey-200"
                >
                  <img src="@/assets/storePage/wishlist.svg" alt="time" />

                  Save
                </button>
                <button
                  class="bg-grey-100 text-base flex items-center gap-2 p-2 px-3 rounded-full font-semibold hover:bg-grey-200"
                >
                  <img src="@/assets/storePage/group.svg" alt="time" />

                  Group Order
                </button>

                <!-- Toggle Signin / Sign Up -->
                <div
                  v-if="mode !== 'verification'"
                  class="flex justify-center items-center"
                >
                  <div class="flex border-1 rounded-full border-grey-200 my-8">
                    <div
                      v-for="(type, index) in this.types"
                      :key="index"
                      @click="changeActiveButton(type)"
                      :class="
                        mode === type.id
                          ? ' bg-black-300 text-white border-white'
                          : ''
                      "
                      class="rounded-full px-6 p-1 cursor-pointer flex flex-col justify-center items-center"
                    >
                      <div>
                        <p class="text-sm font-medium">
                          <strong>{{ type.mode }}</strong>
                        </p>
                      </div>
                      <div>
                        <p class="text-[10px] font-normal">
                          <strong>{{ type.time }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- MAPS FOR PICK UP -->
            <div v-if="currentType.id === 'pickup'" class="my-4">
              <div class="h-36 w-full flex items-center justify-between">
                <div class="h-full w-[80%]">
                  <googleMap
                    :isAdjust="false"
                    :address="
                      getProfileInfo?.address
                        ? getProfileInfo?.address
                        : guestAddress?.placeName
                    "
                    :handleTooFar="handleTooFar"
                    :updateLocation="updateLocation"
                  />
                </div>

                <div>
                  <h5 class="text-base font-semibold">
                    Pick up this order at:
                  </h5>
                  <h6 class="text-bsm underline">3762 Boston Road</h6>
                  <div class="flex items-center">
                    <img
                      class="w-4 h-4"
                      src="@/assets/storePage/Info/car.png"
                      alt="car"
                    />
                    <p class="text-bsm font-semibold ml-2">
                      {{ currentType.time }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- SLIDER -->

            <div>
              <Banner
                :isSlim="true"
                title="Get $0 delivery fee on your first order of $5+"
              />
            </div>

            <hr class="mt-4" />

            <!-- FEATURED PRODUCTS -->

            <div class="my-4">
              <div class="flex items-center justify-between">
                <h3 class="text-2xl font-semibold">Featured Products</h3>

                <div class="flex items-center gap-2">
                  <button
                    class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
                  >
                    <img src="@/assets/storePage/leftblack.svg" alt="" />
                  </button>
                  <button
                    class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
                  >
                    <img src="@/assets/storePage/rightblack.svg" alt="" />
                  </button>
                </div>
              </div>

              <div class="mt-4 flex items-center gap-4">
                <div class="flex flex-col w-[142px]">
                  <div class="relative">
                    <img
                      src="@/assets/storePage/featuredItems.png"
                      alt="Featured"
                      class="h-[142px]"
                    />
                    <button
                      class="text-bsm font-semibold bg-white text-black-300 px-2 p-1 absolute right-2 bottom-2 rounded-full"
                    >
                      Add
                    </button>
                  </div>
                  <h3 class="text-bsm my-1 font-bold">
                    Deluxe Cheeseburger Fries
                  </h3>
                  <h6>$13.00</h6>
                </div>
                <div class="flex flex-col w-[142px]">
                  <div class="relative">
                    <img
                      src="@/assets/storePage/featuredItems.png"
                      alt="Featured"
                      class="h-[142px]"
                    />
                    <button
                      class="text-bsm font-semibold bg-white text-black-300 px-2 p-1 absolute right-2 bottom-2 rounded-full"
                    >
                      Add
                    </button>
                  </div>
                  <h3 class="text-bsm my-1 font-bold">
                    Deluxe Cheeseburger Fries
                  </h3>
                  <h6>$13.00</h6>
                </div>
                <div class="flex flex-col w-[142px]">
                  <div class="relative">
                    <img
                      src="@/assets/storePage/featuredItems.png"
                      alt="Featured"
                      class="h-[142px]"
                    />
                    <button
                      class="text-bsm font-semibold bg-white text-black-300 px-2 p-1 absolute right-2 bottom-2 rounded-full"
                    >
                      Add
                    </button>
                  </div>
                  <h3 class="text-bsm my-1 font-bold">
                    Deluxe Cheeseburger Fries
                  </h3>
                  <h6>$13.00</h6>
                </div>
                <div class="flex flex-col w-[142px]">
                  <div class="relative">
                    <img
                      src="@/assets/storePage/featuredItems.png"
                      alt="Featured"
                      class="h-[142px]"
                    />
                    <button
                      class="text-bsm font-semibold bg-white text-black-300 px-2 p-1 absolute right-2 bottom-2 rounded-full"
                    >
                      Add
                    </button>
                  </div>
                  <h3 class="text-bsm my-1 font-bold">
                    Deluxe Cheeseburger Fries
                  </h3>
                  <h6>$13.00</h6>
                </div>
              </div>
            </div>

            <!-- REVIEWS -->

            <div class="my-4">
              <div class="flex items-center justify-between">
                <h3 class="text-2xl font-semibold">What people are saying</h3>

                <div class="flex items-center gap-2">
                  <button
                    class="bg-grey-100 rounded-full p-2 text-bsm font-bold flex items-center justify-center"
                  >
                    See All Reviews
                  </button>
                </div>
              </div>
              <div class="flex items-center justify-between mt-4">
                <div class="flex items-center gap-2">
                  <h3 class="text-sm font-semibold">4.3</h3>
                  <p class="text-sm font-medium">1,000+ ratings</p>
                  <p class="text-sm font-medium">V need</p>
                </div>

                <div class="flex items-center gap-2">
                  <button
                    class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
                  >
                    <img src="@/assets/storePage/leftblack.svg" alt="" />
                  </button>
                  <button
                    class="bg-grey-100 rounded-full h-8 w-8 flex items-center justify-center"
                  >
                    <img src="@/assets/storePage/rightblack.svg" alt="" />
                  </button>
                </div>
              </div>

              <div class="mt-4 flex items-center gap-4">
                <div
                  class="flex flex-col gap-3 w-[286px] h-[150px] bg-grey-100 rounded-lg p-4"
                >
                  <h3 class="text-base font-bold">You</h3>
                  <h3>You</h3>
                  <h6 class="text-bsm font-normal">Strat Your Review</h6>
                </div>

                <div
                  class="flex flex-col gap-3 w-[286px] h-[150px] bg-white border-1 border-grey-100 rounded-lg p-4"
                >
                  <div>
                    <h3 class="text-base font-bold">Lm A</h3>
                    <div class="flex items-center gap-1 mt-1">
                      <div class="flex items-center">
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                      </div>

                      <p class="text-grey-400 font-medium text-sm">1/27/23</p>
                      <p class="text-grey-400 font-medium text-sm">•</p>
                      <p class="text-grey-400 font-medium text-sm">
                        V need Order
                      </p>
                    </div>
                  </div>

                  <h6 class="font-medium text-sm">
                    Can Be Improved in Quality
                  </h6>
                </div>
                <div
                  class="flex flex-col gap-3 w-[286px] h-[150px] bg-white border-1 border-grey-100 rounded-lg p-4"
                >
                  <div>
                    <h3 class="text-base font-bold">GG A</h3>
                    <div class="flex items-center gap-1 mt-1">
                      <div class="flex items-center">
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                        <img src="@/assets/storePage/star.svg" alt="strat" />
                      </div>

                      <p class="text-grey-400 font-medium text-sm">1/27/23</p>
                      <p class="text-grey-400 font-medium text-sm">•</p>
                      <p class="text-grey-400 font-medium text-sm">
                        V need Order
                      </p>
                    </div>
                  </div>

                  <h6 class="font-medium text-sm">
                    The Mango Madness was very fresh and a nice blend of flavor
                    in sustainable packaging ...
                  </h6>
                </div>
              </div>
            </div>

            <hr class="mt-4" />

            <FullMenu></FullMenu>
          </div>

          <!-- CART -->
          <div
            class="h-[100vh] border-l-2 border-grey-100 w-[330px] hidden lg:block"
          ></div>
        </div>
      </div>
    </landing-layout>
  </div>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import FullMenu from "@/components/reusable/storePage/Menu.vue";
import Banner from "@/components/reusable/banner.vue";
import infoModal from "@/components/reusable/storePage/modals/InfoModal.vue";
import pricing from "@/components/reusable/storePage/modals/pricing.vue";
import googleMap from "@/components/reusable/goolemap.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    landingLayout,
    FullMenu,
    Banner,
    infoModal,
    pricing,
    googleMap,
  },
  data() {
    return {
      mode: "delivery",
      currentType: {
        id: "delivery",
        type: "delivery",
        mode: "Delivery",
        time: "33 min",
      },
      types: [
        { id: "delivery", type: "delivery", mode: "Delivery", time: "33 min" },
        { id: "pickup", type: "pickup", mode: "Pick up", time: "12 min" },
      ],
      openModal: false,
      openPriceModal: false,
      isTooFar: false,
      location: {},
    };
  },

  methods: {
    handleTooFar(isFar) {
      this.isTooFar = isFar;
    },
    updateLocation(lat, long) {
      this.location = {
        lat,
        long,
      };
    },

    changeActiveButton(type) {
      this.mode = type.id;

      this.currentType = type;
    },

    handleOpenModal() {
      if (this.openModal === false) {
        window.scrollTo(0, 0);
      }
      this.openModal = !this.openModal;

      if (this.openModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    handlePriceOpenModal() {
      if (this.openPriceModal === false) {
        window.scrollTo(0, 0);
      }

      this.openPriceModal = !this.openPriceModal;

      if (this.openPriceModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
  },
  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  position: relative;
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
</style>
