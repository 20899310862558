<template>
  <div>
    <input type="checkbox" id="subscribtion-modal" class="modal-toggle" />
    <label for="subscribtion-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl"
        for=""
      >
        <label
          for="subscribtion-modal"
          class="btn btn-sm btn-circle right-2 top-2"
          @click="closeHandler"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="p-4">
          <h3
            v-if="subscribeStep === 0"
            class="text-xl font-bold leading-9 text-black-300 text-center"
          >
            Subscribe (1 / 4)
          </h3>
          <h3
            v-if="subscribeStep === 1"
            class="text-xl font-bold leading-9 text-black-300 text-center"
          >
            Subscribe (2 / 4)
          </h3>
          <h3
            v-if="subscribeStep === 2"
            class="text-xl font-bold leading-9 text-black-300 text-center"
          >
            Subscribe (3 / 4)
          </h3>
          <h3
            v-if="subscribeStep === 3"
            class="text-xl font-bold leading-9 text-black-300 text-center"
          >
            Subscribe (4 / 4)
          </h3>
          <p
            v-if="subscribeStep === 0"
            class="text-lg font-bold text-green-600 text-center"
          >
            Select the days you'd like your delicious meals delivered
          </p>

          <p
            v-if="subscribeStep === 1"
            class="text-lg font-bold text-green-600 text-center"
          >
            Select your preferred delivery time slot
          </p>

          <p
            v-if="subscribeStep === 2"
            class="text-lg font-bold text-green-600 text-center"
          >
            Select the duration of your subscription
          </p>
          <p
            v-if="subscribeStep === 3"
            class="text-lg font-bold text-green-600 text-center"
          >
            Overview
          </p>
        </section>

        <form class="w-100 px-4 flex flex-col justify-center items-center">
          <div
            class="w-full mt-3 px-4 flex flex-wrap gap-3 items-center justify-center p-3"
          >
            <div
              class="w-full grid-cols-2 items-center justify-center"
              :class="subscribeStep === 0 ? 'grid' : 'hidden'"
            >
              <div class="weekselection sunday">
                <label>
                  <input
                    type="checkbox"
                    value="sunday"
                    v-model="selectedDays.sunday"
                  /><span>SUNDAY</span>
                </label>
              </div>

              <div class="weekselection monday">
                <label>
                  <input
                    type="checkbox"
                    value="monday"
                    v-model="selectedDays.monday"
                  /><span>MONDAY</span>
                </label>
              </div>

              <div class="weekselection tuesday">
                <label>
                  <input
                    type="checkbox"
                    value="tuesday"
                    v-model="selectedDays.tuesday"
                  /><span>TUESDAY</span>
                </label>
              </div>

              <div class="weekselection wednesday">
                <label>
                  <input
                    type="checkbox"
                    value="wednesday"
                    v-model="selectedDays.wednesday"
                  /><span>WEDNESDAY</span>
                </label>
              </div>

              <div class="weekselection thursday">
                <label>
                  <input
                    type="checkbox"
                    value="thursday"
                    v-model="selectedDays.thursday"
                  /><span>THURSDAY</span>
                </label>
              </div>

              <div class="weekselection friday">
                <label>
                  <input
                    type="checkbox"
                    value="friday"
                    v-model="selectedDays.friday"
                  /><span>FRIDAY</span>
                </label>
              </div>

              <div class="weekselection saturday">
                <label>
                  <input
                    type="checkbox"
                    value="saturday"
                    v-model="selectedDays.saturday"
                  /><span>SATURDAY</span>
                </label>
              </div>
            </div>

            <!-- STEP 2 -->
            <div
              class="w-full mt-3 px-4 flex-wrap gap-3 items-center justify-center mb-10"
              :class="subscribeStep === 1 ? 'flex' : 'hidden'"
            >
              <label
                v-for="(time, i) in selectedComponent.timings"
                :key="i"
                :class="
                  deliveryDate.time.id === time.id
                    ? 'bg-primaryGreen text-white'
                    : 'bg-gray-100 text-black-300'
                "
                @click="setDeliveryDateTime(time)"
                class="w-[153px] rounded-md cursor-pointer text-center py-3"
              >
                <p class="text-base font-bold">{{ time.time }}</p>
              </label>
            </div>
            <!-- STEP 3 -->
            <div :class="subscribeStep === 2 ? 'block w-full mb-10' : 'hidden'">
              <label for="duration">Select Duration</label>
              <select
                v-model="selectedDuration"
                name="duration"
                id="duration"
                class="w-full p-2"
              >
                <option value="1week">1 Week</option>
                <option value="4weeks">4 Weeks</option>
                <option value="12weeks">12 Weeks</option>
                <option value="24weeks">24 Weeks</option>
                <option value="52weeks">52 Weeks</option>
                <option value="ongoing">On Going</option>
              </select>
            </div>

            <div :class="subscribeStep === 3 ? 'block w-full mb-10' : 'hidden'">
              <div>
                <h5>Selected Days</h5>
                <div
                  class="w-full flex flex-wrap items-center justify-start gap-1 mt-2"
                  v-if="confirmedDays.length > 0"
                >
                  <div
                    v-for="(days, i) in confirmedDays"
                    :key="i"
                    class="gap-2"
                  >
                    <div class="p-2 bg-primaryGreen text-white w-28 rounded-lg">
                      <p class="text-center">{{ days }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <h5>Time of Delivery</h5>
                <div
                  class="w-full flex flex-wrap items-center justify-start gap-1 mt-2"
                  v-if="confirmedDays.length > 0"
                >
                  <div class="gap-2">
                    <div class="p-2 bg-primaryGreen text-white w-32 rounded-lg">
                      <p class="text-center">{{ deliveryDate.time.time }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <h5>Duration</h5>
                <div
                  class="w-full flex flex-wrap items-center justify-start gap-1 mt-2"
                  v-if="confirmedDays.length > 0"
                >
                  <div class="gap-2">
                    <div class="p-2 bg-primaryGreen text-white w-28 rounded-lg">
                      <p class="text-center">{{ selectedDuration }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <p class="text-xs text-gray-200 mt-2">
                Note : Your billing will occur weekly for the selected services.
              </p>
            </div>
          </div>

          <div class="w-full flex items-center gap-2">
            <label
              v-if="subscribeStep === 0"
              type="button"
              for="subscribtion-modal"
              @click="closeHandler"
              class="w-full bg-green-100 text-black-300 p-2 rounded-md text-center cursor-pointer"
            >
              Cancel
            </label>
            <button
              v-else
              @click="stephandler('dec')"
              type="button"
              class="w-full bg-gray-500 p-2 rounded-md text-white font-bold"
            >
              Back
            </button>

            <label
              v-if="subscribeStep === 3"
              for="subscribtion-modal"
              @click="confirmSubcription"
              class="w-full bg-primaryGreen p-2 rounded-md text-white font-bold text-center cursor-pointer"
            >
              Subscribe
            </label>

            <button
              v-else
              :disabled="isNextStepButtonDisabled"
              @click="stephandler('inc')"
              type="button"
              class="w-full bg-primaryGreen p-2 rounded-md text-white font-bold disabled:cursor-not-allowed disabled:bg-gray-200"
            >
              Next
            </button>
          </div>
        </form>
      </label>
    </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["subcribtionType", "changeActiveButton", "storeId"],

  data() {
    return {
      isOpen: false,
      selectedComponentId: 1,
      selectedComponentlabel: "TODAY",
      subcribeType: "monthly",

      subscribeStep: 0,
      selectedDuration: "4weeks",
      deliveryDate: {
        time: { time: "8.30AM - 9AM", id: 1 },
      },
      confirmedDays: [],
      selectedComponent: {
        timings: [
          { time: "8.30AM - 9AM", id: 1 },
          { time: "9AM - 9.30AM", id: 2 },
          { time: "9.30AM - 10AM", id: 3 },
          { time: "10.30AM - 11AM", id: 4 },
          { time: "12.30PM - 1PM", id: 5 },
          { time: "1PM - 1.30PM", id: 6 },
          { time: "7PM - 7.30PM", id: 7 },
          { time: "7.30PM - 8PM", id: 8 },
          { time: "8PM - 8.30PM", id: 9 },
        ],
      },

      selectedDays: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      },
    };
  },

  // METHODS -----------------------------------
  methods: {
    ...mapActions(["UpdateShoppingCartItem"]),

    handleSubcribtionType(type) {
      this.subcribeType = type;
    },

    confirmSubcrieHandler() {
      const selectedDaysArray = Object.keys(this.selectedDays).filter(
        (day) => this.selectedDays[day]
      );

      this.confirmedDays = selectedDaysArray;
    },

    stephandler(step) {
      let check1 = this.subscribeStep === 0 ? false : true;
      let check2 = this.subscribeStep > 2 ? false : true;

      if (check1) {
        if (step === "dec") {
          this.subscribeStep = this.subscribeStep - 1;
        }
      }
      if (check2) {
        if (step === "inc") {
          this.subscribeStep = this.subscribeStep + 1;
        }
      }

      if (this.subscribeStep === 3) {
        this.confirmSubcrieHandler();
      }
    },

    confirmSubcription() {
      console.log("THS FN EXTE");
      // this.subcribtionType(this.subcribeType, time);

      if (this.subscribeStep === 3) {
        this.$emit("close");
      }
    },

    closeHandler() {
      this.$emit("close");
      this.changeActiveButton("now");
    },

    setDeliveryDateTime(time) {
      this.deliveryDate = {
        time: { id: time.id, time: time.time },
      };
    },
  },

  computed: {
    isNextStepButtonDisabled() {
      // Check if none of the days are selected
      return !Object.values(this.selectedDays).some((day) => day);
    },
  },
};
</script>

<style scoped>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}

/* This is to be able to center the content in the middle of the page; */

div label input {
  margin-right: 100px;
}

.weekselection {
  margin: 4px;
  background-color: #cacaca;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
  color: #000000;
}
.weekselection label:hover {
  cursor: pointer;
}
.weekselection label {
  float: left;
  line-height: 3em;
  width: 100%;
  height: 3em;
  color: #000000;
}

.weekselection label span {
  text-align: center;
  padding: 3px 0;
  display: block;
  color: #000000;
}

.weekselection label input {
  position: absolute;
  display: none;
  color: #000000 !important;
}
/* selects all of the text within the input element and changes the color of the text */
.weekselection label input + span {
  color: #000000;
}

/* This will declare how a selected input will look giving generic properties */
.weekselection input:checked + span {
  color: #000000;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

/*
This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
*/

.sunday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.monday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.tuesday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.wednesday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.thursday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.friday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.saturday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.sports input:checked + span {
  @apply bg-primaryGreen text-white;
}
</style>
