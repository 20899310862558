import axios from "axios";
const homeChefStore = {
  state: {
    shopByHomeChef: [],
  },
  getters: {
    getAllhomeChefStore(state) {
      return state.shopByHomeChef;
    },
  },
  actions: {
    async SetAllhomeChefStore({ commit }) {
      await axios.get(`/services/app/Shop/GetAllHomeChefs`).then((res) => {
        commit("setAllhomeChefStore", res?.data?.result?.items);
      });
    },
  },
  mutations: {
    setAllhomeChefStore(state, response) {
      state.shopByHomeChef = response;
    },
  },
};
export default homeChefStore;
