<template>
  <div class="w-full pt-6">
    <VueSlickCarousel
      :slidesToShow="slidesToShow"
      ref="carousel"
      :responsive="responsive"
    >
      <div v-for="(val, i) in tags" :key="i" class="px-3">
        <div
          class="flex flex-col justify-center items-center text-xs space-y-3 cursor-pointer text-center"
          @click="
            $emit('handleClick', { id: val.tag.id, name: val.tag.name })
          "
        >
          <img src="@/assets/tag.png" class="w-16 rounded-lg" />
          <p>
            {{ val.tag.name }}
          </p>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
  
  <script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  props: ["tags", "slidesToShow", "isAddButton", "showImgTag", "cartItems"],
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      // products: carouselData,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
  methods: {
    isInCart(items) {
      if (this.cartItems) {
        return this.cartItems.find((val) => {
          return val.foodMenuId === items.id;
        });
      } else {
        return false;
      }
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "~@storefront-ui/vue/styles";

.carousel {
  padding: 0;
  background-color: white;

  @include for-desktop {
    margin: 0;
  }

  &__item {
    margin: 1.9375rem 0 2.4375rem 0;
  }
}
</style>
  