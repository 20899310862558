<template>
  <div
    class="bg-gray-100 rounded-full shadow-lg flex items-center justify-between w-28"
  >
    <SfButton class="bg-white rounded-full p-1" @click="$emit('delete')">
      <img src="@/assets/svg/delete.svg" alt="some" />
    </SfButton>
    <p>{{ amount }} x</p>
    <SfButton class="bg-white rounded-full p-1" @click="$emit('add')">
      <img src="@/assets/svg/add.svg" alt="some" />
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from "@storefront-ui/vue";
export default {
  props: ["amount"],
  components: { SfButton },
};
</script>

<style>
</style>