<template lang="">
  <div>
    <Teleport to="#EditSubs">
      <Transition name="modal">
        <div
          class="z-50 fixed flex items-center justify-center top-0 left-0 w-[100vw] h-[100vh] bg-[rgba(0,0,0,.5)]"
          v-if="isModalOpen"
        >
          <div
            ref="modal"
            v-if="mode === 'cancel'"
            class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-md p-4"
          >
            <h4 class="text-lg font-semibold">Cancel Subscription</h4>

            <p>Are you sure to Cancel the NoshBox Plan ?</p>
            <p class="text-sm text-gray-400 my-2">
              Note : refund will be processed with in a week
            </p>

            <div class="w-full flex items-center gap-2 mt-4">
              <button
                @click="closeModal"
                class="bg-primaryGreen p-2 text-white rounded-md w-full"
              >
                Go Back
              </button>
              <button
                @click="cancelSubscription"
                class="bg-r-400 p-2 text-white rounded-md w-full"
              >
                Confirm Cancellation
              </button>
            </div>
          </div>

          <div
            class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-md p-8"
            ref="modal"
            v-if="mode === 'pause'"
          >
            <h4 class="text-lg font-semibold">Pause Subscription</h4>

            <p>Are you sure to pause the NoshBox Subscription Plan ?</p>
            <p class="text-sm text-primaryGreen my-2">
              Note : You can resume your current plan anytime
            </p>
            <div class="w-full flex items-center gap-2 mt-4">
              <button
                @click="closeModal"
                class="bg-primaryGreen p-2 text-white rounded-md w-full"
              >
                Go Back
              </button>
              <button
                @click="pauseSubscription"
                class="bg-gray-200 p-2 text-white rounded-md w-full"
              >
                Pause
              </button>
            </div>
          </div>

          <div
            class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-md p-8"
            ref="modal"
            v-if="mode === 'resume'"
          >
            <h4 class="text-lg font-semibold">Resume Subscription</h4>

            <p>Welcome back to NoshBox Subscription Plan</p>

            <div class="w-full flex items-center gap-2 mt-4">
              <button
                @click="closeModal"
                class="bg-gray-200 p-2 text-white rounded-md w-full"
              >
                Go Back
              </button>
              <button
                @click="resumeSubscription"
                class="bg-primaryGreen p-2 text-white rounded-md w-full"
              >
                Activate
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
<script>
import { ref } from "vue";
import axiosInstance from "../../../helpers/axiosInstance";
export default {
  props: ["isModalOpen", "closeModal", "mode", "isActive", "refetch"],
  data() {
    return {
      modal: ref(null),
      isOpen: false,
    };
  },

  methods: {
    cancelSubscription() {
      axiosInstance
        .post("/services/app/OrdersSubscription/CancelSubscription", {
          id: this.$route.params.subid,
        })
        .then((res) => {
          console.log(res);
          this.closeModal();
          this.$swal({
            title: "Subscription cancelled",
            text: "We miss you",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Done", // Change button text
            showCloseButton: true,
          });

          this.refetch();
        });
    },

    pauseSubscription() {
      axiosInstance
        .post("/services/app/OrdersSubscription/PauseSubscription", {
          id: this.$route.params.subid,
        })
        .then((res) => {
          console.log(res);
          this.closeModal();

          this.$swal({
            title: "Subscription Paused",
            text: "We miss you , hope you resume our subscription soon",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Done", // Change button text
            showCloseButton: true,
          });
          this.refetch();
        });
    },

    resumeSubscription() {
      axiosInstance
        .post("/services/app/OrdersSubscription/ResumeSubscription", {
          id: this.$route.params.subid,
        })
        .then((res) => {
          console.log(res);
          this.closeModal();

          this.$swal({
            title: "Welcome back",
            text: "We are thrilled to serve you again",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Done", // Change button text
            showCloseButton: true,
          });
          this.refetch();
        });
    },
  },
};
</script>
<style scoped>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}

/* This is to be able to center the content in the middle of the page; */

div label input {
  margin-right: 100px;
}

.weekselection {
  margin: 4px;
  background-color: #cacaca;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
  color: #000000;
}
.weekselection label:hover {
  cursor: pointer;
}
.weekselection label {
  float: left;
  line-height: 3em;
  width: 100%;
  height: 3em;
  color: #000000;
}

.weekselection label span {
  text-align: center;
  padding: 3px 0;
  display: block;
  color: #000000;
}

.weekselection label input {
  position: absolute;
  display: none;
  color: #000000 !important;
}
/* selects all of the text within the input element and changes the color of the text */
.weekselection label input + span {
  color: #000000;
}

/* This will declare how a selected input will look giving generic properties */
.weekselection input:checked + span {
  color: #000000;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

/*
  This following statements selects each category individually that contains an input element that is a checkbox and is checked (or selected) and chabges the background color of the span element.
  */

.sunday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.monday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.tuesday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.wednesday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.thursday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.friday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.saturday input:checked + span {
  @apply bg-primaryGreen text-white;
}
.sports input:checked + span {
  @apply bg-primaryGreen text-white;
}
</style>
