<template>
  <section class="w-100 h-24 mt-3">
    <div
      class="w-full flex animate-pulse flex-row items-center h-full justify-center space-x-5"
    >
      <div class="w-full flex items-center justify-between px-4">
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
        <div class="w-28 h-16 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
