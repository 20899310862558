<template>
  <LandingLayout>
    <div class="flex justify-center">
      <div class="w-8/12 py-12">
        <h1 class="text-3xl font-bold">Get Help</h1>
        <p class="py-4 font-bold text-gray-600">
            Which order do you need help with?
        </p>
        <p class="text-lg font-bold">
            No Previous Deliveries, Order Today!
        </p>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "../../../layouts/landingLayout.vue";
export default {
  components: {
    LandingLayout,
  },
};
</script>

<style>
</style>