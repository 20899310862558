var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"h-48 w-full relative"},[(_vm.tag)?_c('p',{staticClass:"bg-gray-850 text-white text-xs absolute left-3 top-3 px-3 rounded-md py-0.5 font-medium"},[_vm._v(" Get 20% off (up to CA$7) ")]):_vm._e(),(_vm.isStore)?_c('div',{staticClass:"h-full w-full"},[(_vm.product?.imageUrl)?_c('div',{staticClass:"h-full w-full rounded-lg cursor-pointer",style:({
          backgroundImage: `url(${_vm.product?.imageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: '390px',
          backgroundRepeat: 'no-repeat',
        }),on:{"click":function($event){return _vm.$emit('visitStore', _vm.product)}}}):_c('div',{staticClass:"cursor-pointer w-full h-full storeImage rounded-lg",on:{"click":function($event){return _vm.$emit('visitStore', _vm.product)}}})]):_c('div',{staticClass:"h-full w-full"},[(_vm.product?.imageUrl)?_c('div',{staticClass:"h-full w-full rounded-lg cursor-pointer",style:({
          backgroundImage: `url(${_vm.product?.imageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: '260px',
          backgroundRepeat: 'no-repeat',
        }),on:{"click":function($event){return _vm.$emit('visitStore', _vm.product)}}}):_c('div',{staticClass:"w-full h-full storeImage rounded-lg",on:{"click":function($event){return _vm.$emit('visitStore', _vm.product)}}})]),(_vm.isAddButton && !_vm.isAdded)?_c('SfButton',{staticClass:"text-sm absolute bottom-2 right-2 rounded-full bg-white text-black py-1.5 px-3 capitalize",on:{"click":function($event){return _vm.$emit('add')}}},[_vm._v(" Add ")]):_vm._e(),(_vm.isAddButton && _vm.isAdded && _vm.isAdded?.quantity !== 0)?_c('Counter',{staticClass:"absolute bottom-2 right-2",attrs:{"amount":_vm.isAdded?.quantity},on:{"add":function($event){return _vm.$emit('increaseQuantity')},"delete":function($event){return _vm.$emit('decreaseQuantity')}}}):_vm._e()],1),_c('div',{staticClass:"pt-3 flex items-center justify-between"},[_c('div',{staticClass:"flex items-center cursor-pointer",on:{"click":function($event){return _vm.$emit('visitStore', _vm.product)}}},[_c('p',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.isStore ? _vm.product?.storeName : _vm.product.name)+" ")]),(_vm.isLogo)?_c('img',{staticClass:"w-8",attrs:{"src":require("@/assets/logo-simple.jpeg")}}):_vm._e()]),_c('button',{on:{"click":_vm.addStoreTOwishList}},[_c('WishlistBtn',{attrs:{"isLiked":_vm.savedShop}})],1)]),_c('p',{staticClass:"text-gray-500 text-sm"},[_vm._v(_vm._s(_vm.distance)+" / "+_vm._s(_vm.duration))])])
}
var staticRenderFns = []

export { render, staticRenderFns }