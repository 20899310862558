<template lang="">
  <landing-layout>
    <div class="wrapper">
      <div class="mt-4">
        <h2 class="text-3xl font-semibold">Manage Payments</h2>
      </div>
      <div v-if="getAllCards?.length > 0" class="flex gap-2 flex-wrap">
        <div v-for="(card, i) in getAllCards" :key="i">
          <Card
            :cardnumber="card.last4"
            :cardId="card.cardId"
            :isDefault="card.isDefault"
            :data="cardData1"
            :brand="card.cardBrand"
          />
        </div>
        <div
          class="w-96 h-56 bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform"
        >
          <div
            class="felx flex-col w-full h-full bg-black-300 rounded-md flex items-center justify-center"
          >
            <img src="@/assets/addcardicon.png" alt="" />
            <label
              for="payment-modal"
              class="cursor-pointer p-3 text-base text-black bg-white rounded-md"
            >
              Add New Card
            </label>
          </div>
        </div>
      </div>
      <section v-else class="w-100 mt-3">
        <div
          class="w-full flex flex-col items-center h-full justify-center space-x-5"
        >
          <img class="w-[30%]" src="@/assets/wallet.svg" alt="" />
          <h2 class="text-2xl">No Card Found</h2>
          <label
            for="payment-modal"
            class="cursor-pointer p-3 text-base text-white bg-primaryGreen rounded-md"
          >
            Add New Card
          </label>

          <!-- <div
            class="w-full flex items-center justify-between px-4 flex-wrap gap-4"
          >
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
          </div> -->
        </div>
      </section>
    </div>
    <PaymentModal />
  </landing-layout>
</template>
<script>
import landingLayout from "@/layouts/landingLayout.vue";
import Card from "./card.vue";
import { mapGetters, mapActions } from "vuex";
import PaymentModal from "@/components/reusable/modals/payment.vue";
export default {
  components: { landingLayout, Card, PaymentModal },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getAllCards"]),
  },
  async created() {
    await this.SetAllCards();
  },
  methods: {
    ...mapActions(["SetAllCards"]),
  },

  mounted() {
    console.log("GET CARDS", this.getAllCards);
  },
};
</script>
<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
