import axios from "axios";
const groceryStore = {
  state: {
    shopByGroceryStore: [],
  },
  getters: {
    getAllGroceryStore(state) {
      return state.shopByGroceryStore;
    },
  },
  actions: {
    async SetAllGroceryStore({ commit }) {
      await axios.get(`/services/app/Shop/GetAllConvinienceStores`).then((res) => {
        commit("setAllGroceryStore", res?.data?.result?.items);
      });
    },
  },
  mutations: {
    setAllGroceryStore(state, response) {
      state.shopByGroceryStore = response;
    },
  },
};
export default groceryStore;
