<template>
  <landing-layout>
    <div class="flex justify-center">
      <div class="xl:w-9/12 lg:w-10/12 w-full px-5">
        <div class="flex py-3">
          <h1
            class="font-bold text-sm underline cursor-pointer"
            @click="$router.push(`/customer/store/${storeId}`)"
          >
            Store Home
          </h1>
          <h2 class="pl-2 font-bold text-sm text-gray-500">
            / {{ categoryId?.name }}
          </h2>
        </div>
        <div
          class="flex md:flex-row flex-col justify-between items-center py-3"
        >
          <p class="text-black text-3xl font-bold">
            {{ categoryId?.name }}
          </p>
          <div class="md:w-1/3 w-full pt-3">
            <Search
              :placeholder="`Search ${getStoreInfo?.shopName}`"
              @new-Value="searchProducts"
            />
          </div>
        </div>
        <Categories
          :tags="getStoreInfo.categoryTags"
          :ref="`categories.category.tag`"
          :slidesToShow="12"
          :isAddButton="true"
          :showImgTag="false"
          @handleClick="
            (val) =>
              $router.push(
                `/customer/store/${
                  $route.params.storeId
                }/category/${JSON.stringify(val)}`
              )
          "
        />
        <div
          class="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-8 py-10"
          v-if="getStoreInfoByCategory?.categoryTags[0].items"
        >
          <ProductCard
            v-for="(product, index) in getStoreInfoByCategory?.categoryTags[0]
              .items"
            :product="product"
            :key="index"
            @add="createCart({ product: product })"
            :isAdded="isInCart(product)"
            :tag="false"
            :isAddButton="true"
            @increaseQuantity="increaseQuantity(isInCart(product))"
            @decreaseQuantity="decreaseQuantity(isInCart(product))"
          />
        </div>
      </div>
    </div>
  </landing-layout>
</template>

<script>
import landingLayout from "../../../layouts/landingLayout.vue";
import Search from "@/components/reusable/search.vue";
import Categories from "../../../components/storeDetail/categories.vue";
import ProductCard from "@/components/reusable/productCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { landingLayout, Search, Categories, ProductCard },
  async mounted() {
    await this.SetStoreInfo({
      id: this.$route.params.storeId,
      searchField: "",
    });
    await this.GetCart();
    await this.SetStoreInfoByCategory({
      id: this.$route.params.storeId,
      searchField: "",
      tagId: this.categoryId.id,
    });

    console.log(
      this.getStoreInfoByCategory?.categoryTags[0].items,
      "tag",
      this.getCartInfo
    );
  },
  watch: {
    async categoryId(newVal) {
      await this.SetStoreInfoByCategory({
        id: this.$route.params.storeId,
        searchField: "",
        tagId: newVal.id,
      });
    },
  },
  methods: {
    ...mapActions([
      "SetStoreInfoByCategory",
      "SetStoreInfo",
      "CreateCart",
      "GetCart",
      "UpdateCart",
      "AddToCart",
      "SetStoreInfo",

      "SetCategoryInfo",
      "SetAllCarts",
    ]),

    isInCart(items) {
      console.log(
        "ISINCART IS RUNNING ",
        items,
        "CART INFO ",
        this.getCartInfo
      );
      let cartItem = this.getCartInfo?.shoppingCartItems
        ? this.getCartInfo?.shoppingCartItems
        : false;

      console.log("cartItem ", cartItem);
      if (cartItem) {
        return cartItem.find((val) => {
          return val.foodMenuId === items.id;
        });
      } else {
        return false;
      }
    },

    async createCart(payload) {
      if (!this.getCartInfo) {
        console.log("CREATEING A NEW CART");

        await this.CreateCart({
          id: payload.product.id,
          price: payload.product.unitPrice,
          quantity: "1",
          shopId: this.getStoreInfo.shopId,
        });
        const load = {
          shoppingCartItem: {
            shopId: this.$route.params.storeId,
            shoppingCartId: this.getCartInfo.id,
            foodMenuId: payload.product.id,
            quantity: "1",
          },
          shoppingCartId: this.getCartInfo.id,
        };

        await this.AddToCart(load);
        await this.SetAllCarts();
      } else {
        console.log("GETTING OLD CART ");

        const load = {
          shoppingCartItem: {
            shopId: this.$route.params.storeId,
            shoppingCartId: this.getCartInfo.id,
            foodMenuId: payload.product.id,
            quantity: "1",
          },
          shoppingCartId: this.getCartInfo.id,
        };

        await this.AddToCart(load);
        await this.SetAllCarts();
      }
    },

    async searchProducts(val) {
      this.SetStoreInfoByCategory({
        id: this.$route.params.storeId,
        searchField: val,
        tagId: this.categoryId.id,
      });
    },
    increaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity + 1,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      this.UpdateCart(payload);
    },
    decreaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity > 0 ? val?.quantity - 1 : 0,
        },
        shoppingCartId: val?.shoppingCart?.id,
      };
      this.UpdateCart(payload);
    },
  },
  computed: {
    ...mapGetters([
      "getStoreInfo",
      "getCartInfo",
      "getStoreInfoByCategory",
      "getCategoryInfo",
    ]),
    storeId() {
      return this.$route.params.storeId;
    },
    categoryId() {
      const id = JSON.parse(this.$route.params.categoryId);
      return id;
    },
  },
};
</script>

<style></style>
