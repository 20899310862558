<template>
  <landing-layout>
    <section class="wrapper mt-4">
      <!-- Header Text -->
      <div>
        <h3 class="text-2xl p-2 font-semibold lg:text-3xl lg:p-0">
          Subscriptions
        </h3>
      </div>

      <div
        v-if="getAllOrders.length > 0"
        class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 px-2 mt-4"
      >
        <!-- ORDERED CARD -->
        <div
          v-for="(order, index) in subscriptionData"
          :key="index"
          class="w-max-[360px] h-max-[250px] bg-white shadow-md rounded-lg p-4"
        >
          <div
            v-if="!order?.shopLogoUrl"
            class="bg-grey-100 h-[120px] rounded-md"
          ></div>
          <img
            v-else
            :src="order?.shopLogoUrl"
            class="bg-grey-100 h-[120px] w-full rounded-md"
          />
          <router-link :to="`/customer/subscriptions/${order?.id}`">
            <div class="cursor-pointer">
              <div class="mt-1 flex items-center justify-between">
                <h4 class="text-lg font-bold">
                  {{ order?.foodMenu?.storeName }}
                </h4>
                <h6 class="text-xs">Subscription Id: {{ order?.id }}</h6>
              </div>
              <div class="mt-1 flex gap-1 items-center">
                <p class="text-base font-bold">Status -</p>
                <p class="text-base font-bold text-green-600">
                  {{
                    order?.isActive === true
                      ? order.isPaused === true
                        ? "Paused"
                        : "Active"
                      : order?.isActive === false && "Cancelled"
                  }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <section v-else class="w-100 mt-3">
        <div class="flex items-center justify-center flex-col">
          <h2 class="text-2xl font-bold">No Orders Found</h2>
          <img class="w-[40%] h-[40%]" src="@/assets/no_orders.svg" alt="" />

          <button class="p-2 bg-primaryGreen text-white text-lg rounded-md">
            Start Shopping
          </button>
        </div>
      </section>
    </section>
  </landing-layout>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import { mapActions, mapGetters } from "vuex";
import axiosInstance from "../../../helpers/axiosInstance";

export default {
  data() {
    return {
      isLoading: false,
      subscriptionData: [],
    };
  },
  components: { landingLayout },

  methods: {
    ...mapActions(["SetAllOrders"]),
    getSubscriptions() {
      axiosInstance
        .get(
          "/services/app/OrdersSubscription/GetCustomersSubscriptions?SkipCount=1"
        )
        .then((res) => {
          console.log(res);
          this.subscriptionData = res?.data?.result?.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.SetAllOrders();
    this.getSubscriptions();
  },
  computed: {
    ...mapGetters(["getAllOrders"]),
  },
};
</script>

<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
