<template>
  <section
    v-if="isLoading === false"
    class="wrapper flex flex-col px-5 relative"
  >
    <div
      v-if="categories?.length > 0"
      class="h-24 w-100 flex items-center justify-between gap-3"
    >
      <div class="overflow-hidden px-3">
        <VueSlickCarousel
          v-if="categories.length > 0"
          v-bind="settings"
          ref="categoryCarousel"
        >
          <div v-for="(category, i) in categories" :key="i">
            <SubcategoryButton
              :name="category?.description"
              :image="category?.iconUrl"
              @handleClick="$router.push(`/customer/category/${category?.id}`)"
            /></div
        ></VueSlickCarousel>
      </div>

      <button
        @click="showNext"
        class="absolute w-8 h-8 p-2 rounded-full bg-gray-100 flex items-center justify-center right-3"
      >
        <img src="@/assets/Home/SubCategories/rightarrow.svg" alt="NExt" />
      </button>
      <button
        @click="showPrev"
        class="absolute w-8 h-8 p-2 rounded-full bg-gray-100 flex items-center justify-center left-3"
      >
        <img
          class=""
          src="@/assets/Home/SubCategories/leftarrow.svg"
          alt="NExt"
        />
      </button>
    </div>
  </section>
  <CarouselLoader v-else></CarouselLoader>
</template>

<script>
import SubcategoryButton from "@/components/reusable/SubCategoryButton";
import CarouselLoader from "@/components/skeltons/CarouselSkeleton.vue";

import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    SubcategoryButton,
    VueSlickCarousel,
    CarouselLoader,
  },
  props: ["categories", "isLoading"],
  data() {
    return {
      settings: {
        dots: false,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      },
    };
  },

  methods: {
    showNext() {
      this.$refs.categoryCarousel.next();
    },
    showPrev() {
      this.$refs.categoryCarousel.prev();
    },
  },
};
</script>
