<template>
  <landing-layout>
    <section class="wrapper mt-2">
      <!-- Header Text -->
      <div>
        <h3 class="text-2xl p-2 font-semibold lg:text-4xl lg:p-0">
          Saved Stores
        </h3>
      </div>

      <!-- LIKED STORES -->
      <div class="mt-4">
        <!-- Main Section If no Saved Stores Available -->

        <div
          class="flex justify-center items-center w-100"
          v-if="isLoading === true"
        >
          <div>
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            v-if="likedStores.length === 0"
            class="mt-5 flex flex-col items-center gap-5 p-2 lg:p-0"
          >
            <div class="flex flex-col items-center gap-2">
              <img src="@/assets/savedStores/heart.svg" alt="" />
              <h3 class="text-xl font-bold">You don’t have any saved stores</h3>
            </div>

            <div class="flex items-center gap-2">
              <img src="@/assets/savedStores/read.svg" alt="" />
              <h3 class="text-base font-semibold">
                Saving stores lets you quickly access them whenever you want
              </h3>
            </div>

            <div class="flex items-center gap-2">
              <img src="@/assets/savedStores/user.svg" alt="" />
              <h3 class="text-base font-semibold">
                We’ll also use the stores you save to personalize your
                experience
              </h3>
            </div>
            <router-link :to="`/customer/main`">
              <button class="px-3 py-2 bg-grey-200 rounded-full">
                Browse Stores
              </button>
            </router-link>
          </div>
          <div
            v-else
            class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 px-2"
          >
            <div v-for="(store, i) in likedStores" :key="i" class="">
              <div class="w-max-[360px] cursor-pointer">
                <router-link :to="`/customer/store/${store.shopId}`">
                  <div
                    class="bg-grey-200 rounded-lg flex justify-center items-center h-52 overflow-hidden"
                  >
                    <img v-if="store.bannerUrl" :src="store.bannerUrl" alt="" />
                    <img
                      v-else
                      class="w-[180px]"
                      src="@/assets/placeholder.jpeg"
                      alt=""
                    />
                  </div>
                </router-link>

                <!-- CARD DETAILS -->
                <div>
                  <div class="flex items-center justify-between mt-2 px-2">
                    <h3 class="text-lg font-bold">{{ store.shopName }}</h3>

                    <button @click="removeLikedStore(store.shopId)">
                      <WishlistBtn :isLiked="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </landing-layout>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import WishlistBtn from "../../../components/reusable/WishlistBtn.vue";
import axiosInstance from "../../../helpers/axiosInstance";
export default {
  data() {
    return {
      likedStores: [],
      isLoading: false,
    };
  },
  components: { landingLayout, WishlistBtn },
  methods: {
    getSavedStores() {
      this.isLoading = true;

      axiosInstance
        .get("/services/app/ShopLikeList/GetAllByCustomer")
        .then((res) => {
          // console.log(res);
          this.likedStores = res.data.result.items;
          this.isLoading = false;
          console.log(this.likedStores);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    removeLikedStore(id) {
      this.isLoading = true;

      axiosInstance
        .delete(`/services/app/ShopLikeList/RemoveShopLike?Id=${id}`)
        .then(() => {
          // console.log(res);
          this.isLoading = false;

          this.getSavedStores();
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getSavedStores();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
