<template>
  <landing-layout>
    <div class="grid grid-cols-12">
      <div class="flex justify-center lg:col-span-9 col-span-12 px-5">
        <div class="xl:w-11/12 w-full">
          <div v-if="isLoader === false">
            <div>
              <img
                v-if="getStoreInfo.logoUrl"
                :src="getStoreInfo.logoUrl"
                alt="profile"
                class="w-20 h-20 rounded-full shadow-lg mt-10 md:mx-0 mx-auto"
              />
              <img
                v-else
                src="@/assets/logo-simple.jpeg"
                alt="profile"
                class="w-20 h-20 rounded-full shadow-lg mt-10 md:mx-0 mx-auto"
              />
              <div class="flex items-center gap-4">
                <h1 class="text-4xl font-bold py-2 md:text-left text-center">
                  {{ getStoreInfo.shopName }}
                </h1>

                <button @click="addStoreTOwishList">
                  <WishlistBtn :isLiked="getStoreInfo.isLiked" />
                </button>
              </div>
              <div
                class="flex md:flex-row flex-col justify-between items-center"
              >
                <p class="text-gray-500">
                  <!-- {{ getStoreInfo.rating }} (386) •
                  {{ getStoreInfo?.deliveryTime }}mi • ${{
                    getStoreInfo.deliveryFee
                  }} -->
                </p>
                <div class="md:w-1/3 w-full pt-3">
                  <Search
                    :placeholder="`Search ${getStoreInfo.shopName}`"
                    @new-Value="searchProducts"
                  />
                </div>
              </div>
              <div class="flex py-4">
                <div
                  class="flex items-center border border-gray-100 p-1 rounded-md"
                >
                  <div
                    class="md:w-48 w-24 bg-gray-150 flex flex-col items-center py-2 text-gray-500 rounded-md"
                  >
                    <p class="text-lg font-bold">
                      {{ getStoreInfo?.deliveryFee }}
                    </p>
                    <p class="text-sm">delivery fee</p>
                  </div>
                  <div
                    class="md:w-48 w-24 flex flex-col items-center py-2 border-l border-gray-100 ml-1"
                  >
                    <p class="text-lg font-bold">
                      {{ getStoreInfo?.deliveryTime }}
                    </p>
                    <p class="text-sm">delivery time</p>
                  </div>
                </div>
              </div>
              <!-- <SubCategories class="py-4" /> -->
              <Categories
                :tags="getStoreInfo.categoryTags"
                :ref="`categories.category.tag`"
                :slidesToShow="12"
                :isAddButton="true"
                :showImgTag="false"
                @handleClick="
                  (val) =>
                    $router.push(
                      `/customer/store/${storeId}/category/${JSON.stringify(
                        val
                      )}`
                    )
                "
              />
              <div
                class="pt-8 border-t border-gray-100 mt-10"
                v-for="(categories, i) in getStoreInfo.categoryTags"
                :key="i"
              >
                <div class="flex items-center justify-between">
                  <p
                    class="pb-0 lg:text-2xl md:text-xl text-lg text-black font-semibold"
                  >
                    {{ categories.tag.name }}
                  </p>
                  <div class="flex items-center space-x-4">
                    <p class="text-sm font-medium text-black">See All</p>
                    <div class="flex items-center space-x-2">
                      <SfButton
                        class="p-2 rounded-full bg-gray-100"
                        @click="
                          () => {
                            prev(i);
                          }
                        "
                      >
                        <img src="@/assets/svg/left-C.svg" />
                      </SfButton>
                      <SfButton
                        class="p-2 rounded-full bg-gray-100"
                        @click="
                          () => {
                            next(i);
                          }
                        "
                      >
                        <img src="@/assets/svg/right-C.svg" />
                      </SfButton>
                    </div>
                  </div>
                </div>
                <Carousel
                  v-if="categories.items"
                  :isLogo="false"
                  :ref="`categories.category.name`"
                  :isLike="false"
                  :cartItems="
                    getCartInfo?.shoppingCartItems
                      ? getCartInfo?.shoppingCartItems
                      : false
                  "
                  :slidesToShow="5"
                  :isAddButton="true"
                  :showImgTag="false"
                  :products="categories.items"
                  @increaseQuantity="(val) => increaseQuantity(val)"
                  @decreaseQuantity="(val) => decreaseQuantity(val)"
                  @addProduct="
                    (product) =>
                      createCart({ product: product, tag: categories.tag })
                  "
                />
              </div>
            </div>
          </div>
          <div v-else class="pt-8 space-y-10 px-4">
            <div
              class="h-3.5 bg-gray-50 rounded-full dark:bg-gray-700 w-48 mb-4"
            ></div>
            <div class="grid md:grid-cols-3 grid-cols-1 gap-10">
              <productCardSkelton />
              <productCardSkelton class="md:block hidden" />
              <productCardSkelton class="md:block hidden" />
            </div>
            <div
              class="h-3.5 bg-gray-50 rounded-full dark:bg-gray-700 w-48 mb-4"
            ></div>
            <div class="grid md:grid-cols-3 grid-cols-1 gap-10">
              <productCardSkelton />
              <productCardSkelton class="md:block hidden" />
              <productCardSkelton class="md:block hidden" />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full col-span-3 border-l border-gray-100 xl:block hidden">
        <div class="w-100 fixed overflow-y-auto h-full px-8">
          <div v-if="getCartInfo?.shoppingCartItems.length">
            <div class="pt-12 pb-4 border-b border-gray-100">
              <p class="text-sm font-semibold text-gray-850">
                {{ $t("yourCartFrom") }}
              </p>
              <div class="flex items-center space-x-3">
                <p class="font-semibold text-lg">{{ getCartInfo?.shopName }}</p>
                <img src="@/assets/svg/right.svg" alt="right" class="w-4" />
              </div>
              <SfButton
                @click="
                  $router.push(`/customer/checkout/${$route.params.storeId}`)
                "
                class="w-full flex justify-between rounded-full bg-red px-4 capitalize py-2.5 mt-4"
              >
                <p>{{ $t("checkout") }}</p>
                <p>CA${{ getCartInfo?.totalPrice }}</p>
              </SfButton>
            </div>
            <div>
              <template>
                <CartItem
                  v-for="(item, index) in getCartInfo?.shoppingCartItems"
                  :key="index"
                  :item="item"
                  @increaseQuantity="(val) => increaseQuantity(val)"
                  @decreaseQuantity="(val) => decreaseQuantity(val)"
                />
              </template>
            </div>
          </div>
          <div v-else class="flex flex-col items-center justify-center h-full">
            <img src="@/assets/svg/mainCart.svg" alt="cart" class="w-56" />
            <p class="text-gray-400 text-center px-10 pt-3">
              Order Cart Unavailable Uh oh, something went wrong
            </p>
          </div>
        </div>
      </div>
    </div>
  </landing-layout>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import Search from "@/components/reusable/search.vue";
import Carousel from "@/components/reusable/carousel.vue";
import productCardSkelton from "@/components/skeltons/productCardSkelton.vue";
import Categories from "../../../components/storeDetail/categories.vue";
import CartItem from "@/components/cart/cartItem.vue";
import { SfButton } from "@storefront-ui/vue";
import { mapActions, mapGetters } from "vuex";
import WishlistBtn from "../../../components/reusable/WishlistBtn.vue";
import axiosInstance from "../../../helpers/axiosInstance";

export default {
  components: {
    landingLayout,
    Search,
    Carousel,
    SfButton,
    productCardSkelton,
    Categories,
    CartItem,
    WishlistBtn,
  },
  data() {
    return {
      isLoader: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions([
      "SetStoreInfo",
      "CreateCart",
      "GetCart",
      "UpdateCart",
      "AddToCart",
      "SetCategoryInfo",
      "SetAllCarts",
    ]),
    next(index) {
      this.$refs["categories.category.name"][index].showNext();
    },
    prev(index) {
      this.$refs["categories.category.name"][index].showPrev();
    },
    async increaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity + 1,
        },
        shoppingCartId: val?.shoppingCart?.id,
        shopId: this.getStoreInfo.shopId,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
      await this.SetStoreInfo({
        id: this.$route.params.storeId,
        searchField: "",
      });
    },
    async decreaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity > 1 ? val?.quantity - 1 : 0,
        },
        shoppingCartId: val?.shoppingCart?.id,
        shopId: this.getStoreInfo.shopId,
      };
      await this.UpdateCart(payload);
      await this.SetAllCarts();
      await this.SetStoreInfo({
        id: this.$route.params.storeId,
        searchField: "",
      });
    },
    async createCart(payload) {
      if (!this.getCartInfo) {
        console.log("CREATEING A NEW CART");

        await this.CreateCart({
          id: payload.product.id,
          price: payload.product.unitPrice,
          quantity: "1",
          shopId: this.getStoreInfo.shopId,
        });
        const load = {
          shoppingCartItem: {
            shopId: this.$route.params.storeId,
            shoppingCartId: this.getCartInfo.id,
            foodMenuId: payload.product.id,
            quantity: "1",
          },
          shoppingCartId: this.getCartInfo.id,
        };

        await this.AddToCart(load);
        await this.SetAllCarts();
      } else {
        console.log("GETTING OLD CART ");

        const load = {
          shoppingCartItem: {
            shopId: this.$route.params.storeId,
            shoppingCartId: this.getCartInfo.id,
            foodMenuId: payload.product.id,
            quantity: "1",
          },
          shoppingCartId: this.getCartInfo.id,
        };

        await this.AddToCart(load);
        await this.SetAllCarts();
      }
    },
    async searchProducts(val) {
      await this.SetStoreInfo({
        id: this.$route.params.storeId,
        searchField: val,
      });
    },

    addStoreTOwishList() {
      if (!this.getStoreInfo.isLiked) {
        axiosInstance
          .post("/services/app/ShopLikeList/Create", {
            shopId: this.$route.params.storeId,
          })
          .then(async (res) => {
            console.log(res);
            await this.SetStoreInfo({
              id: this.$route.params.storeId,
              searchField: "",
            });
            this.isLiked = "success";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axiosInstance
          .delete(
            `/services/app/ShopLikeList/RemoveShopLike?Id=${this.$route.params.storeId}`
          )
          .then(async (res) => {
            console.log(res);
            await this.SetStoreInfo({
              id: this.$route.params.storeId,
              searchField: "",
            });
            this.isLiked = "deleted";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },

  computed: {
    ...mapGetters(["getStoreInfo", "getCartInfo", "getCategoryInfo"]),
    storeId() {
      const id = this.$route.params.storeId;

      return id;
    },
  },
  async mounted() {
    this.isLoader = true;

    await this.SetStoreInfo({
      id: this.$route.params.storeId,
      searchField: "",
    });
    this.isLoader = false;
    await this.GetCart({ id: this.$route.params.storeId });
  },
};
</script>

<style></style>
