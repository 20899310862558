import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("address");
  // localStorage.setItem("loggedIn" , "false");
};
const axiosInstance = axios.create({
  baseURL: "https://dev-vneed-api.azurewebsites.net/api", // Your API base URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Retrieve token from storage
    if (token) {
      const accessdecoded_token = jwt_decode(token);
      const accessisExpired =
        dayjs.unix(accessdecoded_token.exp).diff(dayjs()) < 1;
      if (accessisExpired) {
        removeToken();
        window.location = "/";
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
