<template>
    <div role="status" class="">
        <div class="h-2 w-1/3 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-10 bg-gray-50 rounded-lg dark:bg-gray-700  mb-4"></div>
        <div class="h-2 w-1/3 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-10 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-2 w-1/3 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-10 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-2 w-1/3 bg-gray-50 rounded-lg dark:bg-gray-700 mb-2.5"></div>
        <div class="h-10 bg-gray-50 rounded-lg dark:bg-gray-700"></div>
        <span class="sr-only">Loading...</span>
    </div>
</template>
  
<script>
export default {};
</script>
  
<style></style>