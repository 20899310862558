import axios from "axios";
import axiosInstance from "../../helpers/axiosInstance";
const checkout = {
  state: {
    success: "",
    cart: null,
    profile: null,
    carts: null,
    cards: [],
  },
  getters: {
    getCartInfo(state) {
      return state.cart;
    },
    getProfileInfo(state) {
      return state.profile;
    },
    getAllCarts(state) {
      return state.carts;
    },
    getAllCards(state) {
      return state.cards;
    },
  },
  actions: {
    async getCountries() {
      await axios
        .get("https://api.countrystatecity.in/v1/countries")
        .then((response) => {
          console.log("res", response);
        });
    },

    //D312EAE8-EA20-4073-4A63-08DB5FF5CA9B
    async SetAllCarts({ commit }) {
      const token = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      await axiosInstance
        .get("services/app/ShoppingCart/GetCustomersCarts", {
          headers: !token ? headers : {},
        })
        .then((response) => {
          commit("setAllCarts", response?.data?.result);
        });
    },

    async GetCart({ commit }, payload) {
      const token = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };

      console.log("GETCART", payload);
      await axiosInstance
        .get(`/services/app/ShoppingCart/GetByCustomer?id=${payload.id}`, {
          headers: !token ? headers : {},
        })
        .then((response) => {
          localStorage.setItem("customerId", response?.data?.result.id);

          commit("getCart", response?.data?.result);
        })
        .catch((err) => {
          console.log(err.response.status);

          if (err.response.status > 400) {
            localStorage.setItem("loggedIn", "false");
            localStorage.setItem("fromcheckout", "true");
            var dynamicValue = window.location.pathname.match(/\/(\d+)$/)[1];

            if (!window.location.pathname.includes("/customer/checkout/"))
              window.location.href = `/customer/checkout/${dynamicValue}`;
          }
        });
    },

    async UpdateCart({ commit }, payload) {
      const token = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      await axiosInstance
        .put(`services/app/ShoppingCart/UpdateShoppingCartItem`, payload, {
          headers: !token ? headers : {},
        })
        .then((response) => {
          commit("getCart", response?.data?.result);
        });
    },

    async CreateCart({ commit }, payload) {
      const token = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      await axiosInstance
        .post(
          "services/app/ShoppingCart/Create",
          {
            shopId: payload.shopId,
            shoppingCartItems: [],
            shoppingCartCharges: [],
            notes: "Some Notes",
          },
          {
            headers: !token ? headers : {},
          }
        )
        .then((response) => {
          console.log("CREATED NEW CART", response?.data?.result);
          commit("getCart", response?.data?.result);
        });
    },

    async AddToCart({ commit }, payload) {
      const token = localStorage.getItem("token");
      let guid = sessionStorage.getItem("guid");
      const headers = {
        "NoshBox.GuestId": guid,
      };
      await axiosInstance
        .post(`services/app/ShoppingCart/AddShoppingCartItem`, payload, {
          headers: !token ? headers : {},
        })
        .then((response) => {
          commit("getCart", response?.data?.result);
        });
    },
    async UpdateShoppingCartItem({ commit }, payload) {
      await axios
        .patch(`services/app/ShoppingCart/UpdateShoppingCart`, payload)
        .then((response) => {
          console.log(response);
          commit();
        });
    },
    async UpdateCustomerProfile({ commit }, payload) {
      await axiosInstance
        .patch(`services/app/Customers/UpdateProfile`, payload)
        .then((response) => {
          commit("getProfile", response?.data?.result);
        });
    },

    async getCustomerProfile({ commit }) {
      await axiosInstance
        .post(`services/app/Customers/Profile`)
        .then((response) => {
          localStorage.setItem("address", response?.data?.result.address);
          commit("getProfile", response?.data?.result);
        });
    },
    async SetAllCards({ commit }) {
      await axiosInstance
        .get("services/app/Customers/GetCustomersCardsList")
        .then((response) => {
          commit("setAllCards", response?.data?.result);

          console.log("HELOOO", window.location.href);
        })
        .catch((err) => {
          if (err.response.status > 400) {
            localStorage.setItem("loggedIn", "false");
            localStorage.setItem("fromcheckout", "true");
            var dynamicValue = window.location.pathname.match(/\/(\d+)$/)[1];

            if (!window.location.pathname.includes("/customer/checkout/"))
              window.location.href = `/customer/checkout/${dynamicValue}`;
          }
        });
    },
  },
  mutations: {
    setAllCarts(state, response) {
      state.carts = response;
    },
    success(state, response) {
      state.success = response;
    },
    getCart(state, response) {
      state.cart = response;
    },
    getProfile(state, response) {
      state.profile = response;
    },
    setAllCards(state, response) {
      state.cards = response;
    },
  },
};
export default checkout;
