import VueRouter from "vue-router";
import Customer from "@/pages/customer/index.vue";
import LandingPage from "@/pages/customer/landing.vue";
import Home from "@/pages/main/new";
import Cart from "@/pages/customer/cart";
import Pickup from "@/pages/customer/pickup";
import Checkout from "@/pages/customer/checkout";
import StoreDetail from "@/pages/customer/storeDetail";
import StorePage from "@/pages/customer/storePage";
import HomeChefStores from "@/pages/customer/homechef";
import GroceryStores from "@/pages/customer/grocerystores";
import Payment from "@/pages/customer/checkout/payment.vue";
import StoresByCategory from "@/pages/customer/storesByCategory";
import Account from "@/pages/customer/account";
import ManageAccount from "@/pages/customer/manageAccount";
import GetHelp from "@/pages/customer/getHelp";
import Category from "@/pages/customer/storeDetail/category";
import PaymentSq from "@/pages/customer/payment.vue";
import Payments from "@/pages/customer/payments";
import Partner from "@/pages/partner";
import About from "@/pages/partner/about.vue";
import SavedStore from "@/pages/customer/savedStores/index.vue";
import Orders from "@/pages/customer/orders/index.vue";
import Subscriptions from "@/pages/customer/subscription/index.vue";
import SubscriptionsDetail from "@/pages/customer/subscription_detail/index.vue";

import OrderDetail from "@/pages/customer/orderdetail/index.vue";
import deliver from "@/pages/partner/deliver";
import Conveince from "@/pages/partner/conveince";
import HomeChef from "@/pages/partner/homecook";
import DeleteAccount from "@/pages/customer/deleteAccount/index.vue";
import ErrorPage from "@/pages/404/";
import PrivacyPolicy from "@/pages/customer/privacyPolicy.vue"
import TermsAndServices from "../pages/customer/termsAndServices.vue";
import Franchise from "@/pages/main/franchise/index.vue"
import Refundpolicy from "../pages/customer/refundpolicy.vue";
const routes = [
  {
    path: "/",
    name: "landingPage",
    component: Home,
  },
  {
    path: "/franchising",
    name: "franchise",
    component: Franchise,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/refund-policy",
    name: "refundpolicy",
    component: Refundpolicy,
  },
  {
    path: "/terms-and-services",
    name: "terms-and-services",
    component: TermsAndServices,
  },
  {
    path: "*",
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/customer/",
    name: "customer",
    redirect: "/customer/main",
    component: Customer,
    children: [
      {
        path: "main",
        name: "main",
        component: LandingPage,
      },

      {
        path: "savedstores",
        name: "savedstores",
        component: SavedStore,
      },
      {
        path: "orders",
        name: "orders",
        component: Orders,
      },
      {
        path: "orders/:orderid",
        name: "orderdetail",
        component: OrderDetail,
      },
      {
        path: "subscriptions",
        name: "Subscriptions",
        component: Subscriptions,
      },
      {
        path: "subscriptions/:subid",
        name: "SubscriptionsDetail",
        component: SubscriptionsDetail,
      },
      {
        path: "store-detail",
        name: "storeDetail",
        component: StoreDetail,
      },
      {
        path: "homechef",
        name: "home-chef",
        component: HomeChefStores,
      },
      {
        path: "grocerystore",
        name: "grocerystore",
        component: GroceryStores,
      },
      {
        path: "store/:storeId",
        name: "store",
        component: StoreDetail,
      },
      {
        path: "store/:storeId/category/:categoryId",
        component: Category,
      },
      {
        path: "storepage",
        name: "storepage",
        component: StorePage,
      },
      {
        path: "checkout/:storeId",
        name: "checkout",
        component: Checkout,
      },
      {
        path: "payment/:storeId",
        name: "payment",
        component: Payment,
      },
      {
        path: "payments",
        name: "Payments",
        component: Payments,
      },
      {
        path: "square",
        name: "paymentsquare",
        component: PaymentSq,
      },
      {
        path: "pickup",
        name: "pickup",
        component: Pickup,
      },
      {
        path: "manage-account/deleteaccount",
        name: "deleteAccount",
        component: DeleteAccount,
      },
      {
        path: "category/:id",
        name: "category",
        component: StoresByCategory,
      },
      {
        path: "account",
        name: "account",
        component: Account,
      },
      {
        path: "manage-account",
        name: "manageAccount",
        component: ManageAccount,
      },
      {
        path: "get-help",
        name: "getHelp",
        component: GetHelp,
      },
    ],
  },
  {
    path: "/partner/",
    name: "partner",
    redirect: "/partner/about",
    component: Partner,
    children: [
      {
        path: "about",
        name: "about",
        component: About,
      },
      {
        path: "joinrider",
        name: "joinrider",
        component: deliver,
      },
      {
        path: "store",
        name: "store",
        component: Conveince,
      },
      {
        path: "homechef",
        name: "homechef",
        component: HomeChef,
      },
    ],
  },
  {
    path: "/detailed-cart",
    name: "cart",
    component: Cart,
  },
];
// router.replace({ path: '*', redirect: '/main' })
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

const getAccessToken = () => {
  return new Promise((resolve) => {
    const accessToken = localStorage.getItem("token");
    resolve(accessToken);
  });
};

// Add a navigation guard to check for access token
router.beforeEach(async (to, from, next) => {
  if ((to.path === "/partner" || to.path === "/") && !to.meta.isProcessed) {
    // Mark the route as processed to avoid infinite redirection loop
    to.meta.isProcessed = true;

    // Retrieve the access token
    const accessToken = await getAccessToken();

    if (accessToken) {
      // Redirect to /home if access token is available
      next("/customer/main");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
