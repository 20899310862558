<template>
  <div class="h-screen md:overflow-hidden overflow-visible">
    <Header />
    <div class="grid grid-cols-9 h-full xl:overflow-hidden overflow-auto">
      <div
        class="xl:col-span-6 lg:col-span-6 col-span-9 xl:overflow-y-auto md:pb-32 pb-6 overflow-y-visible"
      >
        <PersonalDetail
          :cartInfo="getCartInfo"
          :storeId="$route.params.storeId"
        />
      </div>
      <div
        class="xl:col-span-3 lg:col-span-3 col-span-9 border-l border-gray-100 xl:overflow-scroll overflow-y-visible"
      >
        <Detail
          :cartInfo="getCartInfo"
          :storeId="$route.params.storeId"
          @increaseQuantity="(val) => increaseQuantity(val)"
          @decreaseQuantity="(val) => decreaseQuantity(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/checkout/header.vue";
import Detail from "@/components/checkout/detail.vue";
import PersonalDetail from "@/components/checkout/personalDetail.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Header,
    Detail,
    PersonalDetail,
  },
  computed: {
    ...mapGetters(["getCartInfo"]),
  },
  async mounted() {
    await this.GetCart({ id: this.$route.params.storeId });
  },
  methods: {
    ...mapActions(["GetCart", "UpdateCart"]),
    increaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity + 1,
        },
        shoppingCartId: val?.shoppingCart?.id,
        shopId: this.getStoreInfo.shopId,
      };
      this.UpdateCart(payload);
    },
    decreaseQuantity(val) {
      const payload = {
        shoppingCartItem: {
          id: val?.id,
          foodMenuId: val?.foodMenuId,
          quantity: val?.quantity > 0 ? val?.quantity - 1 : 0,
        },
        shoppingCartId: val?.shoppingCart?.id,
        shopId: this.getStoreInfo.shopId,
      };
      this.UpdateCart(payload);
    },
  },
};
</script>

<style></style>
