<template>
  <LayoutLanding>
    <section class="wrapper">
      <div
        class="p-4 w-100 flex items-center justify-between gap-5 overflow-hidden flex-wrap md:flex-nowrap first-letter:"
      >
        <landingBanner
          title="Get $0 delivery fee on your first order over $10!"
        />
        <rlandingBanner
          title="Get $0 delivery fee on your first order over $10!"
        />
      </div>
      <SubCategories :categories="getCategoryInfo" :isLoading="isLoading" />

      <div v-if="isSkelton === false">
        <div
          class="pt-8 px-4"
          v-for="(categories, i) in getAllProducts"
          :key="i"
        >
          <div class="flex items-center justify-between">
            <p
              class="pb-0 lg:text-2xl md:text-xl text-lg text-black font-semibold"
            >
              {{ categories.category.name }}
            </p>
            <div class="flex items-center space-x-4">
              <p class="text-sm font-medium text-black">See All</p>
              <div class="flex items-center space-x-2">
                <SfButton
                  class="p-2 rounded-full bg-gray-100"
                  @click="
                    () => {
                      prev(i);
                    }
                  "
                >
                  <img src="@/assets/svg/left-C.svg" />
                </SfButton>
                <SfButton
                  class="p-2 rounded-full bg-gray-100"
                  @click="
                    () => {
                      next(i);
                    }
                  "
                >
                  <img src="@/assets/svg/right-C.svg" />
                </SfButton>
              </div>
            </div>
          </div>
          <Carousel
            :isLogo="false"
            :slidesToShow="3"
            v-if="categories.items"
            :ref="`categories.category.name`"
            :products="categories.items"
            :isAddButton="false"
            :showImgTag="false"
            @visitStore="visitStore"
            :isLike="true"
            :isStore="true"
          />
        </div>
      </div>

      <div v-else class="pt-8 space-y-10 px-4">
        <div
          class="h-3.5 bg-gray-50 rounded-full dark:bg-gray-700 w-48 mb-4"
        ></div>
        <div class="grid md:grid-cols-3 grid-cols-1 gap-10">
          <productCardSkelton />
          <productCardSkelton class="md:block hidden" />
          <productCardSkelton class="md:block hidden" />
        </div>
        <div
          class="h-3.5 bg-gray-50 rounded-full dark:bg-gray-700 w-48 mb-4"
        ></div>
        <div class="grid md:grid-cols-3 grid-cols-1 gap-10">
          <productCardSkelton />
          <productCardSkelton class="md:block hidden" />
          <productCardSkelton class="md:block hidden" />
        </div>
      </div>

      <!-- <button @click="getSubscriptions" class="p-2 bg-primaryGreen">
        TEST API ...
      </button> -->
    </section>
  </LayoutLanding>
</template>

<script>
import LayoutLanding from "@/layouts/landingLayout.vue";
import landingBanner from "@/components/reusable/banner";
import SubCategories from "@/components/reusable/landingpage/SubCategories.vue";
import productCardSkelton from "@/components/skeltons/productCardSkelton.vue";
import { SfButton } from "@storefront-ui/vue";
import Carousel from "@/components/reusable/carousel.vue";
import rlandingBanner from "@/components/reusable/rightbanner.vue";
import { mapActions, mapGetters } from "vuex";
import axiosInstance from "../../helpers/axiosInstance";
export default {
  components: {
    LayoutLanding,
    // PillButton,
    landingBanner,
    SubCategories,
    // Categories,
    productCardSkelton,
    SfButton,
    rlandingBanner,
    // ShopCard,
    Carousel,
  },
  data() {
    return {
      isSkelton: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getCategoryInfo",
      "getShopTypes",
      "getResturantTypes",
    ]),
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
  },
  methods: {
    ...mapActions([
      "SetAllProducts",
      "AddToCart",
      "SetCategoryInfo",
      "SetShopTypes",
      "SetAllResturantTypes",
    ]),
    createCart(product) {
      this.AddToCart({ id: product.id, price: product.price, quantity: 3 });
    },
    next(index) {
      this.$refs["categories.category.name"][index].showNext();
    },
    prev(index) {
      this.$refs["categories.category.name"][index].showPrev();
    },
    visitStore(val) {
      this.$router.push(`store/${val.storeId}`);
    },

    getSubscriptions() {
      axiosInstance
        .get(
          "/services/app/OrdersSubscription/GetCustomersSubscriptions?SkipCount=1"
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  async mounted() {
    this.isSkelton = true;
    await this.SetAllProducts().then(() => {
      this.isSkelton = false;
    });
    this.isLoading = true;

    await this.SetShopTypes();
    await this.SetAllResturantTypes();
    await this.SetCategoryInfo().then(() => {
      this.isLoading = false;
    });
    const token = localStorage.getItem("token");
    const guid = sessionStorage.getItem("guid");
    if (!token && !guid) {
      sessionStorage.setItem("guid", this.generateUUID);
    }
  },
};
</script>

<style>
.wrapper {
  width: 100%;
  position: relative;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
</style>
