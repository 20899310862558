<template>
  <div>
    <input type="checkbox" id="verifynumber-modal" class="modal-toggle" />
    <div class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-3/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl p-4"
        for=""
      >
        <label
          for="verifynumber-modal"
          class="btn btn-sm btn-circle"
          @click="$emit('close')"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="py-2 px-2">
          <div class="mb-4">
            <h3 class="text-2xl font-bold leading-8 text-black-300 text-center">
              Phone Number Verification
            </h3>
            <h5
              class="text-bsm font-medium leading-8 text-grey-600 text-center mb-5"
            >
              For your security, we want to make sure it’s really you.
            </h5>
            <p class="text-red text-sm" v-if="phoneisInValid === true">
              Please Enter a valid Security Code
            </p>
          </div>
          <div class="flex flex-col items-center gap-4">
            <div class="flex flex-col w-full gap-2">
              <label for="verify" class="text-base font-bold"
                >Enter 6-digit code</label
              >
              <input
                @blur="isValid"
                v-model="code"
                type="tel"
                placeholder="* * * * * *"
                class="w-full rounded-md bg-coolGray-100 p-2 pl-4"
                id="verify"
              />
            </div>
            <h5 class="text-bsm font-medium leading-8 text-grey-600 text-left">
              We sent a code to {{ number }}
            </h5>

            <div class="flex items-center gap-2">
              <p class="text-black-300 text-sm underline cursor-pointer">
                Resend Code
              </p>
              <p class="text-black-300 text-sm underline cursor-pointer">
                Receive a Code Via Call
              </p>
              <p class="text-black-300 text-sm underline cursor-pointer">
                Get Help
              </p>
            </div>
          </div>
        </section>

        <div class="w-100 flex gap-2 justify-center mt-4">
          <button
            :class="
              phoneisInValid
                ? 'btn-disabled bg-gray-200 hover:cursor-not-allowed'
                : 'bg-red'
            "
            class="bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer w-full text-center flex items-center justify-center"
            for="verifynumber-modal"
            @click="verifyNumber"
            v-if="isloading"
          >
            <Spinner />
          </button>
          <button
            :class="
              phoneisInValid
                ? 'btn-disabled bg-gray-200 hover:cursor-not-allowed'
                : 'bg-red'
            "
            class="bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer w-full text-center"
            for="verifynumber-modal"
            @click="verifyNumber"
            v-else
          >
            Submit
          </button>
        </div>
      </label>
      <!-- FAILURE TOAST -->
      <Toast
        :open="iserror"
        :messageText="'Someting Went Wrong'"
        :varient="'warning'"
      />
      <!-- SUCCESS TOAST -->

      <Toast
        :open="isSuccess"
        :messageText="'Mobile Number updated successfully'"
        :varient="'success'"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import Toast from "@/components/reusable/Toast.vue";
import Spinner from "@/components/reusable/Spinner.vue";
export default {
  props: ["mobileNumber", "updateNumber", "number", "countryCode"],
  data() {
    return {
      isOpen: false,
      phone: "",
      phoneisInValid: false,
      code: "",
      isloading: false,
      iserror: false,
      isSuccess: false,
    };
  },
  components: { Toast, Spinner },

  methods: {
    ...mapActions(["getCustomerProfile"]),
    changeNumber(number) {
      let payload = new FormData();
      payload.append("phoneNumber", `${number}`);

      axios
        .patch("/services/app/Customers/UpdateProfile", payload, {
          headers: {
            "Content-Type": "application/json",
            "Abp.TenantId": 3,
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then(() => {
          this.isSuccess = true;

          this.$emit("popUpClose");

          this.getCustomerProfile();

          setTimeout(() => {
            this.isSuccess = false;
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    isValid() {
      this.phoneisInValid = false;
    },
    removeExtraCharacters(number) {
      // Remove all non-digit characters from the input string
      const cleanedNumber = number.replace(/\D/g, "");

      // If the number doesn't match the USA mobile number format, return the original input
      return cleanedNumber;
    },
    verifyNumber() {
      this.isloading = true;
      const payload = new FormData();
      payload.append("confirmationCode", this.code);
      payload.append(
        "phoneNumber",
        `${this.removeExtraCharacters(this.number)}`
      );
      payload.append("countryCode", `+${this.countryCode}`);

      axios
        .post("services/app/Customers/ConfirmPhoneNumber", payload, {
          headers: {
            "Abp.TenantId": "3",
            "Content-Type": " application/json-patch+json",
            accept: "text/plain",
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then((res) => {
          this.isloading = false;

          this.isSuccess = true;

          setTimeout(() => {
            this.isSuccess = false;
            this.iserror = false;
          }, 3000);
          console.log(res);
        })
        .catch((err) => {
          this.isloading = false;
          this.iserror = true;
          setTimeout(() => {
            this.isSuccess = false;
            this.iserror = false;
          }, 3000);
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}
</style>
