<template>
  <div
    class="m-1 cursor-pointer flex flex-col justify-center items-center"
    @click="$emit('handleClick')"
  >
    <img
      v-if="image"
      class="w-16 h-16"
      :src="image ? image : '@/assets/Home/SubCategories/Burgers.svg'"
      alt="pillImage"
    />
    <img
      v-else
      class="w-16 h-16"
      src="@/assets/Home/SubCategories/Burgers.svg"
      alt="pillImage"
    />
    <p class="text-center font-medium text-xs text-black-300">
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    image: String,
  },
};
</script>
