<template>
  <div class="relative">
    <button
      class="z-5 relative flex items-center focus:outline-none select-none"
      @click="$emit('toggle')"
    >
      <slot name="button"></slot>
    </button>

    <!-- to close when clicked on space around it-->
    <button
      class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
      v-if="open"
      @click="$emit('toggle')"
      tabindex="-1"
    ></button>

    <!--dropdown menu-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="absolute shadow-lg rounded py-1 px-2 text-sm mt-4 bg-white z-10"
        :class="`${
          placement === 'right' ? 'right-0' : 'left-0'
        } ${customClass}`"
        v-if="open"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    customClass: {
      type: String,
    },
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  methods: {},

  mounted() {
    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>
