<template lang="">
  <div>
    <Teleport to="#modal">
      <Transition name="modal">
        <div
          class="z-50 fixed flex items-center justify-center top-0 left-0 w-[100vw] h-[100vh] bg-[rgba(0,0,0,.5)]"
          v-if="isModalOpen"
        >
          <div class="bg-white p-10">
            <Translator :countries="countries" @on-country-click="closeModal" />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
<script>
import { Translator } from "vue-google-translate";

export default {
  props: ["isModalOpen", "closeModal"],

  components: {
    Translator,
  },
  data() {
    return {
      isOpen: false,

      countries: [
        {
          code: "en|af",
          title: "Afrikaans",
        },
        {
          code: "en|sq",
          title: "Albanian",
        },
        {
          code: "en|ar",
          title: "Arabic",
        },
        {
          code: "en|hy",
          title: "Armenian",
        },
        {
          code: "en|az",
          title: "Azerbaijani",
        },

        {
          code: "en|cs",
          title: "Czech",
        },

        {
          code: "en|da",
          title: "Danish",
        },
        {
          code: "en|nl",
          title: "Dutch",
        },
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|et",
          title: "Estonian",
        },
        {
          code: "en|tl",
          title: "Filipino",
        },
        {
          code: "en|fi",
          title: "Finnish",
        },
        {
          code: "en|fr",
          title: "French",
        },

        {
          code: "en|de",
          title: "German",
        },

        {
          code: "en|id",
          title: "Indonesian",
        },

        {
          code: "en|it",
          title: "Italian",
        },

        {
          code: "en|ms",
          title: "Malay",
        },

        {
          code: "en|pl",
          title: "Polish",
        },
        {
          code: "en|pt",
          title: "Portuguese",
        },

        {
          code: "en|ru",
          title: "Russian",
        },
        {
          code: "en|es",
          title: "Spanish",
        },
        {
          code: "en|sv",
          title: "Swedish",
        },
        {
          code: "en|th",
          title: "Thai",
        },
        {
          code: "en|tr",
          title: "Turkish",
        },
      ],
    };
  },

  methods: {},
};
</script>
<style scoped></style>
