import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import router from "./router";
import "./main.css";
import "@storefront-ui/vue/styles.scss";
import store from "./store";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

import VueI18n from "vue-i18n";
import English from "./i18n/en.json";
import French from "./i18n/fr.json";
import Afghani from "./i18n/afg.json";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "en", // set the initial locale
  fallbackLocale: "en", // set the fallback locale
  messages: {
    en: English,
    fr: French,
    afg: Afghani,
    // add more locales as needed
  },
});

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import VueGmaps from "vue-gmaps";

Vue.use(VueGmaps, {
  key: process.env.VUE_APP_GCP_ACCESS,
});
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

import VueFormulate from "@braid/vue-formulate";
Vue.use(VueFormulate);

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueAxios, axios);

axios.defaults.baseURL = 'https://dev-vneed-api.azurewebsites.net/api';
axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;
Vue.config.productionTip = false;

let vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
