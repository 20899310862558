<template>
  <landing-layout>
    <section class="wrapper mt-4">
      <!-- Header Text -->
      <div>
        <h3 class="text-2xl p-2 font-semibold lg:text-3xl lg:p-0">Orders</h3>
      </div>

      <section v-if="isLoading === true" class="w-100 mt-3">
        <div
          class="w-full flex animate-pulse flex-row items-center h-full justify-center space-x-5"
        >
          <div
            class="w-full flex items-center justify-between px-4 flex-wrap gap-4"
          >
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
            <div class="w-96 h-44 bg-gray-300 rounded-md"></div>
          </div>
        </div>
      </section>

      <section v-else>
        <div
          v-if="getAllOrders.length > 0"
          class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 px-2 mt-4"
        >
          <!-- ORDERED CARD -->

          <div
            v-for="(order, index) in getAllOrders"
            :key="index"
            class="w-max-[360px] h-max-[250px] bg-white shadow-md rounded-lg p-4"
          >
            <div
              v-if="!order?.shopLogoUrl"
              class="bg-grey-100 h-[120px] rounded-md"
            ></div>
            <img
              v-else
              :src="order?.shopLogoUrl"
              class="bg-grey-100 h-[120px] w-full rounded-md"
            />
            <router-link :to="`/customer/orders/${order?.id}`">
              <div class="cursor-pointer">
                <div class="mt-1 flex items-center justify-between">
                  <h4 class="text-lg font-bold">
                    {{ order?.foodMenu?.storeName }}
                  </h4>
                  <h6 class="text-xs">order Id: {{ order?.id }}</h6>
                </div>
                <div class="mt-1 flex gap-1 items-center">
                  <p class="text-base font-bold">Status -</p>
                  <p class="text-base font-bold text-green-600">
                    {{
                      order?.orderStatus === 0
                        ? "Confirmed"
                        : order?.orderStatus === 2
                        ? "Processing"
                        : order?.orderStatus === 0
                        ? "Out for delivery"
                        : order?.orderStatus === 0
                        ? "Delivered"
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <section v-else class="w-100 mt-3">
          <div class="flex items-center justify-center flex-col">
            <h2 class="text-2xl font-bold">No Orders Found</h2>
            <img class="w-[40%] h-[40%]" src="@/assets/no_orders.svg" alt="" />

            <button class="p-2 bg-primaryGreen text-white text-lg rounded-md">
              Start Shopping
            </button>
          </div>
        </section>
      </section>
    </section>
  </landing-layout>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      data: [],
    };
  },
  components: { landingLayout },

  methods: {
    ...mapActions(["SetAllOrders"]),
    async AllOrders() {
      this.isLoading = true; // Show loader
      try {
        // Make your API request or any other asynchronous operation here
        // ...

        this.SetAllOrders();
      } catch (error) {
        // Handle errors
      } finally {
        this.isLoading = false; // Hide loader (whether the request succeeds or fails)
      }
    },
  },

  mounted() {
    this.AllOrders();
  },
  computed: {
    ...mapGetters(["getAllOrders"]),
  },
};
</script>

<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
