<template>
  <LandingLayout>
    <div class="flex justify-center items-center h-full py-12 px-4">
      <Toast
        :open="status === 'success'"
        :messageText="'Account Deleted Successfully'"
        :varient="'success'"
      />
      <Toast
        :open="status === 'error'"
        :messageText="'Deleting Account Faild'"
        :varient="'warning'"
      />
      <div
        class="lg:w-4/12 md:w-1/2 w-full p-5 border border-gray-100 rounded-md shadow-md"
      >
        <h1 class="text-3xl font-semibold py-4">Delete Account</h1>

        <p class="text-gray-400 font-light text-sm py-2">
          You can ask to have your account and personal data erased.
        </p>
        <div class="flex items-center gap-4">
          <router-link to="/customer/manage-account"
            ><button class="text-grey-600 font-bold pb-5">
              Go back
            </button></router-link
          >

          <button v-if="isloading" class="text-red font-bold pb-5">
            . . .
          </button>
          <button v-else @click="deleteAccount" class="text-red font-bold pb-5">
            Delete
          </button>
        </div>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "../../../layouts/landingLayout.vue";
import axiosInstance from "../../../helpers/axiosInstance";
import Toast from "@/components/reusable/Toast.vue";

export default {
  components: {
    LandingLayout,
    Toast,
  },
  data() {
    return {
      status: "",
      isloading: false,
    };
  },

  methods: {
    deleteAccount() {
      this.isloading = true;
      axiosInstance
        .delete("/services/app/Customers/DeleteCustomer")
        .then((res) => {
          this.isloading = false;

          console.log(res);
          this.status = "success";
          console.log("--------------- ACCOUNT DELETED ------------");
        })
        .catch((err) => {
          this.isloading = false;
          this.status = "error";

          console.log(err);
        });
    },
  },
};
</script>

<style></style>
