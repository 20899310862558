<template>
  <div class="h-screen overflow-hidden relative">
    <Header class="z-40" />
    <div class="absolute w-full h-full top-20 flex">
      <SideManu
        class="w-4/12 shadow-lg h-screen overflow-y-auto"
        :data="getAllShopsNearMe"
      />
      <div class="w-full h-[90vh]">
        <GoogleMap @location="getShops" :currentLocation="location" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/LandingPage/header.vue";
import SideManu from "@/components/pickUp/sideMenu";
import GoogleMap from "@/components/reusable/googleMap.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Header, SideManu, GoogleMap },
  data() {
    return {
      location: {
        lat: 8.9806,
        lng: 38.7578,
      },
    };
  },
  created() {
    const loc = JSON.parse(sessionStorage.getItem("location"));
    this.location = {
      lat: loc?.latitude ? loc?.latitude : 8.9806,
      lng: loc?.longitude ? loc?.longitude : 38.7578,
    };
  },
  computed: {
    ...mapGetters(["getAllShopsNearMe"]),
  },
  mounted() {
    console.log("currentLocation", this.location);
    this.SetAllShopsNearme();
  },
  methods: {
    ...mapActions(["SetAllShopsNearme"]),
    getShops(locat) {
      console.log("loca", locat);
      this.SetAllShopsNearme(locat);
    },
  },
};
</script>

<style></style>
