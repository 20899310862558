<template>
  <div>
    <input type="checkbox" id="address-modal" class="modal-toggle" />
    <label for="address-modal" class="modal cursor-pointer bg-blur">
      <label
        class="modal-box md:relative absolute md:top-0 top-20 lg:w-4/12 md:7/12 w-full pb-8 max-w-5xl bg-white shadow-xl rounded-3xl"
        for=""
      >
        <label
          for="address-modal"
          class="btn btn-sm btn-circle right-2 top-2"
          @click="$emit('close')"
        >
          <img src="@/assets/svg/cross.svg" class="w-4" />
        </label>

        <section class="px-6 pt-3">
          <h3 class="text-3xl font-bold leading-9 text-black-300">
            Change Address
          </h3>
          <!-- <div class="relative flex w-full mt-2">
            <img
              class="absolute top-3 left-3"
              src="@/assets/Checkout/pin.svg"
            />
            <input
              class="w-full rounded-lg bg-gray-50 pl-10 py-3 pr-3"
              type="text"
              placeholder="Address"
            />
          </div> -->
          <div class="text-base font-semibold space-y-2 py-3 px-3 relative">
            <div
              v-if="isLoading === true"
              class="z-10 top-0 left-0 absolute w-full h-full bg-[rgba(192,192,192,0.3)] pointer-events-none"
            >
              <div class="flex items-center justify-center">
                <div class="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <p>{{ $t("enterYourAddress") }}</p>
            <div>
              <input
                type="text"
                v-model="query"
                placeholder="Search for a place"
                @input="handleSearchInput"
                class="w-full p-2 bg-grey-100 rounded-sm"
              />
            </div>
            <div v-if="query">
              <div v-if="query.length > 0" class="w-full">
                <p
                  @click="
                    changeAddress(
                      place.description,
                      place.city,
                      place.postalCode
                    )
                  "
                  v-for="place in places"
                  :key="place.id"
                  class="bg-grey-100 p-3 rounded-md text-black-300 mt-2 cursor-pointer"
                >
                  {{ place.description }}
                </p>
              </div>
            </div>
          </div>
        </section>
        <div class="py-2 px-8">
          <div
            class="flex items-center justify-between py-3 border-gray-400 border-t-1"
          >
            <div class="flex items-center justify-center gap-3">
              <input
                type="radio"
                id="address2"
                name="address"
                class="addressRadio"
                checked
              />

              <label for="address2" class="cursor-pointer">
                <h5 class="text-base font-medium leading-5 text-red-700 block">
                  {{ getProfileInfo?.address }}
                </h5>
                <!-- <h6 class="text-xs font-medium leading-5 block text-red-700">
                  {{ getProfileInfo?.state }} , {{ getProfileInfo?.postCode }}
                </h6> -->
              </label>
            </div>
            <div>
              <label
                for="leaveat-modal"
                class="w-8 h-8 flex items-center justify-center rounded-full bg-blue-50 cursor-pointer"
              >
                <img src="@/assets/Checkout/pen.svg" class="w-4" />
              </label>
            </div>
          </div>
        </div>
      </label>
      <!-- SUCCESS  TOAST -->
      <Toast
        :open="success"
        :messageText="'Address Modified Successfully'"
        :varient="'success'"
      />
      <!-- SUCCESS  TOAST -->
      <Toast
        :open="failure"
        :messageText="'Someting Went Wrong'"
        :varient="'warning'"
      />
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosInstance from "../../../helpers/axiosInstance";
import { mapActions } from "vuex";
import Toast from "@/components/reusable/Toast.vue";
import searchPlaces from "../../../helpers/seacrhplaces";
export default {
  data() {
    return {
      isOpen: false,
      address: localStorage.getItem("address"),

      query: "",
      places: [],
      isLoading: false,
      value: "",
      success: false,
      failure: false,
    };
  },
  components: { Toast },
  methods: {
    ...mapActions(["getAddress", "getCustomerProfile", "GetCart"]),

    async handleSearchInput() {
      const inputValue = this.query;
      this.places = await searchPlaces(inputValue);
    },

    changeAddress(address, city, postalCode) {
      this.isLoading = true;

      let payload = new FormData();
      payload.append("address", address);
      payload.append("city", city);
      payload.append("postCode", postalCode);

      axiosInstance
        .patch("/services/app/Customers/UpdateProfile", payload, {
          headers: {
            "Content-Type": "application/json",
            "Abp.TenantId": 3,
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.getCustomerProfile();
          this.value = res.data.result.address;
          // this.address = res.data.result.address;
          // this.getAddress(res.data.result.address);

          this.query = "";
          this.places = [];
          this.success = true;

          setTimeout(() => {
            this.success = false;
          }, 3000);
        })
        .catch((err) => {
          this.isLoading = false;

          console.log(err);
          this.failure = true;

          setTimeout(() => {
            this.failure = false;
          }, 3000);
        });
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters(["getProfileInfo"]),
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}
</style>
