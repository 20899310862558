<template>
  <div>
    <!-- Put this part before </body> tag -->
    <input type="checkbox" id="leaveat-modal" class="modal-toggle" />
    <div for="leaveat-modal" class="modal cursor-pointer bg-blur">
      <DropOff
        :showClose="true"
        :handleDeliveryType="handleDeliveryType"
        :deliveryType="deliveryType"
        :UpdatedeliveryInstructions="UpdatedeliveryInstructions"
        :updateDeliveryInfo="updateDeliveryInfo"
        :UpdateAppartmentNumber="UpdateAppartmentNumber"
        :getCartInfo="getCartInfo"
      />
    </div>
  </div>
</template>

<script>
import DropOff from "@/components/reusable/DropOff.vue";
import { mapGetters } from "vuex";
export default {
  components: { DropOff },
  props: [
    "handleDeliveryType",
    "deliveryType",
    "UpdatedeliveryInstructions",
    "address",
    "updateDeliveryInfo",
    "UpdateAppartmentNumber",
  ],

  data() {
    return {};
  },

  methods: {
    async getGuestAddress() {
      // CHECK USER EXISTS
      // await this.GetCart();
    },
  },

  computed: {
    ...mapGetters(["getCartInfo"]),
  },

  async mounted() {
    this.getGuestAddress();
  },
};
</script>

<style>
.addressRadio[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  accent-color: #eb1700;
}

.addressRadio[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #eb1700;
}

.addressRadio[type="radio"]:checked::before {
  transform: scale(1);
}

.addressRadio[type="radio"]:checked:after {
  background-color: #eb1700;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #456759;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
