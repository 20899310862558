<template>
  <landing-layout>
    <section class="wrapper mt-4">
      <!-- Header Text -->
      <div>
        <h3 class="text-2xl p-2 font-semibold lg:text-3xl lg:p-0">
          Orders Details
        </h3>
      </div>

      <section class="mt-4">
        <!-- ORDER STATUS -->
        <div
          class="w-100 bg-[#f1f1f1] shadow-sm h-[120px] rounded-md p-3 flex justify-center"
        >
          <div class="flex items-center">
            <!-- STEP 1 -->
            <div class="flex items-center relative">
              <div
                class="w-8 h-8 rounded-full flex justify-center items-center"
                :class="
                  getOrder?.orderStatus === 0
                    ? 'bg-primaryGreen text-black'
                    : 'bg-white'
                "
              >
                <p class="m-0 p-0 text-center text-white">1</p>
                <h4 class="absolute top-9">Confirmed</h4>
              </div>

              <div class="w-44 h-1 bg-primaryGreen"></div>
            </div>
            <!-- STEP 2 -->
            <div class="flex items-center relative">
              <div
                class="w-8 h-8 rounded-full flex justify-center items-center"
                :class="
                  getOrder?.orderStatus === 2
                    ? 'bg-primaryGreen text-black'
                    : 'bg-white'
                "
              >
                <p class="m-0 p-0 text-center">2</p>
                <h4 class="absolute top-9">Processing</h4>
              </div>

              <div class="w-44 h-1 bg-primaryGreen"></div>
            </div>

            <!-- STEP 3 -->

            <div class="flex items-center relative">
              <div
                class="w-8 h-8 rounded-full flex justify-center items-center"
                :class="
                  getOrder?.orderStatus === 3
                    ? 'bg-primaryGreen text-red'
                    : 'bg-white'
                "
              >
                <p class="m-0 p-0 text-center">3</p>
                <h4 class="absolute top-9 whitespace-nowrap">
                  Out for Delivery
                </h4>
              </div>

              <div class="w-44 h-1 bg-primaryGreen"></div>
            </div>
            <!-- STEP 4 -->

            <div class="flex items-center relative">
              <div
                class="w-8 h-8 rounded-full flex justify-center items-center"
                :class="
                  getOrder?.orderStatus === 4
                    ? 'bg-primaryGreen text-red'
                    : 'bg-white'
                "
              >
                <p class="m-0 p-0 text-center">4</p>
                <h4 class="absolute top-9">Delivered</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- ORDER DETAILS -->

        <div class="mt-4">
          <div class="bg-[#f1f1f1] rounded-md p-4">
            <div class="flex items-center justify-between mt-2">
              <h4>Ordered Date : {{ formattedDate(getOrder?.orderDate) }}</h4>
              <h4>Order Id : {{ getOrder?.id.slice(0, 15) }}</h4>
            </div>
            <div class="flex items-center justify-between mt-4">
              <h4>
                Current Status :
                {{
                  getOrder?.orderStatus === 0
                    ? "Confirmed"
                    : getOrder?.orderStatus === 2
                    ? "Processing"
                    : getOrder?.orderStatus === 0
                    ? "Out for delivery"
                    : getOrder?.orderStatus === 0
                    ? "Delivered"
                    : ""
                }}
              </h4>
            </div>
            <div class="flex items-center justify-between mt-4">
              <h4 class="text-xl font-bold">Order Info</h4>
            </div>

            <div>
              <!-- Order List Card -->

              <div
                v-for="(order, index) in getOrder?.orderDetails"
                :key="index"
                class="w-full bg-white p-4 mt-2 rounded-lg flex items-center justify-between gap-3"
              >
                <img
                  v-if="order?.foodMenu?.imageUrl"
                  :src="order?.foodMenu?.imageUrl"
                  class="w-10 h-10 rounded-sm"
                />
                <div v-else class="w-10 h-10 rounded-sm bg-black-300"></div>

                <!-- Product Name -->
                <div class="w-60 flex-[10]">
                  <h4>{{ order?.foodMenu?.name }}</h4>
                </div>

                <!-- Product Qty -->
                <h4 class="flex-1">Qty : {{ order?.quantity }}</h4>

                <!-- product Price -->

                <h4 class="flex-1">CA ${{ order?.price }}</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- Price Calculation -->
        <div class="mt-4">
          <div
            class="w-full py-5 px-6 mt-2 rounded-lg flex items-start justify-between gap-3"
          >
            <div class="">
              <h5 class="text-lg text-bold">Shipping Address</h5>
              <p class="text-base mt-1">{{ getProfileInfo?.address }}</p>
              <p class="text-base mt-1">
                Phone : {{ getProfileInfo?.phoneNumber }}
              </p>
              <h5 class="text-base text-bold">
                Payment : {{ getOrder?.payment?.cardDetailsCardBrand }} XX-{{
                  getOrder?.payment?.cardDetailsCardLast4
                }}
              </h5>

              <!-- <button class="btn btn-primary bg-r-500 mt-2 text-white text-bold">
                Download Invoice
              </button> -->
            </div>
            <div>
              <h4 class="text-[16px] font-bold">
                Delivery fee : CA{{ getOrder?.deliveryFee }}
              </h4>
              <h4 class="text-[16px] font-bold">
                Discount : CA {{ getOrder?.discountedAmount }}
              </h4>
              <h4 class="text-[16px] font-bold">
                Tax : CA {{ getOrder?.taxAmount }}
              </h4>
              <h4 class="text-[20px] font-bold">
                Total Price : CA {{ getOrder?.totalPrice }}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </section>
  </landing-layout>
</template>

<script>
import landingLayout from "@/layouts/landingLayout.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: { landingLayout },
  methods: {
    ...mapActions(["SetOrder"]),
    formattedDate(currentDate) {
      return moment(currentDate).format("MMMM Do YYYY");
    },
  },
  mounted() {
    this.SetOrder({ id: this.$route.params.orderid });
  },
  computed: {
    ...mapGetters(["getOrder", "getProfileInfo"]),
  },
};
</script>

<style scoped>
@media only screen and (max-width: 678px) {
  .wrapper {
    width: 100%;
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ec1f1f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
