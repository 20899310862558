<template>
  <div class="px-3 py-4">
    <template v-if="data.length!==0">
      <notification-card></notification-card>
      <h1 class="py-4 text-2xl font-semibold">All Stores</h1>
      <store-info
        v-for="(store, i) in data"
        :key="i"
        :store="store"
      ></store-info>
    </template>
    <div v-else class="flex flex-col items-center justify-center h-full">
      <p class="text-xl font-bold">No stores found in this area.</p>
      <p class="text-center text-gray-400 pt-4">Zoom out or search in a new area to find more stores around you.</p>
    </div>
  </div>
</template>

<script>
import notificationCard from "./notificationCard.vue";
import StoreInfo from "./storeInfo.vue";
export default {
  props: ["data"],
  components: { notificationCard, StoreInfo },
};
</script>

<style>
</style>